import {API} from "./api.base.js"
export default {
  async createRelation(data) {
    try {
      const response = await API.post('/api/rest/v2/relations', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRelation() {
    try {
      const response = await API.get('/api/rest/v2/relations');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getRelationbyId(id) {
    try {
      const response = await API.get(`/api/rest/v2/relations/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteRelation(id) {
    try {
      const response = await API.delete(`/api/rest/v2/relations/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async changeConsultsbyId(id) {
    try {
      const response = await API.put(`/api/rest/v2/relations/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalRelations() {
    try {
      const response = await API.get('/api/rest/v2/relations/count/all');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getActiveRelations() {
    try {
      const response = await API.get('/api/rest/v2/relations/count/actief');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getNonActiveRelations() {
    try {
      const response = await API.get('/api/rest/v2/relations/count/nietactief');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateRelation(id, relation) {
    try {
      const response = await API.put(`/api/rest/v2/relations/${id}`, relation);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
