<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div v-if="items.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
          <v-card v-else-if="!showAddArticle">
            <v-card-title>
              Kennisbank
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="custom-table-header"
            >
              <template v-slot:top> </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon small color="green" @click="openArticle(item.id)">
                  mdi-eye
                </v-icon>

                <v-icon small color="red" @click="deleteItem(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
 
   
    </v-container>
  </div>
</template>

<script>

import apiKnowledgebase from "../api/apiKnowledgebase";
export default {

  data: () => ({
    search: "",
    article: [],
    dialog: false,
    items: [],
    headers: [
      {
        text: "Naam",
        value: "subject",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Zoekwoorden",
        value: "keywords",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Aangemaakt op",
        value: "createDate",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
    showAddArticle: false,
    showEditArticle: false,
  }),

  async mounted() {
    this.loadKnowledgeItems();

    // Listen for the custom event
    this.$root.$on("knowledgeItemAdded", () => {
      this.loadKnowledgeItems();
    });
  },
  methods: {
    async loadKnowledgeItems() {
      apiKnowledgebase.getKnowledgeItems().then((data) => {
        this.items = data;
      });
    },
    openArticle(articleId) {
      this.$router.push(`/KnowledgeBase/${articleId}`);
    },
    async deleteItem(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze kennisitem wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiKnowledgebase.deleteItem(id);
        const data = await apiKnowledgebase.getKnowledgeItems();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De Kennisitem is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
<style >
.space {
  margin: 3px;
}


.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
