<template>
  <v-container>
    <v-row>
      <v-col cols="8">
        <v-text-field
          v-model="form.title"
          :label="subject"
          outlined
        ></v-text-field>

        <v-autocomplete
          :items="objects.flat()"
          label="Huis selecteren"
          v-model="form.house"
          item-text="adres"
          item-value="id"
        ></v-autocomplete>

        <v-autocomplete
          :items="items.flat()"
          label="Selecteer een status"
          v-model="form.status"
          item-text="name"
          item-value="name"
          outlined
        ></v-autocomplete>

        <v-textarea
          name="input-7-1"
          label="Beschrijf het probleem"
          v-model="form.description"
        ></v-textarea>
        <v-radio-group v-model="categorieType">
          <v-radio label="Offerte aanvragen" value="Offerte" />
          <v-radio label="Toewijzen aan Monteur" value="Toewijzen" />
          <v-radio label="Zelf opgelost" value="Eigen" />
        </v-radio-group>
        <v-autocomplete
          v-if="categorieType === 'Offerte'"
          :items="mechanic"
          label="Selecteer een reparateur"
          v-model="form.mechanicId"
          item-text="fullName"
          item-value="id"
        ></v-autocomplete>

        <v-autocomplete
          v-if="categorieType === 'Toewijzen'"
          :items="mechanic"
          label="Selecteer een reparateur"
          v-model="form.mechanicId"
          item-text="fullName"
          item-value="id"
        ></v-autocomplete>

        <v-menu
          v-if="categorieType === 'Toewijzen'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.date"
              label="uitvoeringsdatum"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.excutionDate"
            :active-picker.sync="activePicker"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
          ></v-date-picker>
        </v-menu>

        <div>
          <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  style="background-color: #147D81;
  color: white"
                  v-on="on"
                  >Reparatie aanmaken</v-btn
                >
              </template>
              <v-list>
                <v-list-item @click="addRepair()"
                  >Aanmaken en niet versturen</v-list-item
                >
                <v-list-item @click="sendNotification('email')"
                  >Verstuur per E-mail</v-list-item
                >
                <v-list-item @click="sendNotification('sms')"
                  >Verstuur per SMS</v-list-item
                >
              </v-list>
            </v-menu>

            <v-btn
              style="color:white;background-color:#cb403d;margin-left:5px"
              @click="$emit('cancel')"
              >Afsluiten</v-btn
            >
          </div>
        </div>
      </v-col>

      <v-col>
        <h4><b>Kennisitems</b></h4>
        <div v-if="Array.isArray(knowledgeItems)">
          <div v-for="item in knowledgeItems" :key="item.id">
            <a :href="`/KnowledgeBase/${item.id}`">{{ item.subject }}</a>
          </div>
        </div>
        <div v-else>
          {{ knowledgeItems }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiNotifications from "../api/apiNotifications";
import apiMechanics from "../api/apiMechanics";
import apiHouse from "../api/apiHouse";
import apiKnowledgebase from "../api/apiKnowledgebase";
import apiRepair from "../api/apiRepair";

export default {
  props: {
    subject: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activePicker: null,

      menu: false,
      inputSubject: this.subject,
      knowledgeItems: [],
      mechanics: [],
      objects: [],
      form: {
        excutionDate: null,
        description: "",
        mechanicId:"",
        house: "",
        repairBy: "",
        status: "",
      },

      categorieType: null,
      items: [],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    "form.title": {
      handler(newTitle) {
        this.searchKnowledgeItem(newTitle);
      },
      immediate: true,
    },
  },

  computed: {
    mechanic() {
      return this.mechanics.map((mechanic) => {
        return {
          ...mechanic,
          fullName: mechanic.firstName + " " + mechanic.lastName,
        };
      });
    },
  },

  async created() {
    await this.searchKnowledgeItem(this.inputSubject);
  },
  methods: {
    async sendNotification(notificationType) {
      try {
        let message = "";
        let recipient = "";
        if (notificationType === "email") {
          await apiNotifications.sendMailBasedonTemplate(
           this.form
          );
          console.log("E-mail notificatie verzonden");
        } else if (notificationType === "sms") {
          await apiNotifications.sendSMSNotification({
            phoneNumber: recipient,
            message: message,
          });
          console.log("SMS notificatie verzonden");
        }
      } catch (error) {
        console.error("Fout bij het verzenden van de notificatie:", error);
      }
    },
    async addRepair() {
      try {
        const response = await apiRepair.createRepair(this.form);
        this.$emit("cancel");
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    async searchKnowledgeItem(keyword) {
      try {
        const data = await apiKnowledgebase.searchKnowledgeItem(keyword);
        this.knowledgeItems =
          data.length > 0
            ? data
            : "Geen kennisitem gevonden die kan helpen bij het oplossen van deze storing";
      } catch (error) {
        console.error(error);
        this.knowledgeItems = [];
      }
    },
  },
  async mounted() {
    apiMechanics.getMechanics().then((data) => {
      this.mechanics = data;
    });
    apiHouse.getHouse().then((data) => {
      this.objects = data;
    });
    apiRepair.getRepairStatus().then((data) => {
      this.items = data;
    });
  },
};
</script>
