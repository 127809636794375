<template>
  <v-container style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="5">
          <v-text-field
            dense
            outlined
            placeholder="Status toevoegen"
            v-model="form.name"
          ></v-text-field>
          <v-textarea
            dense
            v-model="form.description"
            outlined
            placeholder="Omschrijving die handig kan zijn"
          ></v-textarea>
          <v-btn color="primary" @click="addStatus()"> Status toevoegen </v-btn>
        </v-col>

        <v-col cols="7">
          <div v-if="statussen.length === 0">
          <img src="@/assets/nodata.jpg" height="450" width="550" />
        </div>
          <v-card v-else>
            <v-card-title>
              Statussen
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="statussen"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <v-icon small color="red" @click="deleteStatus(item.id)" v-if="item.name != 'Gesloten'">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiIncidentstatus from "../api/apiIncidentstatus";
export default {
  data: () => ({
    form: {
      name: "",
      description: "",
    },
    statussen: [],
    search: "",
    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Omschrijving",
        value: "description",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    apiIncidentstatus.getIncidentsStatus().then((data) => {
      this.statussen = data;
    });
  },

  methods: {
    resetForm() {
      this.form.name = "";
      this.form.description = "";
    },

    async addStatus() {
      try {
        if (!this.form.name) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }

        const response = await apiIncidentstatus.createIncidentStatus(
          this.form
        );

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apiIncidentstatus.getIncidentsStatus();
        this.statussen = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteStatus(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze status wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiIncidentstatus.deleteIncidentStatus(id);
        const data = await apiIncidentstatus.getIncidentsStatus();
        this.statussen = data;
        this.$swal.fire(
          "Verwijderd",
          "De status is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
