import {API} from "./api.base.js"
export default {
  async addcompanyInfo(data) {
    try {
      const response = await API.post("/api/rest/v2/company/info", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getCompany() {
    try {
      const response = await API.get("/api/rest/v2/company/info");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updatecompanyInfo(id) {
    try {
      const response = await API.put(`/api/rest/v2/company/info/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
