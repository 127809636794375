
<template>
  <SMS />
</template>

<script>
import SMS from "@/components/instellingen/SMS";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SMS,
  },
  metaInfo: {
    title: "SMS | Dashboard",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>