<template>
  <SingleRelation />
</template>

<script>
import SingleRelation from "@/components/relation/SingleRelation";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleRelation,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Relatie bewerken ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>