<template>
  <v-container>
    <form ref="form" @submit.prevent="submitForm">
          <v-card elevation="3">
            <v-card-title>
              NAW gegevens
            </v-card-title>

            <v-card-text>
              <v-col cols="2">
                <v-select
                  :items="titels"
                  label="Titel"
                  v-model="form.title"
                ></v-select>
              </v-col>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Voornaam"
                    v-model="form.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Tussenvoegsel"
                    v-model="form.insertion"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Achternaam"
                    v-model="form.lastName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Telefoonnummer"
                    v-model="form.phone"
                    :prefix="'+31'.toString()"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Email"
                    v-model="form.email"
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
             </v-card>
    </form>
  </v-container>
</template>

<script>
import apiUser from "../api/apiUser";
export default {
  data() {
    return {
      form: {
        title: "",
        firstName: "",
        insertion: "",
        lastName: "",
        phone: "",
        email: "",
       
      },
      emailRules: [
        (value) => !!value || "E-mail is verplicht",
        (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail moet een geldig formaat hebben",
      ],
    };
  },

 
  methods: {
    async submitForm() {
      try {
        const response = await apiUser.createEmployee(this.form);
        this.$router.push("/Medewerkersbeheren");
        this.$refs.form.reset();
        console.log(response);
        this.$swal
          .fire({
            icon: "success",
            title: "Yes!",
            text: "Het aanmaken is gelukt!",
            confirmButtonText: "Kopieer wachtwoord",
            showCancelButton: false,
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              const tempInput = document.createElement("input");
              tempInput.value = response.password;
              document.body.appendChild(tempInput);
              tempInput.select();
              document.execCommand("copy");
              document.body.removeChild(tempInput);
              this.$swal.fire({
                icon: "success",
                title: "Wachtwoord gekopieerd!",
                text: "Het wachtwoord is gekopieerd naar het klembord.",
              });
            }
          });
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          icon: "error",
          title: "Er is een foutmelding!",
          text: "Probeer het nogmaals!",
        });
      }
    },


  },
};
</script>
