import {API} from "./api.base.js"
export default {


  async getPropertysFilter() {
    try {
      const response = await API.get('/api/rest/v2/contract/filter/propertys');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRelationFilter() {
    try {
      const response = await API.get('/api/rest/v2/contract/filter/relations');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTenantFilter() {
    try {
      const response = await API.get('/api/rest/v2/contract/filter/tenants');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
 
};
