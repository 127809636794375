<template>
  <form ref="form" @submit.prevent="submitForm">
    <v-stepper v-model="e13" vertical>
      <v-stepper-step step="1" complete>
        NAW gegevens
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card>
          <v-card-title>
            NAW gegevens
          </v-card-title>

          <v-card-text>
            <v-col cols="2">
              <v-select
                :items="titels"
                label="Titel"
                v-model="form.title"
              ></v-select>
            </v-col>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Voornaam"
                  v-model="form.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Tussenvoegsel"
                  v-model="form.insertion"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Achternaam"
                  v-model="form.lastName"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Telefoonnummer"
                  v-model="form.phone"
                  :prefix="'+31'.toString()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Email" v-model="form.email"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="e13 = 2"
          >
            Volgende
          </v-btn>
          <v-btn @click="$emit('cancel')" text>
            Annuleer
          </v-btn>
        </v-card>
      </v-stepper-content>

      <v-stepper-step step="2" complete>
        Adresgegevens
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card elevation="3">
          <v-card-title>
            Adresgegevens
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-text-field label="Adres" v-model="form.adres"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Huisnummer"
                  v-model="form.houseNumber"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Postcode"
                  v-model="form.postcode"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Stad" v-model="form.city"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="form.country"
                  :items="countries"
                  label="Kies een land"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="e13 = 3"
          >
            Volgende
          </v-btn>
          <v-btn color="error" @click="e13 = 1" text> Annuleer </v-btn>
        </v-card>
      </v-stepper-content>

      <v-stepper-step step="3">
        Administratiegegevens
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card elevation="3">
          <v-card-title>
            Administratiegegevens
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  :items="types"
                  label="Type relatie"
                  v-model="form.type"
                ></v-select>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Contract uploaden"
                  v-model="form.contract"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Informatie"
                  v-model="form.information"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <span style="margin-right:5px">
            <v-btn
              style="background-color: #147D81;
  color: white"
              @click="submitForm()"
              >Aanmaken</v-btn
            >
          </span>
          <v-btn
            style="color:white;background-color:#cb403d"
            @click="$emit('cancel')"
          >
            Afsluiten
          </v-btn>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </form>
</template>

<script>
import axios from "axios";
import apiRelation from "../api/apiRelation";
export default {
  data() {
    return {
      e13: 1,
      expertises: [],
      form: {
        title: "",
        firstName: "",
        insertion: "",
        lastName: "",
        phone: "",
        email: "",
        //Tab 2
        adres: "",
        postcode: "",
        houseNumber: "",
        city: "",
        country: "",
        //Tab 3
        contract: "",
        information: "",
        type: "",
      },
      categorieType: "",
      types: ["Investeerder", "Bedrijf", "Woningcooperatie", "Gemeente"],
      countries: [],
      selectedCountry: null,
      titels: ["Heer", "Mevrouw", "Zeg ik liever niet"],
    };
  },
  methods: {
    async submitForm() {
      if (
        !this.form.firstName ||
        !this.form.lastName ||
        !this.form.phone ||
        !this.form.email
      ) {
        this.$swal.fire(
          "Error",
          "Voornaam, achternaam, telefoonnummer en email zijn verplichte velden",
          "error"
        );
        return;
      }

      try {
        const response = await apiRelation.createRelation(this.form);
        this.$router.push("/Relaties");
        this.$refs.form.reset();
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.$emit("relation-added");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    getCountries() {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          this.countries = response.data.map((country) => country.name.common);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
