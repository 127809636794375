
<template>
  <Folders />
</template>

<script>
import Folders from "@/components/instellingen/Folders";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Folders,
  },
  metaInfo: {
 title: "FLEX VASTGOED CRM | Folders",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>