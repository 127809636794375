<template>
  <Mechanics />
</template>

<script>
import Mechanics from "@/components/mechanic/Mechanics";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Mechanics,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Monteurs ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>