<template>
  <v-container style="padding-top: 4rem">
    <v-row>
      <v-col cols="12" md="4">
        <v-row>
          <div style="overflow-y: auto; max-height: calc(100vh - 100px);">
            <v-col cols="12" v-for="repair in repairs" :key="repair.id">
              <div style="max-height: 400px; overflow-y: auto;">
                <v-card outlined>
                  <v-list-item three-line>
                    <!-- Card content -->
                    <v-list-item-content>
                      <v-list-item-title>
                        <b> {{ repair.title }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="repair.excutionDate"
                        >Uitvoerdatum:
                        {{ repair.excutionDate }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle v-if="!repair.excutionDate"
                        >Uitvoerdatum: nog niet bekend</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        >Status: {{ repair.status }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>

                  <!-- Card actions -->
                  <v-card-actions>
                    <v-btn
                      v-if="!repair.excutionDate"
                      small
                      style="background-color: #147D81;
  color: white"
                      @click="openRepair(repair.id)"
                      rounded
                    >
                      Inplannen
                    </v-btn>
                    <v-btn
                      v-if="repair.excutionDate"
                      small
                      style="background-color: #147D81;
  color: white"
                      @click="openRepair(repair.id)"
                      rounded
                    >
                      Datum wijzigen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-col>
          </div>
        </v-row>
      </v-col>
      <v-col cols="12" md="8">
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64"> </v-sheet>

            <v-sheet height="600">
              <v-row class="center" style="padding-bottom:10px">
                <v-col cols="3">
                  <v-btn small color="warning" @click="prev"
                    >Vorige Maand</v-btn
                  >
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-btn small color="primary" @click="next"
                    >Volgende Maand</v-btn
                  >
                </v-col>
              </v-row>
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="updateEvents"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card color="grey lighten-4" min-width="350px" flat>
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-btn icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>

                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <p>
                      <b>Woning: </b>
                      <span v-html="selectedEvent.houseAdres"></span> <br />

                      <b>Status: </b>
                      <span v-html="selectedEvent.status"></span> <br />
                      <b>Aangemaakt door: </b>
                      <span v-html="selectedEvent.createdBy"></span>
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false">
                      Annuleren
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRepair from "../api/apiRepair";
export default {
  data: () => ({
    repairs: [],
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: null,
  }),

  mounted() {
    this.$refs.calendar.checkChange();
    apiRepair.getRepaircalander().then((data) => {
      this.repairs = data;
    });
    apiRepair.getRepaircalander().then((data) => {
      this.events = data
        .filter((event) => event.excutionDate)
        .map((event) => ({
          name: event.title,
          status: event.status,
          createdBy: event.createdBy,
          houseAdres:
            event.property && event.property.adres
              ? event.property.adres
              : "Geen adres",
          start: new Date(new Date(event.excutionDate).getTime() + 4 * 3600000),
          end: new Date(new Date(event.excutionDate).getTime() + 4 * 3600000),
          color: "blue",
          timed: true,
        }));
    });
  },

  methods: {
    openRepair(id) {
      this.$router.push(`/SingleReparatie/${id}`);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    updateEvents() {
      this.events
        .filter((event) => event.excutionDate)
        .map((event) => ({
          name: event.title,
          start: new Date(new Date(event.excutionDate).getTime() + 4 * 3600000),
          end: new Date(new Date(event.excutionDate).getTime() + 4 * 3600000),
          color: "blue",
          timed: true,
        }));
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
};
</script>

<style></style>
