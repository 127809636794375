<template>
  <v-container>
    <div>
      <v-btn
        style="background-color: #147D81;color: white;margin-bottom: 15px;"
        @click="generatePdf()"
        class="btn"
        >PDF downloaden</v-btn
      >
      <v-row>
        <v-col cols="6">
          <h5>Algemeen</h5>
          <v-text-field
            class="smaller-input"
            label="Wordt automatisch gegenereerd"
            disabled
          >
          </v-text-field>
          <v-text-field
            size="s"
            v-model="contract.title"
            label="Contractnummer Titel"
          >
          </v-text-field>

          <v-text-field v-model="contract.type" disabled label="Contracttype">
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <h5>Financieel</h5>
          <v-text-field
            type="number"
            prefix="€"
            label="Contractbedrag"
            v-model="contract.contractAmount"
          >
          </v-text-field>
          <v-text-field v-model="contract.indexDate" disabled></v-text-field>

          <v-switch
            v-model="contract.automaticIndexing"
            label="Automatisch indexering"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>Contractgegevens</h5>
          <v-text-field
            v-model="contract.startDate"
            disabled
            label="Startdatum"
          >
          </v-text-field>
          <v-text-field v-model="contract.endDate" disabled label="Einddatum">
          </v-text-field>
          <v-text-field
            v-model="contract.cancellationDate"
            disabled
            label="Opzegdatum"
          >
          </v-text-field>

          <v-text-field
            v-model="contract.extensionPeriod"
            disabled
            label="verlengingsperiode"
          >
          </v-text-field>
          <v-text-field
            v-model="contract.notificationDate"
            disabled
            label="Notificatiedateum"
          >
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <h5>Notitie & informatie</h5>
          <v-select
            :items="contractstatus"
            v-model="contract.status"
            label="Status"
          ></v-select>

          <v-text-field v-model="contract.assign" label="Toegewezen aan">
          </v-text-field>

          <v-textarea
            name="input-7-1"
            label="contract notitie"
            hint="Hint text"
            v-model="contract.description"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-btn
        rounded
        style="background-color: #147D81;color: white"
        @click="assignContract()"
        class="btn"
        >Contract wijzigingen</v-btn
      >
      <v-btn
        @click="goBack()"
        color="red darken-1"
        style="color:white; margin-left:5px"
        rounded
      >
        Afsluiten
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import apiContract from "../api/apiContract";

export default {
  data() {
    return {
      contractstatus: [
        "Actief",
        "Inactief",
        "Verlopen",
        "In behandeling",
        "Afgewezen",
        "Voltooid",
      ],

      contract: {},
    };
  },

  async mounted() {
    apiContract.getContractById(this.$route.params.id).then((data) => {
      this.contract = data;
    });
  },

  methods: {
    goBack() {
      this.$router.push("../../Contracten");
    },
    async generatePdf() {
  try {
    const id = this.$route.params.id;
    console.log("generatePdf function called with ID:", id);

    const response = await apiContract.generatePDF(id, {
      responseType: "blob",
    });
    console.log("Server response data:", response.data);
    const blob = new Blob([response.data], { type: "application/pdf" });
    const blobURL = window.URL.createObjectURL(blob);
    window.open(blobURL);
    window.URL.revokeObjectURL(blobURL);
  } catch (error) {
    console.error("Er is een fout opgetreden bij het downloaden van de factuur:", error);
  }
},
    async SaveContract() {
      try {
        const data = {
          ...this.contract,
          indexDate: this.parseDate(this.formattedIndexDate),
          endDate: this.parseDate(this.formattedEndDate),
          notificationDate: this.parseDate(this.formattedNotificationDate),
        };
        const response = await apiContract.createContract(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.contract = await apiContract.getContracts();
        this.dialog.value = false;
        this.content = "";
        this.title = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
