
<template>
  <GDPR />
</template>

<script>
import GDPR from "@/components/instellingen/GDPR";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    GDPR,
  },
  metaInfo: {
    title: "FLEX VASTGOED CRM | GDPR Privacy",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>