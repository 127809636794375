import {API} from "./api.base.js"
export default {

  async  createContractType(data) {
    try {
      const response = await API.post('/api/rest/v2/contract/type', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getContractType() {
    try {
      const response = await API.get('/api/rest/v2/contract/type');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteContractType(id) {
    try {
      const response = await API.delete(`/api/rest/v2/contract/type/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateContractType(id) {
    try {
      const response = await API.put(`/api/rest/v2/contract/type/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
