<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-col cols="12">
        <div v-if="filteredIncidents.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
        <v-card v-else>
          <v-card-title>
            <h5>Meldingen</h5>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Opzoeken"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-icon @click="showFilter = !showFilter" color="primary"
            >fas fa-filter</v-icon
          >Filter op Status

          <v-row v-if="showFilter" style="margin-left:30px">
            <v-checkbox
              v-for="status in statussen"
              :key="status.id"
              v-model="selectedStatuses"
              :label="status.name"
              :value="status.id"
            ></v-checkbox>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="filteredIncidents"
            :search="search"
            disable-pagination
            :hide-default-footer="true"
            class="custom-table-header"
            id="my-tenants"
            cellClass="printable"
          >
            <template v-slot:[`item.Actions`]="{ item }">
              <span style="margin-right:5px">
                <v-icon small color="green" @click="openIncident(item.id)">
                  mdi-eye
                </v-icon>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import apiIncidentstatus from "../api/apiIncidentstatus";
import apiIncident from "../api/apiIncident";

export default {
  data: () => ({
    showFilter: false,
    search: "",
    selectedStatuses: [],
    statussen: [],
    incidents: [],
    incident: {
      subject: "",
      question: "",
      status: null,
    },
    headers: [
      {
        text: "Onderwerp",
        value: "subject",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Aangemaakt:",
        value: "createDate",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Gewijzigd op:",
        value: "updateDate",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Status:",
        value: "status",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),
  computed: {
    filteredIncidents() {
      if (!this.incidents) return [];

      // Standaard filter: verberg 'Gesloten' meldingen
      let result = this.incidents.filter((incident) => {
        return incident.status !== "Gesloten";
      });
      if (this.selectedStatuses.length > 0) {
        result = this.incidents.filter((incident) => {
          return this.selectedStatuses.includes(incident.status.id);
        });
      }

      return result;
    },
  },
  async mounted() {
    await this.fetchData();
  },

  methods: {
    async fetchData() {
      const [incidents, statussen] = await Promise.all([
        apiIncident.getIncidents(),
        apiIncidentstatus.getIncidentsStatus(),
      ]);
      this.incidents = incidents.map((incident) => ({
        id: incident.id,
        subject: incident.subject,
        question: incident.question,
        createDate: incident.createDate,
        updateDate: incident.updateDate,
        status: incident.status.name,
      }));

      this.statussen = statussen;
    },

    openIncident(meldingId) {
      this.$router.push(`/MeldingBekijken/${meldingId}`);
    },

    resetForm() {
      this.incident.subject = "";
      this.incident.question = "";
    },

    async createIncident() {
      try {
        const response = await apiIncident.createIncident(this.incident);
        console.log(response);
        this.$swal.fire("Yes!", "De melding is aangemaakt!", "success");
        await this.fetchData();
        this.resetForm();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.space {
  margin: 3px;
}

.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
