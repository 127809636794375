<template>
  <v-container style="padding-top: 4rem">
    <div>
      <v-row>
        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Project naam"
            v-model="form.name"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Aangemaakt"
            v-model="form.createdBy"
            disabled
            outlined
          ></v-text-field>
        </v-col>

<v-col cols="12" md="6" xl="6">
  <v-text-field
    label="Object"
    :value="form.houses.length > 0 ? form.houses[0].adres : ''"
    outlined
    disabled
  ></v-text-field>
</v-col>
<v-col cols="12" md="6" xl="6">
  <v-text-field
    label="Gekoppelde relatie"
    :value="form.relations.length > 0 ? form.relations[0].firstName : ''"
    disabled
    outlined
  ></v-text-field>
</v-col>



        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Startdatum"
            v-model="form.startDate"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Einddatum"
            v-model="form.endDate"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Geschatte uren"
            v-model="form.estimatedHours"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Geschatte kosten"
            v-model="form.estimatedCost"
            prefix="Euro"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-text-field
            label="Bestede kosten"
            v-model="form.billing"
            prefix="Euro"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6" xl="6">
          <v-select
            label="Type"
            v-model="form.type"
            :items="types"
            outlined
          ></v-select>
        </v-col>

        <v-col cols="12" md="6" sm="6" xl="6">
          <v-select
            outlined
            :items="statussen"
            v-model="form.status"
            label="Status"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-textarea
            outlined
            label="Project informatie"
            v-model="form.description"
          ></v-textarea>
        </v-col>

        <v-col cols="12">
          <div style="display: flex; justify-content: center;">
            <v-btn style="background-color: #147D81;
  color: white" @click="updateStatus()"
              >Project bijwerken</v-btn
            >
            <v-btn style="color:white;background-color:#cb403d" @click="goBack()"
              >Afsluiten</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiProject from "../api/apiProject";
export default {
  data() {
    return {
      types: ["Per uur", "Vast tarief"],
      statussen: ["Actief", "Afgerond", "In behandeling", "In bespreking"],
      form: {
            houses: [],
              relations: [],
      },
    };
  },
  mounted() {
    apiProject.getProjectById(this.$route.params.id).then((data) => {
      this.form = data;
    });
  },
  methods: {
    goBack() {
      this.$router.push("../../Projecten");
    },
    async updateStatus() {
      const id = this.$route.params.id;
      try {
        const response = await apiProject.updateProject(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.button {
  color: white;
  margin: 4px;
}
</style>
