<template>
  <v-container>
    <v-col>
      <v-dialog v-model="dialog" width="650">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            style="background-color: #147D81;
  color: white"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Project toevoegen
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            Nieuwe project aanmaken
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="project.projectName"
              label="Projectnaam"
            ></v-text-field>

            <v-text-field
              v-model="project.estimatedHours"
              label="Geschatte uren"
              type="number"
            ></v-text-field>

            <v-text-field
              v-model="project.estimatedCost"
              label="Geschatte kosten"
              prefix="Euro"
              type="number"
            ></v-text-field>

            <v-row>
              <v-col cols="6">
                <v-menu
                  ref="startMenu"
                  v-model="projectMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="project.startDate"
                      label="Start datum"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="project.startDate"
                    @input="$refs.startMenu.save(project.startDate)"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="endMenu"
                  v-model="projectMenu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="project.endDate"
                      label="Eind datum"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="project.endDate"
                    @input="$refs.endMenu.save(project.endDate)"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-textarea
              name="input-7-1"
              label="Omschrijving"
              value=""
              v-model="project.description"
            ></v-textarea>
            <v-col cols="12">
              <label> Is het project gekoppeld aan een relatie?</label>
              <v-radio-group v-model="houseType">
                <v-radio label="Ja" value="Ja" />
                <v-radio label="Nee" value="Nee" />
              </v-radio-group>
              <v-autocomplete
                v-if="houseType === 'Ja'"
                :items="relations.flat()"
                label="Relatie selecteren"
                item-text="firstName"
                item-value="id"
                v-model="project.relation"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <label> Heeft het project betrekking op een woning?</label>
              <v-radio-group v-model="relationType">
                <v-radio label="Ja" value="Ja" />
                <v-radio label="Nee" value="Nee" />
              </v-radio-group>
              <v-autocomplete
                v-if="relationType === 'Ja'"
                :items="objects.flat()"
                label="Huis selecteren"
                v-model="project.house"
                item-text="adres"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">
              Annuleren
            </v-btn>
            <v-btn
              style="background-color: #147D81;
  color: white"
              @click="createProject()"
            >
              Aanmaken
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-container>
</template>
<script>
import apiRelation from "../api/apiRelation";
import apiHouse from "../api/apiHouse";
import apiProject from "../api/apiProject";

export default {
  data: () => ({
    projectMenu: null,
    projectMenu1: null,
    project: {
      relation: "",
      house: "",
      projectName: "",
      description: "",
      startDate: null,
      endDate: null,
      estimatedHours: "",
      estimatedCost: "",
    },
    objects: [],
    relationType: "",
    houseType: "",
    dialog: false,
    model: false,
    relations: [],
    dialogDelete: false,
    items: [],
  }),

  async mounted() {
    await this.getRelations();
    apiProject.getProjects().then((data) => {
      this.items = data;
    });
    apiHouse.getHouse().then((data) => {
      this.objects = data;
    });
  },

  methods: {
    async getRelations() {
      const relations = await apiRelation.getRelation();
      this.relations = relations;
    },

    async createProject() {
      try {
        const data = {
          relation: this.project.relation,
          house: this.project.house,
          name: this.project.projectName,
          description: this.project.description,
          startDate: this.project.startDate,
          endDate: this.project.endDate,
        };
        const response = await apiProject.createProject(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        apiProject.getProjects().then((data) => {
          this.items = data;
        });
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
