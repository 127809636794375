var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{staticStyle:{"margin-top":"40px"}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"sm":"6","lg":"6","md":"4"}},[_c('v-text-field',{attrs:{"solo":"","label":"Oud wachtwoord","prepend-inner-icon":"mdi-lock-question"},model:{value:(_vm.form.oldPassword.value),callback:function ($$v) {_vm.$set(_vm.form.oldPassword, "value", $$v)},expression:"form.oldPassword.value"}}),_c('v-text-field',{class:{
              'is-valid':
                _vm.form.newPassword.value.length > 0 &&
                _vm.has_minimum_length &&
                _vm.has_number &&
                _vm.has_lowercase &&
                _vm.has_uppercase &&
                _vm.has_special,
              'is-invalid':
                _vm.form.newPassword.value.length > 0 &&
                !(
                  _vm.has_minimum_length &&
                  _vm.has_number &&
                  _vm.has_lowercase &&
                  _vm.has_uppercase &&
                  _vm.has_special
                ),
            },attrs:{"solo":"","label":"Nieuw wachtwoord","prepend-inner-icon":"mdi-lock","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.form.newPassword.value),callback:function ($$v) {_vm.$set(_vm.form.newPassword, "value", $$v)},expression:"form.newPassword.value"}}),(_vm.form.newPassword.value.length > 0)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.has_minimum_length),expression:"!has_minimum_length"}],staticClass:"text-danger small"},[_vm._v(" - Het wachtwoord moet minstens 8 karakters lang zijn ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.has_number),expression:"!has_number"}],staticClass:"text-danger small"},[_vm._v(" - Het wachtwoord moet minimaak 1 cijfer bevatten ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.has_lowercase),expression:"!has_lowercase"}],staticClass:"text-danger small"},[_vm._v(" - Het wachtwoord moet minimaal een kleinletter bevatten ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.has_uppercase),expression:"!has_uppercase"}],staticClass:"text-danger small"},[_vm._v(" - Het wachtwoord moet minimaal een hoofdletter bevatten ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.has_special),expression:"!has_special"}],staticClass:"text-danger small"},[_vm._v(" - Het wachtwoord moet minimaal een van deze karakters bevatten !?@#$%^&*().;:_- ")])]:_vm._e(),_c('v-btn',{staticStyle:{"background-color":"#147D81","color":"white"},attrs:{"disabled":!(
                _vm.has_minimum_length &&
                _vm.has_number &&
                _vm.has_lowercase &&
                _vm.has_uppercase &&
                _vm.has_special
              )},on:{"click":function($event){return _vm.changeCredentials()}}},[_vm._v(" wachtwoord wijzigen ")])],2),_c('v-col',{staticClass:"d-flex flex-column text-center",staticStyle:{"background-color":"#64b5f6","color":"white"},attrs:{"sm":"6","lg":"6","md":"4"}},[_c('h5',[_c('b',[_vm._v(" Tip ")])]),_c('p',[_vm._v(" Gebruik kleine letters, hoofdletters, cijfers, speciale tekens en spaties. Gebruik nooit voor de hand liggende woorden of reeksen, zoals de naam van je partner of kinderen, reeksen als 12345 of qwerty, welkom01. Verzin geen wachtwoord dat erg lijkt op één van jouw andere wachtwoorden. ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }