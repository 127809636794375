<template>
  <Facilities />
</template>

<script>
import Facilities from "@/components/realestateHelper/Facilities";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Facilities,
  },
  metaInfo: {
    title: "Flex CRM | Faciliteiten beheren",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>