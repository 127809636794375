<template>
  <Tenants />
</template>

<script>
import Tenants from "@/components/tenant/Tenants";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Tenants,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Huurders ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
