<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex">
        <span class="headline">Sleutels overzicht</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Opzoeken"
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-row>
        <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
          <v-card>
            <v-card-title>
              <div class="key-icon-number">
               
                {{ item.keynumber }} <v-icon small color="green" >
                      mdi-key
                    </v-icon>
              </div>
            </v-card-title>

            <v-card-text>
            
            </v-card-text>
            <v-card-actions>
              <v-dialog transition="dialog-top-transition" max-width="600">
                <template v-slot:activator="{ on, attrs }">
  
                   
                    <v-btn color="#147d81" dense v-bind="attrs" v-on="on" style="color:white">
                      Sleutel uitlenen
                    </v-btn>
              
        
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      style="background-color: #147D81;
  color: white"
                      dark
                      >Sleutel uitlenen</v-toolbar
                    >
                    <v-card-text>
                      <label>
                        Selecteer aan wie u de sleutel wil uitlenen
                      </label>

                      <v-radio-group v-model="selectrelation">
                        <v-radio
                          label="Niet uitgeleend"
                          value="Niet uitgeleend"
                        />
                        <v-radio label="Huurders" value="Huurder" />
                        <v-radio label="Relatie" value="Relatie" />
                        <v-radio label="Montuer" value="Monteur" />
                      </v-radio-group>

                      <v-autocomplete
                        v-if="selectrelation === 'Huurder'"
                        :items="tenantsWithFullName"
                        label="Huurder selecteren"
                        v-model="tenant"
                        item-text="fullNameTenant"
                        item-value="fullNameTenant"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="selectrelation === 'Relatie'"
                        :items="relationsWithFullName"
                        label="Relatie selecteren"
                        v-model="relation"
                        item-text="fullNameRelation"
                        item-value="fullNameRelation"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="selectrelation === 'Monteur'"
                        :items="mechanic"
                        label="Reparateur selecteren"
                        v-model="selectedmechanic"
                        item-text="fullName"
                        item-value="fullName"
                      ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                        style="color:white;background-color:#cb403d"
                        >Afsluiten</v-btn
                      >
                      <v-btn text @click="updateKey(item.id)">Aanmaken</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import apiTenant from "../api/apiTenant";
import apiRelation from "../api/apiRelation";
import apiMechanics from "../api/apiMechanics";
import apiKeys from "../api/apiKey";
export default {
  data: () => ({
    default: "",
    dialogOpen: false,
    search: "",
    items: [],
    relations: [],
    tenants: [],
    selectrelation: "",
    mechanics: [],
    selectedmechanic: "",
    tenant: "",
    relation: "",
    headers: [
      {
        text: "Sleutelnummer",
        value: "keynumber",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    closeDialog() {
      this.dialogOpen = false;
    },
    async updateKey(id) {
      try {
        let lenTo = null;
        if (this.selectrelation === "Huurder") {
          lenTo = this.tenant;
        } else if (this.selectrelation === "Relatie") {
          lenTo = this.relation;
        } else if (this.selectrelation === "Monteur") {
          lenTo = this.selectedmechanic;
        }

        const body = { lentTo: lenTo };

        const response = await apiKeys.updateKey(id, body);

        console.log(response);

        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
        this.items = await apiKeys.getKeys();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },
  },

  computed: {
    mechanic() {
      return this.mechanics.map((mechanic) => {
        return {
          ...mechanic,
          fullName: mechanic.firstName + " " + mechanic.lastName,
        };
      });
    },
    tenantsWithFullName() {
      return this.tenants.map((tenant) => {
        return {
          ...tenant,
          fullNameTenant: tenant.firstName + " " + tenant.lastName,
        };
      });
    },
    relationsWithFullName() {
      return this.relations.map((relation) => {
        return {
          ...relation,
          fullNameRelation: relation.firstName + " " + relation.lastName,
        };
      });
    },
  },
  async mounted() {
    apiKeys.getKeys().then((data) => {
      this.items = data;
    });
    apiRelation.getRelation().then((data) => {
      this.relations = data;
    });
    apiTenant.getTenants().then((data) => {
      this.tenants = data;
    });
    apiMechanics.getMechanics().then((data) => {
      this.mechanics = data;
    });
  },
};
</script>

<style scoped>
.key-icon-number {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  color: #147d81;
}


@media (max-width: 600px) {
  .v-container {
    padding: 1rem;
  }
  .headline {
    font-size: 1.2rem;
  }
}
</style>
