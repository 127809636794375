import {API} from "./api.base.js"
export default {



async sendMailBasedonTemplate(data) {
  try {
      const response = await API.post(`/api/rest/v2/repairs/send/mail/`, data);
      return response.data;
  } catch (error) {
      console.error(error);
  }
},


};
