<template>
  <v-container>
    <div>
      <v-row>
        <v-col cols="6">
          <h5>Algemeen</h5>
          <v-text-field
            class="smaller-input"
            label="Wordt automatisch gegenereerd"
            disabled
          >
          </v-text-field>
          <v-text-field
            size="s"
            v-model="contract.title"
            label="Contractnummer Titel"
          >
          </v-text-field>

          <v-autocomplete
            :items="types.flat()"
            label="Selecteer een type contract"
            v-model="contract.type"
            item-text="name"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col cols="6">
          <h5>Financieel</h5>
          <v-text-field
            type="number"
            prefix="€"
            label="Contractbedrag"
            v-model="contract.contractAmount"
          >
          </v-text-field>
          <v-menu
            v-model="menuIndexDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedIndexDate"
                @input="updateIndexDate($event)"
                label="Indexatiedatum"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contract.indexDate"
              no-title
              format="DD/MM/YYYY"
              @input="menuIndexDate = false"
            ></v-date-picker>
          </v-menu>
          <v-switch
            v-model="contract.automaticIndexing"
            label="Automatisch indexering"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h5>Contractgegevens</h5>
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedStartDate"
                label="Aanvangsdatum"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contract.startDate"
              no-title
              format="DD/MM/YYYY"
              @input="menuStartDate = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedEndDate"
                label="Einddatum"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contract.endDate"
              no-title
              format="DD/MM/YYYY"
              @input="menuEndDate = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="menuCancellationDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedCancellationDate"
                label="Opzegdatum"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contract.cancellationDate"
              no-title
              format="DD/MM/YYYY"
              @input="menucancellationDateDate = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model="contract.extensionPeriod"
            type="number"
            prefix="weken"
            label="Verlengingsperiode"
          ></v-text-field>

          <v-menu
            v-model="menuNotificationDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formattedNotificationDate"
                label="Notificatiedatum"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="contract.notificationDate"
              no-title
              format="DD/MM/YYYY"
              @input="menuNotificationDate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="6">
          <h5>Notitie & informatie</h5>
          <v-select
            :items="contractstatus"
            v-model="contract.status"
            label="Status"
          ></v-select>

          <v-select
            :items="assinging"
            v-model="assign"
            label="Toewijzen"
          ></v-select>

          <v-autocomplete
            v-if="assign == 'Huurder'"
            :items="tenantfilter.flat()"
            label="Selecteer huurder"
            item-text="firstName"
            item-value="id"
            v-model="contract.tenantId"
          ></v-autocomplete>

          <v-autocomplete
            v-if="assign == 'Relatie'"
            :items="relationfilter.flat()"
            label="Selecteer Relatie"
            item-text="firstName"
            item-value="id"
            v-model="contract.relationId"
          ></v-autocomplete>

          <v-autocomplete
            v-if="assign == 'Object'"
            :items="propertyfilter.flat()"
            label="Selecteer een type object"
            item-text="adres"
            item-value="id"
            v-model="contract.propertyId"
          ></v-autocomplete>
          <v-autocomplete
            :items="contractTemplates.flat()"
            label="Contract template selecteren"
            v-model="contract.templateId"
            item-text="title"
            item-value="id"
          ></v-autocomplete>

          <v-textarea
            name="input-7-1"
            label="contract notitie"
            hint="Hint text"
            v-model="contract.description"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-btn
        rounded
        style="background-color: #147D81;color: white"
        @click="createContract()"
        class="btn"
        >Contract bekrachtigen</v-btn
      >
      <v-btn
        @click="cancelForm"
        color="red darken-1"
        style="color:white; margin-left:5px"
        rounded
      >
        Afsluiten
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import apiContract from "../api/apiContract";
import apiContractFilter from "../api/apiContractFilter";
import apiContractTemplate from "../api/apiContractTemplate";
import apiContractType from "../api/apiContractType";
export default {
  data() {
    return {
      assinging: ["Relatie", "Huurder", "Object", "Niet van toepassing"],
      assign: "",

      contractTemplates: [],
      contractstatus: [
        "Actief",
        "Inactief",
        "Verlopen",
        "In behandeling",
        "Afgewezen",
        "Voltooid",
      ],
      types: [],
      menuStartDate: false,
      menuEndDate: false,
      menuCancellationDate: false,
      menuNotificationDate: false,
      menuIndexDate: false,
      propertyfilter: [],
      relationfilter: [],
      tenantfilter: [],
      contract: {
        title: "",
        description: "",
        type: "",
        templateId: null,
        relationId:null,
        propertyId:null,
        tenantId:null,
        contractType:null,

        status: "",
        startDate: null,
        endDate: null,
        cancellationDate: null,
        extensionPeriod: "",
        notificationDate: null,
        automaticIndexing: false,
        contractAmount: null,
        indexDate: null,
      },
    };
  },

  computed: {
    formattedIndexDate: {
      get() {
        return this.formatDate(this.contract.indexDate);
      },
      set(newValue) {
        this.contract.indexDate = this.parseDate(newValue);
      },
    },
    formattedStartDate: {
      get() {
        return this.formatDate(this.contract.startDate);
      },
      set(newValue) {
        this.contract.startDate = this.parseDate(newValue);
      },
    },
    formattedEndDate: {
      get() {
        return this.formatDate(this.contract.endDate);
      },
      set(newValue) {
        this.contract.endDate = this.parseDate(newValue);
      },
    },
    formattedNotificationDate: {
      get() {
        return this.formatDate(this.contract.notificationDate);
      },
      set(newValue) {
        this.contract.notificationDate = this.parseDate(newValue);
      },
    },
    formattedCancellationDate: {
      get() {
        return this.formatDate(this.contract.cancellationDate);
      },
      set(newValue) {
        this.contract.cancellationDate = this.parseDate(newValue);
      },
    },
  },

  async mounted() {
    apiContractType.getContractType().then((data) => {
      this.types = data;
    });
    apiContractTemplate.contractTemplateFilter().then((data) => {
      this.contractTemplates = data;
    });
    apiContractFilter.getPropertysFilter().then((data) => {
      this.propertyfilter = data;
    });
    apiContractFilter.getRelationFilter().then((data) => {
      this.relationfilter = data;
    });
    apiContractFilter.getTenantFilter().then((data) => {
      this.tenantfilter = data;
    });
  },
  watch: {
    "contract.indexDate": function(newDate) {
      this.formattedIndexDate = this.formatDate(newDate);
    },
    "contract.startDate": function(newDate) {
      this.formattedStartDate = this.formatDate(newDate);
    },
    "contract.endDate": function(newDate) {
      this.formattedEndDate = this.formatDate(newDate);
    },
    "contract.cancellationDate": function(newDate) {
      this.formattedCancellationDate = this.formatDate(newDate);
    },

    "contract.notificationDate": function(newDate) {
      this.formattedNotificationDate = this.formatDate(newDate);
    },
  },

  methods: {
    resetForm() {
      this.contract = {
        title: "",
        description: "",
        type: "",
        template: "",
        status: "",
        startDate: null,
        endDate: null,
        cancellationDate: null,
        extensionPeriod: "",
        notificationDate: null,
        automaticIndexing: false,
        contractAmount: null,
        indexDate: null,
      };
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async createContract() {
      try {
        const data = {
          ...this.contract,
          indexDate: this.parseDate(this.formattedIndexDate),
          startDate: this.parseDate(this.formattedStartDate),
          endDate: this.parseDate(this.formattedEndDate),
          notificationDate: this.parseDate(this.formattedNotificationDate),
        };
        const response = await apiContract.createContract(data);
        this.resetForm();
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    cancelForm() {
      this.$emit("cancel");
    },
  },
};
</script>
