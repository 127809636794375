<template>
  <div>
    <div v-if="filteredProjects.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
    <v-row v-else>
      <v-col cols="12">
        <v-card>
          <v-card-title>
           
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Opzoeken"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col v-for="letter in letters" :key="letter" cols="auto" style="margin-left: 20px">
              <v-btn
                :color="selectedLetter === letter ? '#147D81' : ''"
                @click="selectLetter(letter)"
              >
                {{ letter }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="item in filteredProjects" :key="item.id" cols="12" md="6" lg="4">
              <v-hover v-slot:default="{ hover }">
                <v-card style="margin-left: 20px" :elevation="hover ? 24 : 6">
                  <v-alert
                    :color="getCardClass(item.status)"
                   
                    colored-border
                  ></v-alert>

                  <v-card-title>{{ item.name }}</v-card-title>

                  <v-card-text>
                    <div>Aangemaakt door: {{ item.createdBy }}</div>
                    <div>Status: {{ item.status }}</div>
                    <div>Start datum: {{ item.startDate }}</div>
                    <div>Eind datum: {{ item.endDate }}</div>
                  </v-card-text>
                  <v-card-actions>
                    <v-icon small color="green" @click="openProject(item.id)">
                      mdi-eye
                    </v-icon>
                    <v-icon small color="red" @click="deleteProject(item.id)">
                      mdi-delete
                    </v-icon>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import apiProject from "../api/apiProject";

export default {
  data() {
    return {
      search: "",
      hover:false,
      items: [],
      selectedLetter: "",
      letters: ["Actief", "Afgerond", "In behandeling", "In bespreking"],
    };
  },
  async mounted() {
    apiProject.getProjects().then((data) => {
      this.items = data;
    });
  },
  computed: {
    filteredProjects() {
      if (this.items.length > 0 && this.selectedLetter) {
        return this.items.filter((item) => {
          return item.status.includes(this.selectedLetter);
        });
      }
      return this.items;
    },
  },
  methods: {
    selectLetter(letter) {
      this.selectedLetter = letter;
    },
    async openProject(id) {
      this.$router.push(`/project/informatie/${id}`);
    },
    getCardClass(status) {
      switch (status) {
        case "Actief":
          return "green";
        case "In behandeling":
          return "yellow";
        case "In bespreking":
          return "orange";
        case "Afgerond":
          return "blue";
        default:
          return "";
      }
    },
    async deleteProject(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je dit project wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiProject.deleteProject(id);
        this.$swal.fire(
          "Verwijderd",
          "Het project is succesvol verwijderd",
          "success"
        );
        apiProject.getProjects().then((data) => {
          this.items = data;
        });
      }
    },
  },
};
</script>
