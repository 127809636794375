import {API} from "./api.base.js"
export default {

  async generateApiKeys() {
    try {
      const response = await API.post('/api/rest/v2/apikey');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getApiKey() {
    try {
      const response = await API.get("/api/rest/v2/apikey");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteApiKey(id) {
    try {
      const response = await API.delete(`/api/rest/v2/apikeys/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
