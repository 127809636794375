<template>
  <Categories />
</template>

<script>
import Categories from "@/components/realestateHelper/Categories";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Categories,
  },
  metaInfo: {
    title: "Flex CRM | Categorieen wijzigen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>