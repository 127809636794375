<template>
  <v-container style="padding-top:2rem">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-text>
           <h6 style="text-align: center;">Mailimport instellen</h6>

         
            <v-row >
              <v-col cols="12">
                <v-text-field
                  dense
                  placeholder="Voeg client ID"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
                <v-text-field
                  dense
                  placeholder="Tenant ID"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
                <v-text-field
                  dense
                  placeholder="Client secret"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Emailadres "
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  :color="isEditing ? 'primary' : 'secondary'"
                  @click="isEditing = !isEditing"
                  block
                >
                  {{ isEditing ? "Opslaan" : "Configuratie wijzigen" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isEditing: false,
    };
  },
};
</script>

<style scoped>
.space {
  margin-top: 10px;
}
</style>
