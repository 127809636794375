<template>
  <v-container>
    <div>
      <form ref="form" @submit.prevent="submitForm">
        <v-tabs v-model="tab" background-color="#147D81" dark>
          <v-tab v-for="(item, i) in items" :key="i">
            {{ item.tab }}
          </v-tab>

          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                NAW gegevens
              </v-card-title>

              <v-card-text>
                <v-col cols="2">
                  <v-select
                    :items="titels"
                    label="Titel"
                    v-model="mechanic.title"
                  ></v-select>
                </v-col>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Voornaam"
                      v-model="mechanic.firstName"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      label="Tussenvoegsel"
                      v-model="mechanic.insertion"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Achternaam"
                      v-model="mechanic.lastName"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Telefoonnummer"
                      v-model="mechanic.phone"
                      :prefix="'+31'.toString()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Email"
                      v-model="mechanic.email"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                Adresgegevens
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Adres"
                      v-model="mechanic.adres"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Huisnummer"
                      v-model="mechanic.houseNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Postcode"
                      v-model="mechanic.postcode"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Stad"
                      v-model="mechanic.city"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="mechanic.country"
                     disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                Administratiegegevens
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-autocomplete
               
                      :items="expertises"
                     
                      dense
                    chips
            small-chips
                      label="Expertise"
                      v-model="mechanic.expert"
                      item-text="name"
                      item-value="id"
                      multiple
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field label="Contract"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Informatie"
                      v-model="mechanic.information"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <span style="margin-right:5px">
          <v-btn style="background-color: #147D81;
  color: white" v-if="showNextButton" @click="nextTab"
            >Volgende</v-btn
          >
          <v-btn style="background-color: #147D81;
  color: white" v-if="showUpdateButton" @click="updateMechanic(mechanic.id)"
            >Update</v-btn
          >
        </span>
        <v-btn style="color:white;background-color:#cb403d" @click="$router.push('/Monteursbeheren')"> Afsluiten </v-btn>
      </form>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import apiMechanics from "../api/apiMechanics";
export default {
  data() {
    return {
      expertises: [],
      mechanic: {},
      tab: null,
      countries: [],
      selectedCountry: null,
      titels: ["Heer", "Mevrouw", "Zeg ik liever niet"],
      items: [
        { tab: "NAW gegevens", content: "Tab 1 Content" },
        { tab: "Adresgegevens", content: "Tab 2 Content" },
        { tab: "Administratiegegevens", content: "Tab 3 Content" },
      ],
      tabIndex: 0,
      showNextButton: true,
      showUpdateButton: false,
    };
  },

  mounted() {
  apiMechanics.getMechanicById(this.$route.params.id).then((data) => {
    this.mechanic = data;
    this.expertises = data.expertises;
    this.mechanic.expert = data.expertises.map(expertise => expertise.id);
  });
},


  methods: {
    nextTab() {
      if (this.tab < this.items.length - 1) {
        this.tab++;
      } else {
        this.showNextButton = false;
        this.showUpdateButton = true; // show the "Update" button in the last tab
      }
    },

    async updateMechanic() {
      const id = this.$route.params.id;
      try {
        const response = await apiMechanics.updateMechanicById(
        id,
          this.mechanic
        );
        this.$router.push("/Monteursbeheren");
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    getCountries() {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          this.countries = response.data.map((country) => country.name.common);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
