
<template>
  <ContractType />
</template>

<script>
import ContractType from "@/components/contracten/ContractType";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ContractType,
  },
  metaInfo: {
    title: "Welkom bij Flex vastgoed CRM | ContractType",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>