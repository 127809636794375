import {API} from "./api.base.js"
export default {

  async  createMechanic(data) {
    try {
      const response = await API.post('/api/rest/v2/mechanics', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getMechanics() {
    try {
      const response = await API.get('/api/rest/v2/mechanics');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteMechanic(id) {
    try {
      const response = await API.delete(`/api/rest/v2/mechanics/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateMechanic(role) {
    try {
      const response = await API.put(`/api/rest/v2/mechanics/${role.id}`, role);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async updateMechanicById(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/mechanics/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getMechanicById(id) {
    try {
      const response = await API.get(`/api/rest/v2/mechanics/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getExpertises() {
    try {
      const response = await API.get('/api/rest/v2/expertises');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
