import {API} from "./api.base.js"

export default {

  async  addSMSKeys(form) {
    try {
      const response = await API.post('/api/rest/v2/messagebird', form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getSMSKeys() {
    try {
      const response = await API.get('/api/rest/v2/messagebird');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteSMSKeys(id) {
    try {
      const response = await API.delete(`/api/rest/v2/messagebird/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateSMSKeys(id) {
    try {
      const response = await API.put(`/api/rest/v2/messagebird/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
 
};
