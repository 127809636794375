<template>
  <div>
    <v-card>
      <v-container>
        <div>
          <label>Onderwerp</label>
          <v-text-field v-model="items.subject" solo></v-text-field>
          <label>Categorie selecteren</label>
          <v-select
            v-model="items.categorie"
            :items="categories"
            id="name"
            solo
            item-text="name"
          ></v-select>

          <label>Zoekwoorden</label>
          <v-combobox
            label="Tags"
            v-model="items.keywords"
            multiple
            chips
            small-chips
            deletable-chips
            solo
          ></v-combobox>
        </div>
        <v-divider> </v-divider>
        <vue-editor id="editor1" v-model="items.content"> </vue-editor>

        <div class="text-center">
          <v-btn
          style="background-color: #147D81;
  color: white;margin-right:10px"
            @click="updateArticle()"
        
          >
            Bijwerken
          </v-btn>
          <v-btn style="color:white;background-color:#cb403d" @click="$router.push('/KnowledgeBase')">
            Afsluiten
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import apiKnowledgebase from "../api/apiKnowledgebase";
import apiKnowledgeCategorie from "../api/apiKnowledgeCategorie";
export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      items: {},
      categories: [],
    };
  },
  async mounted() {
    await apiKnowledgebase
      .getArticleById(this.$route.params.id)
      .then((data) => {
        this.items = data;
      });
    await apiKnowledgeCategorie.getKnowledgeCategories().then((data) => {
      this.categories = data;
    });
  },
  methods: {
    async updateArticle() {
      const id = this.$route.params.id;
      try {
        const response = await apiKnowledgebase.updateArticle(id, this.items);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
        this.$emit("article-updated", response);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}

#editor,
#editor1 {
  height: 350px;
  width: 150px;
}
</style>
