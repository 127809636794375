<template>
    <SingleTicket />
  </template>
  
  <script>
  import SingleTicket from "@/components/tickets/SingleTicket";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        SingleTicket,
    },
    metaInfo: {
      title: "Flex Vastgoed CRM | Meldingen ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  