<template>
  <SingleMechanic />
</template>

<script>
import SingleMechanic from "@/components/mechanic/SingleMechanic";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleMechanic,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Monteursprofiel ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>