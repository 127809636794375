<template>
  <div>
    <v-container>
      <v-col cols="12">
        <div>
          <AddProject />
        
          <div class="icon-container">
            <v-icon
              :color="showTable ? '#147D81' : undefined"
              @click="
                showTable = true;
                showCard = false;
              "
              >mdi-table</v-icon
            >

            <v-icon
              :color="showCard ? '#147D81' : undefined"
              @click="
                showTable = false;
                showCard = true;
              "
              >mdi-account-box-outline</v-icon
            >
          </div>
          
          <v-col cols="12">
            <div class="row mbot15 overview">
              <div class="col-md-12">
                <h4 class="no-margin">Projecten overzicht</h4>
              </div>
              <div class="col-md-2 col-xs-6 border-right">
                <h3 class="bold">{{ totalData.aantal }}</h3>
                <span class="text-dark">Totaal projecten</span>
              </div>
              <div class="col-md-2 col-xs-6 border-right">
                <h3 class="bold">
                  {{ totalActiveData.aantal }}
                </h3>
                <span class="text-success">Actieve projecten</span>
              </div>
              <div class="col-md-2 col-xs-6 border-right">
                <h3 class="bold">
                  {{ totalPendingData.aantal }}
                </h3>
                <span class="text-danger">In behandeling projecten</span>
              </div>
              <div class="col-md-2 col-xs-6 border-right">
                <h3 class="bold">{{ totalInDiscussionData.aantal }}</h3>
                <span class="text-info">In bespreking</span>
              </div>
              <div class="col-md-2 col-xs-6 border-right">
                <h3 class="bold">{{ totalCompletedData.aantal }}</h3>
                <span class="text-danger">Afgeronde projecten</span>
              </div>
            </div>
          </v-col>
          <ProjectTable v-if="showTable && !showCard" />
          <ProjectCard v-if="!showTable && showCard" />
        </div>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import AddProject from "../project/AddProject";
import ProjectTable from "../project/ProjectTable";
import ProjectCard from "../project/ProjectCard";
import apiProject from "../api/apiProject";

export default {
  components: {
    AddProject,
    ProjectTable,
    ProjectCard,
  },
  data: () => ({
    showTable: true,
    showCard: false,
    totalData: {},
    totalActiveData: {},
    totalCompletedData: {},
    totalPendingData: {},
    totalInDiscussionData: {},
  }),
  async mounted() {
    try {
      this.totalData = await apiProject.getTotal();
      this.totalActiveData = await apiProject.getTotalActive();
      this.totalCompletedData = await apiProject.getTotalCompleted();
      this.totalPendingData = await apiProject.getTotalPending();
      this.totalInDiscussionData = await apiProject.getTotalInDiscussion();
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  },
};
</script>

<style>
.icon-container {
  display: flex;
  justify-content: flex-end;
}
</style>
