<template>
  <v-container style="padding-top: 4rem">
    <form ref="form" @submit.prevent="addSMSKeys">
      <v-card elevation="2">
        <v-row>
          <v-col cols="3">
            <v-subheader style="font-weight:bold"
              >Api sleutel <span style="color:red">*</span></v-subheader
            >
          </v-col>
          <v-col cols="4">
            <v-text-field
              placeholder="Voeg Api Sleutel toe"
              solo
              v-model="form.apiKey"
              :disabled="!isEditing"
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <v-btn
              style="color:white;background-color:#0a7da0;"
              @click="isEditing = !isEditing"
              v-if="!isEditing"
            >
              Instellen
            </v-btn>

            <v-btn
              style="color:white;background-color:#0a7da0;"
              v-else-if="isEditing"
              @click="addSMSKeys()"
            >
              Opslaan
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-subheader style="font-weight:bold"
              >Telefoonnummer toevoegen
              <span style="color:red">*</span></v-subheader
            >
          </v-col>
          <v-col cols="4">
            <v-text-field
              placeholder="voorbeeld +3197010240934"
              solo
              v-model="form.PhoneNumber"
              :disabled="!isEditing"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </form>
  </v-container>
</template>

<script>
import apiSMS from "../api/apiSMS";
export default {
  data() {
    return {
      isEditing: false,
      form: {
        apiKey: "",
        PhoneNumber: "",
      },
    };
  },
  async mounted() {
    apiSMS.getSMSKeys().then((data) => {
      this.form = data;
    });
  },
  methods: {
    async addSMSKeys() {
      try {
        const response = await apiSMS.addSMSKeys(this.form);
        this.$refs.form.reset();
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.isEditing = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    async updateSMS(id) {
      try {
        const response = await apiSMS.updateSMSKeys(id, this.form);
        this.$refs.form.reset();
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 10px;
}
</style>
