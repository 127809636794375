<template>
  <v-container>
    <v-card>
      <v-card-text>
        <v-row align="center">
          <v-col cols="9">
            <v-text-field
              v-model="searchTerm"
              label="Zoeken"
              append-icon="mdi-magnify"
              @keyup.enter="search"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn @click="search">Zoeken</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-for="category in categoriesWithItems" :key="category.id">
      <v-card-title>{{ category.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="item in getFilteredItemsByCategory(category.name)" :key="item.id" cols="4">
            <v-card @click="goToItem(item)">
              <v-card-text>
                <div v-if="item.categorie === category.name">
                  <a :href="`/KnowledgeBase/${item.id}`" target="_blank">{{ item.subject }}</a>
                </div>
                <div v-else>
                  {{ item.subject }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import apiKnowledgeCategorie from "../api/apiKnowledgeCategorie";
import apiKnowledgebase from "../api/apiKnowledgebase";

export default {
  data() {
    return {
      searchTerm: "",
      categories: [],
      items: [],
    };
  },
  async mounted() {
    await apiKnowledgeCategorie.getKnowledgeCategories().then((data) => {
      this.categories = data;
    });
    this.loadKnowledgeItems();

    // Listen for the custom event
    this.$root.$on("knowledgeItemAdded", () => {
      this.loadKnowledgeItems();
    });
  },
  methods: {
    async loadKnowledgeItems() {
      apiKnowledgebase.getKnowledgeItems().then((data) => {
        this.items = data;
      });
    },
    search() {
      // Filter de items op basis van de zoekterm
      // Je kunt de logica hier toevoegen om de items te filteren op basis van de zoekterm
    },
    goToItem(item) {
      // Navigeer naar het geselecteerde item
      console.log('Navigeren naar item:', item);
    },
    getFilteredItemsByCategory(categoryName) {
      if (this.searchTerm === '') {
        return this.items.filter(item => item.categorie === categoryName);
      } else {
        const searchTermLower = this.searchTerm.toLowerCase();
        return this.items.filter(item => {
          return item.subject.toLowerCase().includes(searchTermLower) && item.categorie === categoryName;
        });
      }
    },
  },
  computed: {
    categoriesWithItems() {
      return this.categories.filter(category => this.getFilteredItemsByCategory(category.name).length > 0);
    },
  },
};
</script>
