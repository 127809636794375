<template>
  <v-container>
    <div>
      <v-tabs v-model="tab" background-color="#147D81" dark>
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.tab }}
        </v-tab>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              NAW gegevens
            </v-card-title>

            <v-card-text>
              <v-col cols="3">
                <v-select
                  :items="titels"
                  label="Titel"
                  v-model="employee.title"
                ></v-select>
              </v-col>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Voornaam"
                    v-model="employee.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Tussenvoegsel"
                    v-model="employee.insertion"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Achternaam"
                    v-model="employee.lastName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Telefoonnummer"
                    v-model="employee.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Email"
                    v-model="employee.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <span style="margin-right:5px">
        <v-btn
          style="background-color: #147D81;
  color: white;"
          @click="updateEmployee()"
          >Bijwerken</v-btn
        >
      </span>
      <v-btn
        style="color:white;background-color:#cb403d"
        @click="$emit('annuleer')"
      >
        Afsluiten
      </v-btn>
    </div>
  </v-container>
</template>

<script>

import apiUser from "../api/apiUser";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      employee: {},
    };
  },

  async mounted() {
    try {
      const employeedata = await apiUser.getSingleEmployee(this.id);
      this.employee = employeedata;
    } catch (error) {
      console.error(error);
    }
    this.getCountries();
  },

  methods: {
    async updateEmployee() {
      try {
        const response = await apiUser.updateSingleEmployee(
          this.id,
          this.employee
        );
        console.log(response);
        this.employee = response;
        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
        const data = await apiUser.getSingleEmployee(this.id);
        this.emplployee = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },
  },
};
</script>
