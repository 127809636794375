import {API} from "./api.base.js"

export default {

  async getSysteminfo() {
    try {
      const response = await API.get('/api/rest/v2/system/info');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  

 
};
