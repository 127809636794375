<template>
  <div>
    <v-container>
      <div v-if="items.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
      <v-card v-else>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
              Exporteren
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in exportOptions"
              :key="item.text"
              @click="exportData(item.type)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-card-title>
          Relatie
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          :hide-default-footer="true"
          class="custom-table-header"
          id="my-relations"
          cellClass="printable"
        >
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="green" @click="openRelation(item.id)">
              mdi-eye
            </v-icon>
            <v-icon small color="red" @click="deleteRelation(item.id)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiRelation from "../api/apiRelation";
export default {
  data: () => ({
    search: "",
    items: [],
    showAddRelationForm: false,
    relationsInfo: {
      totalRelations: [],
      totalActiveRelations: [],
      totalNonActiveRelations: [],
      activeContacts: 0,
      inactiveContacts: 0,
    },
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    headers: [
      {
        text: "Titel",
        value: "title",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Voornaam",
        value: "firstName",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Achternaam",
        value: "lastName",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
        class: "white-header",
      },

      {
        text: "E-mail",
        value: "email",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Status:",
        value: "status",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  created() {
    this.$on("relation-added", () => {
      apiRelation.getRelation().then((data) => {
        this.items = data;
      });
    });
  },
  async mounted() {
    apiRelation.getTotalRelations().then((data) => {
      this.relationsInfo.totalRelations = data;
    });
    apiRelation.getActiveRelations().then((data) => {
      this.relationsInfo.totalActiveRelations = data;
    });

    apiRelation.getNonActiveRelations().then((data) => {
      this.relationsInfo.totalNonActiveRelations = data;
    });
    apiRelation.getRelation().then((data) => {
      this.items = data;
    });
  },

  methods: {
    openRelation(id) {
      this.$router.push(`/relatie/${id}`);
    },
    async deleteRelation(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze relatie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiRelation.deleteRelation(id);
        const data = await apiRelation.getRelation();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De relatie is succesvol verwijderd",
          "success"
        );
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Relaties.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.items.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Relatiesoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-relations").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style>
.overview {
  margin-bottom: 15px;
}
.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>


