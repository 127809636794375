
<template>
  <KnowledgeBase />
</template>

<script>
import KnowledgeBase from "@/components/knowledgebase/KnowledgeBase";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    KnowledgeBase,
  },
  metaInfo: {
    title: "knowledgebase | Kennisitems overzicht",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>