import {API} from "./api.base.js"
export default {

  async  createIncident(data) {
    try {
      const response = await API.post('/api/rest/v2/incident', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getIncidents() {
    try {
      const response = await API.get('/api/rest/v2/incident');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getIncidentById(id) {
    try {
      const response = await API.get(`/api/rest/v2/incident/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteIncident(id) {
    try {
      const response = await API.delete(`/api/rest/v2/incident/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateIncident(id) {
    try {
      const response = await API.put(`/api/rest/v2/incident/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async addActionToIncident(id, data) {
    try {
      const response = await API.post(`/api/rest/v2/incident/action/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


 
};
