<template>
  <v-container>
    <div>
      <h4>Documenten in {{ folderName }}</h4>
    </div>
    <v-row>
      <v-col v-for="item in folderFiles" :key="item.name" cols="12" md="6" lg="4">
        <v-card class="folder-card" @click="handleClick(item)">
          <template v-if="item.isDirectory">
            <v-icon x-large color="yellow">mdi-folder</v-icon>
            <span class="folder-name">{{ item.name }}</span>
          </template>
          <template v-else>
            <img :src="imageSrc(item.loc)" alt="Item Afbeelding" height="150" width="350"/>
            <span class="folder-name">{{ item.name }}</span>
      

            <v-btn small @click.stop="handleDownloadDocument(item)" color="primary">Bekijken</v-btn>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiFolders from "../api/apiFolders";
export default {
  props: {
    folderName: String,
    folderFiles: Array,
  },
  data() {
    return {
      files: [],
    };
  },
  async mounted() {
    try {
      const files = await apiFolders.getDirectoriesFiles(this.folderName);
      this.files = files;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    imageSrc(loc) {
      return `http://localhost:3000/${loc}`;
    },
    handleClick(item) {
      if (item.isDirectory) {
        this.openSubFolder(item.name);
      } // Geen actie voor niet-directory items hier, om bubbling te voorkomen bij download.
    },
    openSubFolder(subFolderName) {
      this.$emit("openFolder", `${this.folderName}/${subFolderName}`);
    },
    handleDownloadDocument(item) {
  const fileUrl = `http://localhost:3000/${item.loc}`;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', item.name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}


  },
};
</script>

<style>
/* Stijlen voor de weergave van de bestanden */
.folder-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
}

.folder-name {
  margin-top: 10px;
  font-weight: bold;
}
</style>
