<template>
  <form ref="form" @submit.prevent="submitForm">
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        NAW gegevens
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card elevation="3">
          <v-card-title>
            NAW gegevens
          </v-card-title>

          <v-card-text>
            <v-col cols="2">
              <v-select
                :items="titels"
                label="Titel"
                v-model="form.title"
              ></v-select>
            </v-col>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Voornaam"
                  v-model="form.firstName"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Tussenvoegsel"
                  v-model="form.insertion"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Achternaam"
                  v-model="form.lastName"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Telefoonnummer"
                  v-model="form.phone"
                  :prefix="'+31'.toString()"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Email" v-model="form.email"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Geboortedatum"
                  v-model="form.birthday"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Geboorteplaats"
                  v-model="form.birthplace"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="3">
              <v-select
                :items="burgelijkestaten"
                label="Burgerlijke staat"
                v-model="form.maritalStatus"
              ></v-select>
            </v-col>
          </v-card-text>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="e6 = 2"
          >
            Volgende
          </v-btn>
          <v-btn @click="$emit('cancel')" text>
            Annuleer
          </v-btn>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Adresgegevens
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card elevation="3">
          <v-card-title>
            Adresgegevens
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-text-field label="Adres" v-model="form.adres"></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Huisnummer"
                  v-model="form.houseNumber"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Postcode"
                  v-model="form.postcode"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field label="Stad" v-model="form.city"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-select
                  v-model="form.country"
                  :items="countries"
                  label="Kies een land"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="e6 = 3"
          >
            Volgende
          </v-btn>
          <v-btn @click="e6 = 1" text>
            Vorige
          </v-btn>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Administratiegegevens
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card elevation="3">
          <v-card-title>
            Administratiegegevens
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-select
                  :items="identificationTypes"
                  label="Type identificatie"
                  v-model="form.identificationType"
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Identificatienummer"
                  v-model="form.identificationNumber"
                ></v-text-field>
                <v-alert v-if="isInvalidIdentificationNumber" type="error">
                  Ongeldig identificatienummer
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Identificatie einddatum"
                  v-model="form.identificationEndDate"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  label="Bankrekeningnummer"
                  v-model="form.bankNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <div>
            <v-btn
              style="background-color: #147D81;
  color: white;margin:2px"
              @click="submitForm"
              >Aanmaken</v-btn
            >

            <v-btn
              style="color:white;background-color:#cb403d;"
              @click="$emit('cancel')"
            >
              Afsluiten
            </v-btn>
          </div>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </form>
</template>

<script>
import axios from "axios";
import apiTenant from "../api/apiTenant";
export default {
  data() {
    return {
      e6: 1,
      form: {
        title: "",
        firstName: "",
        insertion: "",
        lastName: "",
        phone: "",
        email: "",
        birthday: "",
        birthplace: "",
        maritalStatus: "",
        //Tab 2
        adres: "",
        postcode: "",
        houseNumber: "",
        city: "",
        country: "",
        //Tab 3

        identificationType: "",
        identificationNumber: "",
        identificationEndDate: "",
        bankNumber: "",
      },
      identificationTypes: ["Paspoort", "Rijbewijs", "Identiteitskaart"],

      countries: [],
      selectedCountry: null,
      burgelijkestaten: [
        "Gehuwd",
        "Ongehuwd",
        "Geregistreerd partnerschap",
        "Gescheiden",
        "Weduwe/Weduwnaar",
      ],
      titels: ["Heer", "Mevrouw", "Zeg ik liever niet"],
      items: [
        { tab: "NAW gegevens", content: "Tab 1 Content" },
        { tab: "Adresgegevens", content: "Tab 2 Content" },
        { tab: "Administratiegegevens", content: "Tab 3 Content" },
      ],
    };
  },

  computed: {
    isInvalidIdentificationNumber() {
      if (
        this.identificationType === "Paspoort" &&
        this.identificationNumber.length !== 9
      ) {
        return true;
      }
      if (
        this.identificationType === "Rijbewijs" &&
        this.identificationNumber.length !== 10
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.getCountries();
  },

  methods: {
    async submitForm() {
      try {
      await apiTenant.createTenant(this.form);
        this.$router.push("/huurders");
        this.$refs.form.reset();
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.$emit("tenant-added");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    getCountries() {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          this.countries = response.data.map((country) => country.name.common);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
