
<template>
  <Objecten />
</template>

<script>
import Objecten from "@/components/realestate/Objecten";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Objecten,
  },
  metaInfo: {
    title: "Mijn woningen | FlexCRM",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>