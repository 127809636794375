import { API } from "./api.base.js";
export default {
  async createAsset(data) {
    try {
      const response = await API.post("/api/rest/v2/check/folders", data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getDirectories() {
    try {
      const response = await API.get("/api/rest/v2/check/folders");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getDirectoriesFiles(folderName) {
    try {
      const response = await API.get(
        `/api/rest/v2/check/folders/${folderName}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
