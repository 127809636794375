import {API} from "./api.base.js"
export default {
  async getCurrentUser() {
    try {
      const response = await API.get('/api/rest/v2/employee/current');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
      async  createEmployee(form) {
        try {
          const response = await API.post(`/api/rest/v2/employee`,form);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
      
      async  updateMyAccount(data) {
        try {
          const response = await API.put('/api/rest/v2/employee/update/myaccount', data);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },

      async  getSingleEmployee(id) {
        try {
          const response = await API.get(`/api/rest/v2/employee/single/employee/${id}`);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
      async  updateSingleEmployee(id,data) {
        try {
          const response = await API.put(`/api/rest/v2/employee/update/single/employee/${id}`,data);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },


      async getEmployees() {
        try {
          const response = await API.get('/api/rest/v2/employee');
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },

      async getActiveEmployee() {
        try {
          const response = await API.get('/api/rest/v2/employee/enable');
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
      async enableEmployee() {
        try {
          const response = await API.put('/api/rest/v2/employee/enable', {
            status: 'actief'
          });
          return response.data;
        } catch (error) {
          throw new Error(error);
        }
      },

      
      async disableEmployee() {
        try {
          const response = await API.put('/api/rest/v2/employee/disable', {
            status: 'niet actief'
          });
          return response.data;
        } catch (error) {
          throw new Error(error);
        }
      },

      async deleteEmployee(id) {
        try {
          const response = await API.delete(`/api/rest/v2/users/${id}`);
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
};
