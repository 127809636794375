<template>
  <v-container style="padding-top:2rem">
    <v-row>
      <v-col cols="4">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              style="background-color: #147D81;
  color: white"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Nieuwe categorisatie
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Nieuwe categorisatie
            </v-card-title>

            <v-card-text>
              Nieuwe categorieën kunnen toegevoegd worden.

              <v-radio-group v-model="categorieType">
                <v-radio label="Hoofdcategorie" value="hoofdcategorie" />
                <v-radio label="Subcategorie" value="subcategorie" />
              </v-radio-group>
              <v-text-field v-model="naam" label="Naam" outlined />

              <v-autocomplete
                v-if="categorieType === 'subcategorie'"
                :items="categorieen.flat()"
                label="Categorie selecteren"
                v-model="categorie"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="error" @click="dialog = false">
                Annuleren
              </v-btn>
              <v-btn
                style="background-color: #147D81;
  color: white"
                @click="saveCategory()"
              >
                Aanmaken
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div class="tree">
          <b> Categorieen en subcategorieen </b>
          <v-treeview hoverable activatable :items="categories"></v-treeview>
        </div>
      </v-col>

      <v-col cols="4" style="padding-top:5rem">
        <div>
          <v-text-field
            outlined
            :disabled="!isEditing"
            v-model="selectedSubcategoryName"
          ></v-text-field>
          <v-btn style="margin-right:10px" color="error"> verwijderen </v-btn>
          <v-btn
            style="background-color: #147D81;
  color: white"
            @click="updateText"
          >
            {{ buttonText }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiCategories from "../api/apiCategories";
export default {
  data: () => ({
    dialog: false,
    buttonText: "Aanpassen",
    selectedSubcategoryName: "",
    categorieType: "",
    categorie: "",
    categorieen: [],
    naam: "",
    categories: [],
    isEditing: false,
  }),
  async mounted() {
    this.loadCategories();
    apiCategories.getCategories().then((data) => {
      this.categorieen = data;
    });
  },

  computed: {
    isSubcategorie() {
      return this.categorieType === "subcategorie";
    },
  },
  watch: {
    categorieType() {
      this.naam = "";
    },
  },
  methods: {
    updateText() {
      // code voor het bijwerken van de tekst
      this.buttonText = "Updaten";
      this.isEditing = true;
    },
    updateSelectedSubcategory(selectedItems) {
      if (selectedItems.length > 0) {
        this.selectedSubcategoryName = selectedItems[0].name;
      }
    },

    async loadCategories() {
      try {
        const subcategories = await apiCategories.getsubCategories();
        const categories = await apiCategories.getCategories();
        const categoriesWithSubcategories = this.buildCategories(subcategories);

        categories.forEach((category) => {
          const existingCategory = categoriesWithSubcategories.find(
            (c) => c.id === category.id
          );
          if (!existingCategory) {
            categoriesWithSubcategories.push({
              id: category.id,
              name: category.name,
              children: [],
            });
          }
        });

        this.categories = categoriesWithSubcategories;
      } catch (error) {
        console.error(error);
      }
    },

    buildCategories(subcategories) {
      const categoriesWithSubcategoriesMap = new Map();
      subcategories.forEach((subcategory) => {
        const categoryId = subcategory.category.id;
        const subcategoryId = subcategory.id;
        const subcategoryName = subcategory.name;

        if (!categoriesWithSubcategoriesMap.has(categoryId)) {
          categoriesWithSubcategoriesMap.set(categoryId, {
            id: categoryId,
            name: subcategory.category.name,
            children: [],
          });
        }

        categoriesWithSubcategoriesMap.get(categoryId).children.push({
          id: subcategoryId,
          name: subcategoryName,
        });
      });

      const categoriesWithSubcategories = Array.from(
        categoriesWithSubcategoriesMap.values()
      );
      return categoriesWithSubcategories;
    },

    async saveCategory() {
      if (this.categorieType === "hoofdcategorie") {
        try {
          const data = {
            name: this.naam,
          };
          const response = await apiCategories.createCategorie(data);
          console.log(response);
          this.dialog = false;
          this.naam = "";
        } catch (error) {
          console.error(error);
        }
      } else if (this.categorieType === "subcategorie") {
        try {
          const data = {
            name: this.naam,
            category_id: this.categorie,
          };
          const response = await apiCategories.createSubategorie(data);
          console.log(response);
          this.dialog = false;
          this.naam = "";
        } catch (error) {
          console.error(error);
        }
      }
      this.loadCategories();
      apiCategories.getCategories().then((data) => {
        this.categorieen = data;
      });
    },
  },
};
</script>
<style scoped>
.tree {
  margin-top: 30px;
}
</style>
