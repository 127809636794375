import {API} from "./api.base.js"
export default {
  async createRole(role) {
    try {
      const response = await API.post('/api/rest/v2/roles', {
        name: role.name,
        permissions: role.permissions
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getRoles() {
    try {
      const response = await API.get('/api/rest/v2/roles');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteRole(id) {
    try {
      const response = await API.delete(`/api/rest/v2/roles/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateRole(role) {
    try {
      const response = await API.put(`/api/rest/v2/roles/${role.id}`, role);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
};
