import {API} from "./api.base.js";

export default {

  async  createHouse(data) {
    try {
      const response = await API.post('/api/rest/v2/house', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getHouse() {
    try {
      const response = await API.get('/api/rest/v2/house');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getHouseById(id) {
    try {
      const response = await API.get(`/api/rest/v2/house/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateHouse(form,id) {
    try {
      const response = await API.put(`/api/rest/v2/house/${id}`,form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getStatus() {
    try {
      const response = await API.get('/api/rest/v2/status');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteHouse(id) {
    try {
      const response = await API.delete(`/api/rest/v2/house/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },


 
};
