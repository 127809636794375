import {API} from "./api.base.js"
export default {

  async  createFacilitie(data) {
    try {
      const response = await API.post('/api/rest/v2/facilities', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getFacilities() {
    try {
      const response = await API.get('/api/rest/v2/facilities');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteFacilities(id) {
    try {
      const response = await API.delete(`/api/rest/v2/facilities/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateFacilities(id, data) {
    try {
      const response = await API.put(`/api/rest/v2/facilities/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
