<template>
  <v-container>
    <v-form @submit.prevent="updatecompanyInfo">
      <v-row>
        <v-col cols="12" sm="6">
          <v-col cols="12">
            <label class="typo__label">Logo toevoegen </label>
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: left"
            >
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :max-image="1"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="Sleep afbeeldingen"
                browseText="of klik om te selecteren"
                dropText="Zet uw bestanden hier neer"
                popupText="Dit is de standaard afbeelding"
              ></vue-upload-multiple-image>
            </div>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="company.companyName"
            label="Bedrijfsnaam"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="company.adres"
            label="Adres"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="company.houseNumber"
            label="Huisnummer"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="company.postcode"
            label="Postcode"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="company.city"
            label="Stad"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="company.kvkNumber"
            label="KVK-nummer"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="company.btwNumber"
            label="BTW-nummer"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import apiCompany from "../api/apiCompany";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  name: "Company",
  components: {
    VueUploadMultipleImage,
  },
  data: () => ({
    images: [],
    formDataImages: [],
    company: {},
  }),
  async mounted() {
    apiCompany.getCompany().then((data) => {
      this.company = data[0];
    });
  },

  methods: {
    async uploadLogoSuccess(upload, response) {
      const data = response.data[0];
      const formData = new FormData();
      formData.append("image", upload.file);
      await axios.post(
        `https://backend.flexvastgoedcrm.nl/api/company/${data.id}/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log(formData, index, fileList);
      this.$set(this.formDataImages, index, formData);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        this.$Swall.fire("error");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      //this.$set(this.formDataImages, index, formData);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>
