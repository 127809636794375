<template>
  <v-container>
    <form ref="form" @submit.prevent="submitForm">
      <v-stepper v-model="e13" vertical>
        <v-stepper-step step="1">
          Wonen
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card>
            <v-card-title> Wonen </v-card-title>

            <v-row>
              <v-col cols="4">
                <label> Woonoppervlakte(m²)</label>
                <input
                  class="form-control"
                  placeholder="Woon oppervlakte"
                  name="number_bedroom"
                  type="number"
                  v-model="form.square"
                />
              </v-col>
              <v-col cols="4">
                <label> Perceeloppervlakte(m²)</label>
                <input
                  class="form-control"
                  placeholder="perceel oppervlakte"
                  type="number"
                  v-model="form.plotArea"
                />
              </v-col>
              <v-col cols="4">
                <label> Aantal slaapkamers</label>
                <input
                  class="form-control"
                  placeholder="Aantal slaapkamers"
                  type="number"
                  id="number_bedroom"
                  v-model="form.bedrooms"
                />
              </v-col>
              <v-col cols="4">
                <label> Aantal badkamers</label>
                <input
                  class="form-control"
                  placeholder="Aantal badkamers"
                  type="number"
                  v-model="form.bathrooms"
                />
              </v-col>
              <v-col cols="4">
                <label> Aantal woonlagen</label>
                <input
                  class="form-control"
                  placeholder="Aantal woonlagen"
                  type="number"
                  v-model="form.stairs"
                />
              </v-col>
            </v-row>
            <div style="margin-top:5px">
              <v-btn color="primary" @click="e13 = 2">
                Volgende
              </v-btn>
              <v-btn color="error" @click="$emit('cancel')" text>
                Annuleer
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="2">
          Adresgegevens
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card>
            <v-row>
              <v-col cols="8">
                <label>Adres</label>
                <gmap-autocomplete
                  class="form-control"
                  placeholder="Adres toevoegen"
                  data-counter="120"
                  name="name"
                  type="text"
                  id="name"
                  @place_changed="Data"
                  required
                ></gmap-autocomplete>
              </v-col>
              <v-col cols="2">
                <label>Toevoeging</label>
                <input
                  class="form-control"
                  data-counter="120"
                  v-model="form.extraHousenumber"
                />
              </v-col>
              <div style="margin-bottom:1rem">
                <v-btn
                  color="primary"
                  @click="e13 = 3"
                  style="margin-top: 1rem"
                >
                  Volgende
                </v-btn>
                <v-btn color="error" @click="e13 = 1" text>
                  Vorige
                </v-btn>
              </div>
            </v-row>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="3">
          Financien
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card>
            <v-card-title> Financien </v-card-title>

            <v-row>
              <v-col cols="12" sm="6" md="3">
                <label> Vraagprijs</label>
                <v-text-field
                  outlined
                  label="€"
                  dense
                  v-model="form.price"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label> Selecteer</label>
                <v-select
          :items="items"
     outlined
          label="Selecteer"
        ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label> Vraagprijs per m²</label>
                <v-text-field
                  outlined
                  dense
                  label="€"
                  v-model="form.pricePerSquare"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label> WOZ waarde </label>
                <v-text-field
                  outlined
                  dense
                  label="€"
                  v-model="form.wozValue"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e13 = 4">
              Volgende
            </v-btn>
            <v-btn color="error" @click="e13 = 2" text>
              Vorige
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="4">
          Bouw
        </v-stepper-step>

        <v-stepper-content step="4">
          <v-card>
            <v-card-title> Bouw </v-card-title>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label> Soort appartement</label>
                <v-text-field
                  outlined
                  dense
                  v-model="form.appartmentType"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label> Soort bouw</label>
                <v-text-field outlined v-model="form.builtType"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label>Bouwjaar</label>
                <v-select
                  label="Bouwjaar"
                  dense
                  v-model="form.yearBuilt"
                  :items="years"
                  :menu-props="{ offsetY: true }"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label> Soort dak</label>
                <v-text-field outlined v-model="form.roofType"></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e13 = 5">
              Volgende
            </v-btn>
            <v-btn color="error" @click="e13 = 3" text>
              Vorige
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="5">
          Energie
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-card>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <label> Energielabel</label>
                <v-text-field
                  outlined
                  dense
                  v-model="form.energyLabel"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label> Isolatie</label>
                <v-text-field outlined v-model="form.isolation"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <label> Verwarming</label>
                <v-text-field outlined v-model="form.heating"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <label> Warm water</label>
                <v-text-field outlined v-model="form.hotWater"></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e13 = 6">
              Volgende
            </v-btn>
            <v-btn color="error" @click="e13 = 4" text>
              Vorige
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="6">
          KVK
        </v-stepper-step>

        <v-stepper-content step="6">
          <v-card>
            <v-card-title> VvE checklist</v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label>
                    <input type="checkbox" v-model="form.yearlyMeeting" />
                    Yearly Meeting
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>
                    <input
                      type="checkbox"
                      v-model="form.regularContributions"
                    />
                    Regular Contributions
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>
                    <input type="checkbox" v-model="form.reserveFund" />
                    Reserve
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>
                    <input type="checkbox" v-model="form.maintenancePlan" />
                    Onderhouds Plan
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <label>
                    <input type="checkbox" v-model="form.buildingInsurance" />
                    Gebouw verzekering
                  </label>
                </v-col>
              </v-row>
            </v-container>

            <v-btn color="primary" @click="e13 = 7">
              Volgende
            </v-btn>
            <v-btn color="error" @click="e13 = 5" text>
              Vorige
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="7">
          Project en Status
        </v-stepper-step>

        <v-stepper-content step="7">
          <v-card>
            <v-row>
              <v-col cols="6">
                <p class="sidebar-title"><span>Status</span></p>

                <v-autocomplete
                  :items="items"
                  label="Status"
                  v-model="form.status"
                  item-value="id"
                  item-text="name"
                ></v-autocomplete>
              </v-col>

              <v-col cols="6">
                <p class="sidebar-title"><span>Project</span></p>

                <v-autocomplete
                  :items="projects"
                  label="Project"
                  v-model="form.project"
                  item-value="id"
                  item-text="name"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <p class="sidebar-title"><span>Relatie</span></p>

            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  :items="relation"
                  label="Relatie"
                  v-model="form.relatie"
                  item-text="fullName"
                  item-value="fullName"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  v-model="form.facilitie"
                  :items="facilities"
                  label="Selecteer faciliteiten"
                  item-value="id"
                  item-text="name"
                  multiple
                  outlined
                  dense
                ></v-combobox>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e13 = 8">
              Volgende
            </v-btn>
            <v-btn color="error" @click="e13 = 6" text>
              Vorige
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-step step="8">
          Media & Omschrijving
        </v-stepper-step>

        <v-stepper-content step="8">
          <v-card>
            <v-row>
              <v-col cols="12">
                <label class="typo__label">Afbeelding toevoegen </label>
                <div
                  id="my-strictly-unique-vue-upload-multiple-image"
                  style="display: flex; justify-content: left"
                >
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :max-image="20"
                    :data-images="images"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                    dragText="Sleep afbeeldingen"
                    browseText="of klik om te selecteren"
                    dropText="Zet uw bestanden hier neer"
                    markIsPrimaryText="Instellen als standaard"
                    primaryText="standaard"
                    popupText="Dit is de standaard afbeelding"
                  ></vue-upload-multiple-image>
                </div>
              </v-col>

              <v-col cols="12">
                <label> Omschrijving *</label>
                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="Omschrijving"
                  data-counter="350"
                  name="description"
                  cols="50"
                  id="description"
                  v-model="form.description"
                ></textarea>
              </v-col>
            </v-row>
            <div style="margin-top:5px">
              <v-btn color="primary" @click="addObject()">
                Object toevoegen
              </v-btn>

              <v-btn color="error" @click="$emit('cancel')" text>
                Annuleer
              </v-btn>
            </div>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </form>
  </v-container>
</template>

<script>
import apiFacilities from "../api/apiFacilities";
import apiHouse from "../api/apiHouse";
import apiProject from "../api/apiProject";
import apiRelation from "../api/apiRelation";
import axios from "axios";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  components: {
    VueUploadMultipleImage,
  },
  data: () => ({
    e13: 1,
    items: ['Per maand', 'Per week', 'Per jaar', 'Per vierkante meter', 'Op aanvraag'],
    form: {
      apiFacilities: "",
      bedrooms: "",
      bathrooms: "",
      stairs: "",
      project: "",
      facilitie: "",
      square: "",
      plotArea: "",
      extraHousenumber: "",
      price: "",
      pricePerSquare: "",
      wozValue: "",
      appartmentType: "",
      builtType: "",
      yearBuilt: "",
      roofType: "",
      energyLabel: "",
      isolation: "",
      heating: "",
      hotWater: "",
      status: "",
      yearlyMeeting: false,
      regularContributions: false,
      reserveFund: false,
      maintenancePlan: false,
      buildingInsurance: false,
      description: "",
      relatie: "",
    },
    formDataImages: [],
    isVve: false,
    facilities: [],
    items: [],
    projects: [],
    images: [],
    relations: [],
    years: [],
  }),
  created() {
    const currentYear = new Date().getFullYear();
    for (let year = 1900; year <= currentYear; year++) {
      this.years.push(year);
    }
  },

  computed: {
    relation() {
      return this.relations.map((relation) => {
        return {
          ...relation,
          fullName: relation.firstName + " " + relation.lastName,
        };
      });
    },
  },
  async mounted() {
    await this.getStatussen();
    await this.getRelations();
    await this.getFacilities();
    apiProject.getProjects().then((data) => {
      this.projects = data;
    });
  },

  methods: {
    nextStep() {
      this.currentStep = "complete";
    },
    async getStatussen() {
      const status = await apiHouse.getStatus();
      this.items = status;
    },
    async getFacilities() {
      const facilities = await apiFacilities.getFacilities();
      this.facilities = facilities;
    },

    async getRelations() {
      const relations = await apiRelation.getRelation();
      this.relations = relations;
    },

    Data(AdresData) {
      const addressComponents = AdresData.address_components;
      let postalCode = "";
      for (let i = 0; i < addressComponents.length; i++) {
        const types = addressComponents[i].types;
        for (let j = 0; j < types.length; j++) {
          if (types[j] === "postal_code") {
            postalCode = addressComponents[i].long_name;
            break;
          }
        }
        if (postalCode !== "") {
          break;
        }
      }
      this.AdresData = {
        adres: AdresData.formatted_address,
        stad: AdresData.vicinity,
        long_coord: AdresData.geometry.location.lng(),
        lat_coord: AdresData.geometry.location.lat(),
        postal_code: postalCode,
      };
    },

    async addObject() {
      try {
        const payload = {
          ...this.form,
          adres: this.AdresData.adres,
          city: this.AdresData.vicinity,
          long_coord: this.AdresData.long_coord,
          lat_coord: this.AdresData.lat_coord,
        };
        const { data } = await axios.post(
          "https://backend.flexvastgoedcrm.nl/api/rest/v2/house",
          payload
        );
        await Promise.all(
          this.formDataImages.map(async (formData) => {
            await axios.post(
              `https://backend.flexvastgoedcrm.nl/api/rest/v2/house/${data.id}/image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          })
        );
        this.$swal.fire("Aangemaakt!", "", "success");
      } catch (err) {
        console.log(err);
        this.$swal.fire("Controleer of alle velden zijn ingevuld", "", "error");
      }
    },


    uploadImageSuccess(formData, index, fileList) {
      console.log(formData, index, fileList);
      this.$set(this.formDataImages, index, formData);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        this.$Swall.fire("error");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      //this.$set(this.formDataImages, index, formData);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>
