<template>
  <v-container style="padding-top:4rem">
    <v-row>
      <v-col cols="6" md="6">
        <div>
          <v-btn
            style="background-color: #147D81;
  color: white;margin-right:5px"
          >
            Systeem updaten
          </v-btn>
          <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" dark v-bind="attrs" v-on="on">
                IP restrictie toevoegen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-toolbar style="color:white">
                  IP Restrictie toevoegen
                </v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col sm="6" md="6">
                      <v-text-field label="Naam*" v-model="name"></v-text-field>
                    </v-col>
                    <v-col sm="6" md="6">
                      <v-text-field
                        label="IP*"
                        v-model="ip"
                        required
                      ></v-text-field>
                      <p v-if="!ip" class="error-message">Ip adres invoeren</p>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  style="color:white;background-color:#cb403d"
                  @click="dialog = false"
                >
                  Afsluiten
                </v-btn>
                <v-btn
                  style="color:white;background-color:#147D81"
                  @click="createIprestriction()"
                >
                  Aanmaken
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div style="padding-top:2rem">
          <v-data-table
            :headers="headers"
            :items="restrictions"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.iprange="{ item }">
              <span>{{ item.iprange ? "Gehasht" : "Gehasht" }}</span>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="6" md="6">
        <v-card>
          <v-card-title class="title">Server Informatie</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p"
                    >Hostname:</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    system.serverStatus.hostname
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p"
                    >Platform:</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    system.serverStatus.platform
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p"
                    >Architecture:</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    system.serverStatus.architecture
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p">Memory:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    system.serverStatus.memory
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p"
                    >Free Memory:</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    system.serverStatus.freememory
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p">Uptime:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    system.serverStatus.uptime
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p">Type:</v-list-item-title>
                  <v-list-item-subtitle>{{
                    system.serverStatus.type
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title-p"
                    >Release:</v-list-item-title
                  >
                  <v-list-item-subtitle>{{
                    system.serverStatus.release
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiIp from "../api/apiIp";
import apiSystem from "../api/apiSystem";
export default {
  data: () => ({
    headers: [
      { text: "ID", value: "id" },
      { text: "Naam", value: "name" },
      { text: "IP Range", value: "iprange" },
    ],

    model: false,
    dialog: false,
    name: "",
    ip: "",
    system: {},
    restrictions: [],
  }),
  async mounted() {
    apiSystem.getSysteminfo().then((data) => {
      this.system = data;
    });
    apiIp.getIprestrictions().then((data) => {
      this.restrictions = data;
    });
  },

  methods: {
    async createIprestriction() {
      try {
        const data = {
          name: this.name,
          iprange: this.ip,
        };
        const response = await apiIp.createIprestriction(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.dialog = false;
        this.name = "";
        this.ip = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style>
.title {
  justify-content: left;
  cursor: move;
  overflow: hidden;
  color: #1f64a0 !important;
  font-size: 14px;
  font-weight: bold;
  margin-left: 4px;
}

.title-p {
  font-size: 13px;
  margin-left: 4px;
  font-weight: bold;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
</style>
