
<template>
  <ProjectDetails />
</template>

<script>
import ProjectDetails from "@/components/project/ProjectDetails";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ProjectDetails,
  },
  metaInfo: {
    title: "Flex CRM | Project informatie",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>