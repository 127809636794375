<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <AddEmployeeForm
          v-if="showAddEmployeeForm"
          @cancel="showAddEmployeeForm = false"
        />

        <SingleEmployee
          v-if="showSingleEmployee"
          @annuleer="showSingleEmployee = false"
          :id="employeeId"
        />

        <v-col cols="12">
          <div style="margin-bottom:10px">
            <v-btn
              rounded
              class="btn"
              style="background-color: #147D81;
  color: white;"
              dark
              @click="showAddEmployeeForm = true"
              v-if="!(showAddEmployeeForm || showSingleEmployee)"
            >
              Medewerker aanmaken
            </v-btn>
          </div>
          <v-card v-if="!(showAddEmployeeForm || showSingleEmployee)">
            <v-card-title>
              Medewerkersbeheren
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="employees"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-slot:top> </template>

              <template
                v-for="item in employees"
                v-slot:[`item.status`]="{ item }"
              >
                <div :key="item.id">
                  <v-switch
                    v-model="item.toggle"
                    class="btn"
                    hide-details
                    :disabled="item.status !== 'actief'"
                    @change="updateUser(item)"
                  ></v-switch>
                </div>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon
                  small
                  color="green"
                  @click="openEmployeeProfile(item.id)"
                >
                  mdi-eye
                </v-icon>
                <v-icon small color="red" @click="deleteEmployee(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AddEmployeeForm from "../employees/AddEmployeeForm.vue";
import SingleEmployee from "../employees/SingleEmployee.vue";
import apiUser from "../api/apiUser";
export default {
  components: {
    AddEmployeeForm,
    SingleEmployee,
  },
  data: () => ({
    employees: [],
    showAddEmployeeForm: false,
    showSingleEmployee: false,
    search: "",
    model: false,

    headers: [
      {
        text: "Voornaam",
        value: "firstName",
        sortable: true,
      },

      {
        text: "Achternaam",
        value: "lastName",
        sortable: true,
      },
      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
      },

      {
        text: "Email",
        value: "email",
        sortable: true,
      },

      
      {
        text: "Status",
        value: "status",
        sortable: true,
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    try {
      const data = await apiUser.getEmployees();
      this.employees = data.map((employee) => ({
        ...employee,
        toggle: employee.status === "actief",
      }));
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    openEmployeeProfile(employeeId) {
      this.showSingleEmployee = true;
      this.employeeId = employeeId;
    },
    async updateUser(item) {
      if (this.toggle == false) {
        try {
          const data = await apiUser.enableEmployee(item.id);
          this.employee.status = data.status;
        } catch (error) {
          console.error(error);
        }
      } else if (this.toggle == true) {
        try {
          const data = await apiUser.disableEmployee();
          this.employee.status = data.status;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async deleteEmployee(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze medewerker wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiUser.deleteEmployee(id);
        const data = await apiUser.getEmployees();
        this.employees = data;
        this.$swal.fire(
          "Verwijderd",
          "De Medewerker is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
