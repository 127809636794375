<template>
  <v-container style="padding-top: 2rem">
    <div style="padding: 1rem"></div>

    <div class="row">
      <!-- administratie -->
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div>
            <div class="link-box">
              <h2>{{ totalNotPaid.aantal }}</h2>
              <p class="link-box-title">Aantal Openstaande Reparaties</p>
              <p class="link-box-subtitle">
                Dit rapport toont het aantal facturen die nog niet zijn betaald.
              </p>
              <v-chip class="ma-2" color="success" outlined>
                Facturatie
              </v-chip>

              <v-btn
                text
                style="background-color: #147D81;
  color: white"
              >
                Download rapport
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalPaid.aantal }}</h2>
            <p class="link-box-title">Aantal afgeronde reparaties</p>
            <p class="link-box-subtitle">
              Dit rapport toont het aantal facturen die betaald zijn.
            </p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ verrekend.aantal }}</h2>
            <p class="link-box-title">Aantal verrekend facturen</p>
            <p class="link-box-subtitle">
              Dit rapport toont het aantal facturen die zijn verrekend.
            </p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Euro {{ totalDayTurnovers.dayTurnover }}</h2>
            <p class="link-box-title">Dag omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de dag omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Euro {{ totalWeekTurnovers.weekTurnover }}</h2>
            <p class="link-box-title">Week omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de week omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>Euro {{ getMonthTurnOvers.monthTurnover }}</h2>
            <p class="link-box-title">Maand omzet</p>
            <p class="link-box-subtitle">Dit rapport toont de maand omzet.</p>
            <v-chip class="ma-2" color="success" outlined> Facturatie </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalOrders.aantal }}</h2>
            <p class="link-box-title">Aantal orders</p>
            <p class="link-box-subtitle">Dit rapport toont de aantal orders.</p>
            <v-chip class="ma-2" color="deep-purple accent-4" outlined>
              Orders
            </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totalSend.aantal }}</h2>
            <p class="link-box-title">Aantal verzonden orders</p>
            <p class="link-box-subtitle">
              Dit rapport toont de aantal verzonden orders.
            </p>
            <v-chip class="ma-2" color="deep-purple accent-4" outlined>
              Orders
            </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 mb-4">
        <div>
          <div class="link-box">
            <h2>{{ totatNotSend.aantal }}</h2>
            <p class="link-box-title">Aantal niet verzonden orders</p>
            <p class="link-box-subtitle">
              Dit rapport toont de aantal niet verzonden orders.
            </p>
            <v-chip class="ma-2" color="deep-purple accent-4" outlined>
              Orders
            </v-chip>
            <v-btn
              text
              style="background-color: #147D81;
  color: white"
            >
              Download rapport
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
// import apiReport from "../api/apiReport";

export default {
  data() {
    return {
      totalNotPaid: [],
      totalPaid: [],
      verrekend: [],
      totalOrders: [],
      totalSend: [],
      totatNotSend: [],
      totalDayTurnovers: [],
      totalWeekTurnovers: [],
      getMonthTurnOvers: [],
    };
  },

  mounted() {
    apiReport.totalNotPaid().then((data) => {
      this.totalNotPaid = data;
    });
    apiReport.totalPaid().then((data) => {
      this.totalPaid = data;
    });
    apiReport.verrekend().then((data) => {
      this.verrekend = data;
    });

    apiReport.totalOrders().then((data) => {
      this.totalOrders = data;
    });
    apiReport.totalSend().then((data) => {
      this.totalSend = data;
    });
    apiReport.totatNotSend().then((data) => {
      this.totatNotSend = data;
    });
    apiReport.totalDayTurnovers().then((data) => {
      this.totalDayTurnovers = data;
    });
    apiReport.totalWeekTurnovers().then((data) => {
      this.totalWeekTurnovers = data;
    });
    apiReport.getMonthTurnOvers().then((data) => {
      this.getMonthTurnOvers = data;
    });
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  border-top: 2px solid #90caf9;

  /* min-height:100%; */
}
.link-box p {
  margin: 0px 10px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: black;
  font-size: 16px;
}
.link-box-subtitle {
  color: rgb(63, 63, 63);
  font-size: 14px;
  margin-bottom: 10px;
}
h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
