<template>
  <v-container class="container">
    <div>
      <v-row>
        <v-col cols="4">
          <h5>Nieuwe rechten-groep aanmaken</h5>
          <form @submit.prevent="createRole">
            <div>
              <v-text-field
                v-model="roleName"
                label="Rechtengroep naam"
                required
              ></v-text-field>
            </div>
            <div>
              <label>Onderdelen:</label>
              <v-treeview
                :items="permissions"
                v-model="selectedPermissions"
                selectable
                item-key="id"
                :open-all="false"
                activatable
                open-on-click
                :show-checkboxes="true"
              />
            </div>
            <v-btn
              rounded
              depressed
              style="background-color: #147D81;
  color: white;"
              type="submit"
            >
              Rechtengroep aanmaken
            </v-btn>
          </form>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-card-title>
              Rechtengroepen
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="roles" :search="search">
              <template v-slot:item.permissions="{ item }">
                <v-chip
                  v-for="(permission, index) in item.permissions"
                  :key="index"
                  label
                  >{{ permission }}</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn @click="editRole(item)">Bewerken</v-btn>
                <v-btn @click="deleteRole(item)">Verwijderen</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiRole from "../api/apiRole";
export default {
  data() {
    return {
      roleName: "",
      selectedPermissions: [],
      permissions: [
        {
          id: 1,
          name: "Woningen",
          children: [
            { id: 2, name: "Lezen" },
            { id: 3, name: "Aanmaken" },
            { id: 4, name: "Wijzigen" },
            { id: 5, name: "Verwijderen" },
          ],
        },
        {
          id: 6,
          name: "Consult",
          children: [
            { id: 7, name: "Lezen" },
            { id: 8, name: "Aanmaken" },
            { id: 9, name: "Wijzigen" },
            { id: 10, name: "Verwijderen" },
          ],
        },
        {
          id: 11,
          name: "Betalingen",
          children: [
            { id: 12, name: "Lezen" },
            { id: 13, name: "Aanmaken" },
            { id: 14, name: "Wijzigen" },
            { id: 15, name: "Verwijderen" },
          ],
        },
        {
          id: 16,
          name: "Relaties",
          children: [
            { id: 17, name: "Lezen" },
            { id: 18, name: "Aanmaken" },
            { id: 19, name: "Wijzigen" },
            { id: 20, name: "Verwijderen" },
          ],
        },
        {
          id: 21,
          name: "Projecten",
          children: [
            { id: 22, name: "Lezen" },
            { id: 23, name: "Aanmaken" },
            { id: 24, name: "Wijzigen" },
            { id: 25, name: "Verwijderen" },
          ],
        },
        {
          id: 26,
          name: "Modules",
          children: [
            { id: 27, name: "Lezen" },
            { id: 28, name: "Aanmaken" },
            { id: 29, name: "Wijzigen" },
            { id: 30, name: "Verwijderen" },
          ],
        },
        {
          id: 31,
          name: "Instellingen",
          children: [
            { id: 32, name: "Lezen" },
            { id: 33, name: "Aanmaken" },
            { id: 34, name: "Wijzigen" },
            { id: 35, name: "Verwijderen" },
          ],
        },
      ],
      roles: [
        {
          id: 1,
          name: "Admin",
          permissions: [
            "Dashboard.Lezen",
            "Dashboard.Wijzigen",
            "Users.Aanmaken",
            "Roles.Wijzigen",
          ],
        },
        {
          id: 2,
          name: "Manager",
          permissions: ["Dashboard.Lezen", "Users.Lezen", "Users.Wijzigen"],
        },
        { id: 3, name: "User", permissions: ["Dashboard.Lezen"] },
      ],
      search: "",
      headers: [
        { text: "Naam", value: "name" },
        { text: "Onderdelen", value: "permissions" },
        { text: "Acties", value: "", sortable: false },
      ],
    };
  },
  methods: {
    async createRole() {
      try {
        const newRole = {
          name: this.roleName,
          permissions: [],
        };
        for (const p of this.selectedPermissions) {
          const parent = this.permissions.find((pp) => pp.id === p.parentId);
          if (!parent) {
            console.error(`Invalid parentId: ${p.parentId}`);
            continue;
          }
          const child = parent.children.find((c) => c.id === p.id);
          if (!child) {
            console.error(`Invalid childId: ${p.id}`);
            continue;
          }
          newRole.permissions.push(`${parent.name}.${child.name}`);
        }
        const response = await apiRole.createRole(newRole);
        this.roles.push(response);
        this.roleName = "";
        this.selectedPermissions = [];
      } catch (error) {
        console.error(error);
      }
    },

    async editRole(role) {
      this.roleName = role.name;
      this.selectedPermissions = this.permissions.filter((p) =>
        role.permissions.includes(`${p.parentName}.${p.name}`)
      );
    },
    async deleteRole(role) {
      try {
        await apiRole.deleteRole(role.id);
        const index = this.roles.indexOf(role);
        if (index > -1) {
          this.roles.splice(index, 1);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  padding-top: 4rem;
}

.tree-container,
.roles-container {
  flex: 1;
}
</style>
