import {API} from "./api.base.js"

export default {

  async getLogs() {
    try {
      const response = await API.get('/api/rest/v2/logger');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteLogs() {
    try {
      const response = await API.delete(`/api/rest/v2/logger`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
 
};
