import {API} from "./api.base.js"

export default {

  async  createProject(data) {
    try {
      const response = await API.post('/api/rest/v2/project', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getProjects() {
    try {
      const response = await API.get('/api/rest/v2/project');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteProject(id) {
    try {
      const response = await API.delete(`/api/rest/v2/project/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateProject(id, form) {
    try {
      const response = await API.put(`/api/rest/v2/project/${id}`,form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getProjectById(id) {
    try {
      const response = await API.get(`/api/rest/v2/project/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  //Data
  async getTotal() {
    try {
      const response = await API.get('/api/rest/v2/project/total');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalActive() {
    try {
      const response = await API.get('/api/rest/v2/project/total/active');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalCompleted() {
    try {
      const response = await API.get('/api/rest/v2/project/total/completed');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalPending() {
    try {
      const response = await API.get('/api/rest/v2/project/total/pending');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalInDiscussion() {
    try {
      const response = await API.get('/api/rest/v2/project/total/InDiscussion');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
