<template>
  <Employee />
</template>

<script>
import Employee from "@/components/employees/Employee";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Employee,
  },
  metaInfo: {
    title: "Flex CRM | Gebruikersbeheren ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>