<template>
    <AddTicket />
  </template>
  
  <script>
  import AddTicket from "@/components/tickets/AddTicket";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        AddTicket,
    },
    metaInfo: {
      title: "Flex Vastgoed CRM | Meldingen aanmaken ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  