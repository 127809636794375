<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-col>
          <router-link to="/ReparatieMelden">
            <v-btn
              rounded
              style="background-color: #147D81;
  color: white"
              dark
            >
              Reparatie aanmaken
            </v-btn>
          </router-link>
        </v-col>

        <v-col cols="12">
          <div class="row mbot15 overview">
            <div class="col-md-12">
              <h4 class="no-margin">Reparatie overzicht</h4>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ totalRepairs.aantal }}</h3>
              <span class="text-dark">Totale reparaties</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">
                {{ totalActive.aantal }}
              </h3>
              <span class="text-success">Actieve reparaties</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">
                {{ totalNotActive.aantal }}
              </h3>
              <span class="text-danger">Afgeronde reparaties</span>
            </div>
          </div>
          <div v-if="items.length === 0">
            <img src="@/assets/nodata.jpg" height="550" width="650" />
          </div>

          <v-card v-else>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
                  Exporteren
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.text"
                  @click="exportData(item.type)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-title>
              Reparaties
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              id="my-relations"
              cellClass="printable"
            >
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon small color="green" @click="openRepair(item.id)">
                  mdi-eye
                </v-icon>
                <v-icon small color="red" @click="deleteRepair(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import "jspdf-autotable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiRepair from "../api/apiRepair";
export default {
  data: () => ({
    totalRepairs: [],
    totalActive: [],
    totalNotActive: [],
    search: "",
    items: [],
    relationsInfo: {
      totalRelations: [],
      totalActiveRelations: [],
      totalNonActiveRelations: [],
      activeContacts: 0,
      inactiveContacts: 0,
    },

    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    headers: [
      {
        text: "Titel",

        value: "title",
        sortable: true,
      },

      {
        text: "Woning",
        value: "house.adres",
        sortable: true,
      },
      {
        text: "Uitvoerdatum",
        value: "excutionDate",
        sortable: true,
      },
      {
        text: "Aangemaakt door",
        value: "createdBy",
        sortable: true,
      },

      {
        text: "status",
        value: "status",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    apiRepair.getActiveStatus().then((data) => {
      this.totalActive = data;
    });
    apiRepair.getNotActiveStatus().then((data) => {
      this.totalNotActive = data;
    });

    apiRepair.getRepairs().then((data) => {
      this.items = data;
    });
    apiRepair.getTotalRepairs().then((data) => {
      this.totalRepairs = data;
    });
  },
  methods: {
    openRepair(id) {
      this.$router.push(`/SingleReparatie/${id}`);
    },
    async deleteRepair(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze reparatie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiRepair.deleteRepair(id);
        this.items = await apiRepair.getRepairs();
        this.$swal.fire(
          "Verwijderd",
          "De reparatie is succesvol verwijderd",
          "success"
        );
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "relatiesoverzicht.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.filteredItems.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("relatiesoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-relations").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style>
.overview {
  margin-bottom: 15px;
}
</style>
