
<template>
  <Keys />
</template>

<script>
import Keys from "@/components/realestateHelper/Keys";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Keys,
  },
  metaInfo: {
    title: "Sleutelbeheer | Flex CRM",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>