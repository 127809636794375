<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-col cols="12">
          <v-btn
            rounded
            style="background-color: #147D81;
  color: white;"
            @click="deleteAllLogs()"
          >
            Logs verwijderen
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-card>
            <v-card-title>
              Activiteiten Logs
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
            >
              <template v-slot:item.level="{ item }">
                <div :class="getLogLevelClass(item.level)">
                  {{ item.level }}
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiLogs from "../api/apiLogs";
export default {
  data: () => ({
    search: "",
    model: false,
    dialogDelete: false,
    currentGame: [],
    items: [],
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: true,
      },

      {
        text: "Level",
        value: "level",
        sortable: true,
      },
      {
        text: "Gebruiker",
        value: "displayName",
        sortable: true,
      },

      {
        text: "Activiteit",
        value: "message",
        sortable: true,
      },
      {
        text: "ipadres",
        value: "iP",
        sortable: true,
      },
      {
        text: "Aangemaakt op",
        value: "createDate",
        sortable: true,
      },

      {
        text: "Gewijzigd op",
        value: "updateDate",
        sortable: true,
      },
    ],
  }),

  methods: {
    getLogLevelClass(level) {
      if (level === "warning") {
        return "text-warning";
      } else if (level === "info") {
        return "text-#147D81";
      } else if (level === "error") {
        return "text-danger";
      }
      // default color
      return "text-muted";
    },
    async deleteAllLogs() {
      try {
        const result = await this.$swal.fire({
          title: "Bent u zeker dat u alle logs wil verwijderen?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ja, alles verwijderen",
          cancelButtonText: "Sluiten",
        });

        if (result.isConfirmed) {
          const response = await apiLogs.deleteLogs();
          console.log(response);
          this.$swal.fire("Verwijderd", `${response.message}`, "success");
          apiLogs.getLogs().then((data) => {
            this.items = data;
          });
        } else {
          // If the user cancels the deletion, show a message using SweetAlert2
          this.$swal.fire(
            "Geannuleerd",
            "Er zijn geen logs verwijderd",
            "info"
          );
        }
      } catch (error) {
        console.error(error);
        this.$swal.fire("Error", `${error.message}`, "error");
      }
    },
  },
  async mounted() {
    apiLogs.getLogs().then((data) => {
      this.items = data;
    });
  },
};
</script>
