<template>
  <v-container>
    <div class="spacing">
      <v-btn
        rounded
        style="background-color: #147D81;
  color: white"
        @click="generateSubmissionLink()"
      >
        Upload link aanmaken
      </v-btn>
    </div>
    <div>
      <form ref="form" @submit.prevent="submitForm">
        <v-tabs v-model="tab" background-color="#147D81" dark>
          <v-tab v-for="(item, i) in items" :key="i">
            {{ item.tab }}
          </v-tab>
          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                Opdracht
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Opdracht"
                      v-model="repair.title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-if="repairlink && repairlink.length > 0"
                      label="Link naar reparatiebewijs"
                      v-model="repairlink"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-icon
                      v-if="repairlink && repairlink.length > 0"
                      class="copy-icon"
                      @click="copyLink"
                    >
                      mdi-content-copy
                    </v-icon>
                    <!-- Snackbar for copy confirmation -->
                    <v-snackbar
                      v-model="showSnackbar"
                      :timeout="snackbarTimeout"
                      multi-line
                      vertical
                    >
                      {{ snackbarText }}
                    </v-snackbar>
                  </v-col>
                </v-row>
                <div>
                  <v-textarea
                    label="Opdracht omschrijving"
                    v-model="repair.description"
                  ></v-textarea>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                Adresgegevens
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      label="Adres"
                      disabled
                      v-model="adres"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-tooltip v-model="show" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="grey lighten-1">
                            mdi-information-slab-circle-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Het adres kan niet gewijzigd worden.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card elevation="3">
              <v-card-title>
                Administratiegegevens
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-autocomplete
                      :items="statussen.flat()"
                      label="Selecteer een status"
                      v-model="repair.status"
                      item-text="name"
                      item-value="name"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      disabled
                      prefix="Uitvoerdatum"
                      v-if="!edit"
                      v-model="repair.excutionDate"
                    >
                    </v-text-field>

                    

                    <v-dialog v-if="edit" v-model="dialog" max-width="290">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="selectedDate"
                          label="Kies een datum"
                          prepend-icon="event"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-if="!timeSelected"
                        v-model="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="selectTime"
                          >Volgende</v-btn
                        >
                      </v-date-picker>
                      <v-time-picker v-else v-model="time" full-width>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="saveDateTime"
                          >OK</v-btn
                        >
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="2">
                    <v-icon small color="green" @click="toggleEdit()">
                      mdi-pencil
                    </v-icon>
                  </v-col>
                </v-row>

                <v-row>
                
                    <v-col cols="4">
                    <v-text-field
                      label="Toegewezen aan:"
                      disabled
                
                    ></v-text-field>
               
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Verstuurd per:"
                      disabled
                    
                    ></v-text-field>
               
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="8">
                    <v-textarea
                      name="input-7-1"
                      label="Notitie"
                      v-model="repair.notes"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row> </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <span style="margin-right:5px">
          <v-btn
            style="background-color: #147D81;
  color: white"
            v-if="showNextButton"
            @click="nextTab"
            >Volgende</v-btn
          >
          <v-btn
            style="background-color: #147D81;
  color: white"
            v-if="showUpdateButton"
            @click="updateRepair(repair.id)"
            >Update</v-btn
          >
        </span>
        <v-btn
          style="color:white;background-color:#cb403d;"
          @click="$router.push('/Reparaties')"
        >
          Afsluiten
        </v-btn>
      </form>
    </div>
  </v-container>
</template>

<script>
import ClipboardJS from "clipboard";
import apiRepair from "../api/apiRepair";
export default {
  data() {
    return {
      repairlink: "",
      showInfo: false,
      edit: false,
      dialog: false,
      adres: "",
      date: new Date().toISOString().substr(0, 10),
      time: new Date().toISOString().substr(11, 5),
      timeSelected: false,
      repair: {},
      showSnackbar: false,
      snackbarText: "",
      snackbarTimeout: 3000,
      clipboard: null,
      statussen: [],
      tab: null,
      show: false,
      link: { submissionLink: "" },
      items: [
        { tab: "Opdracht gegevens", content: "Tab 1 Content" },
        { tab: "Adresgegevens", content: "Tab 2 Content" },
        { tab: "Administratiegegevens", content: "Tab 3 Content" },
      ],
      tabIndex: 0,
      showNextButton: true,
      showUpdateButton: false,
    };
  },
  computed: {
    selectedDate() {
      return `${this.date}`;
    },
  },
  async mounted() {
await this.getData();
    apiRepair.getRepairStatus().then((data) => {
      this.statussen = data;
      console.log("Statussen geladen:", this.statussen);
    });
  },

  methods: {
    toggleEdit() {
      try {
        this.edit = !this.edit;
        this.dialog = true;
      } catch (error) {
        console.error("Error toggling edit mode:", error);
      }
    },

    selectTime() {
      this.timeSelected = true;
    },
    saveDateTime() {
      this.dialog = false;
    },
    async generateSubmissionLink() {
      await apiRepair
        .generateSubmissionLink(this.$route.params.id)
        .then((data) => {
          this.link = data;
        });
        this.getData();
    },


    async getData(){
      apiRepair.getRepairbyId(this.$route.params.id).then((data) => {
      this.repair = data;
      this.repairlink = data.repairLinks[0]?.submissionLink;
      this.adres = data.property?.adres;
      console.log("Repair data geladen:", this.repair);
    });
    },

    copyLink() {
      const textField = document.createElement("textarea");
      textField.innerText = this.repairlink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      document.body.removeChild(textField);

      this.snackbarText = "Link copied to clipboard";
      this.showSnackbar = true;
    },

    nextTab() {
      if (this.tab < this.items.length - 1) {
        this.tab++;
      } else {
        this.showNextButton = false;
        this.showUpdateButton = true; // show the "Update" button in the last tab
      }
    },

    async updateRepair() {
      const id = this.$route.params.id;
      try {
        this.repair.excutionDate = `${this.date} ${this.time}`;
        const response = await apiRepair.updateRepairbyId(id, this.repair);
        this.$router.push("/Reparaties");
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
<style>
.copyable-text-field {
  cursor: pointer;
  background-color: #f5f5f5;
}

.copy-icon {
  cursor: pointer;
  color: #999;
}
.spacing {
  padding-bottom: 1rem;
}
</style>
