<template>
  <RepairsReport />
</template>

<script>
import RepairsReport from "@/components/repair/RepairsReport";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    RepairsReport,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Reparatie rapport ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
