<template>
  <v-container>
    <v-card max-width="600">
      <v-row style="justify-content: center;">
        <v-col cols="5" md="5">
          <v-select
            v-model="employee.language"
            :items="languages"
            item-text="name"
            item-value="name"
            label="Taal selecteren"
            outlined
          >
            <template v-slot:selection="{ item }">
              <img
                :src="item.flag"
                height="16"
                width="16"
                alt="language.flag"
              />
              {{ item.name }}
            </template>
            <template v-slot:item="{ item }">
              <img
                :src="item.flag"
                height="16"
                width="16"
                alt="language.flag"
              />
              {{ item.name }}
            </template>
          </v-select>
        </v-col>
        <v-col cols="5" md="5">
          <v-select
            :items="modus"
            v-model="employee.mode"
            label="Selecteer thema"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12">
          <v-btn
          style="background-color: #147D81;
  color: white"
            dark
            block
            elevation="2"
            @click="updateMyAccount(employee)"
          >
            Gegevens bijwerken
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>



<script>
import apiUser from "../api/apiUser";
export default {
  data() {
    return {
      active: true,
      modus: ["Light", "Dark"],
      activeElement: "Pending",
      search: "",
      employee: {},
      language: "",
      languages: [
        { name: "Pools", flag: require("@/assets/LanguageImages/poland.png") },
        {
          name: "Engels",
          flag: require("@/assets/LanguageImages/unitedkingdom.png"),
        },
        {
          name: "Nederlands",
          flag: require("@/assets/LanguageImages/dutch.png"),
        },
      ],
    };
  },


  async mounted() {
     
    try {
      const data = await apiUser.getCurrentUser();
      this.employee = data;
    } catch (error) {
      console.error(error);
    }
      await this.darkMode();
  },
  methods: {
async darkMode() {
  if (this.employee.mode === "Dark") {
    console.log("dit is employee" + this.employee.mode);
    this.$vuetify.theme.dark = true;
  } else {
    this.$vuetify.theme.dark = false;
  }
},
   async updateMyAccount(employee) {
  try {
    const response = await apiUser.updateMyAccount(employee);
    console.log(response);
    this.employee = response; // Update employee object with new values
   
    this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
  } catch (error) {
    console.error(error);
     this.$swal.fire("Foutmelding!", "Het toevoegen is niet gelukt!", "error");
  }
},
  
  },
};
</script>
