import {API} from "./api.base.js"
export default {

  async  addMailServer(data) {
    try {
      const response = await API.post('/api/rest/v2/mailcontroller', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async  getBasicAuth() {
    try {
      const response = await API.get('/api/rest/v2/mailcontroller/basicauth');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async  getAuth() {
    try {
      const response = await API.get('/api/rest/v2/mailcontroller/auth', );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
 
};
