import {API} from "./api.base.js"
export default {
  async createTenant(form) {
    try {
      const response = await API.post(`/api/rest/v2/tenant`,form);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTenants() {
    try {
      const response = await API.get('/api/rest/v2/tenant');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  

  async getTenantForIncident() {
    try {
      const response = await API.get('/api/rest/v2/tenant/incident');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTenantbyId(id) {
    try {
      const response = await API.get(`/api/rest/v2/tenant/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteTenant(id) {
    try {
      const response = await API.delete(`/api/rest/v2/tenant/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteNote( noteId, tenantId) {
    try {
      const response = await API.delete(`/api/rest/v2/tenant/${noteId}/note/${tenantId}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateTenant(id,data) {
    try {
      const response = await API.put(`/api/rest/v2/tenant/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  
  },

  async createNote(id,data) {
    try {
      const response = await API.post(`/api/rest/v2/tenant/notes/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getNotesByTenantId(id, data) {
    try {
      const response = await API.get(`/api/rest/v2/tenant/notes/${id}`,data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getTotalTenants() {
    try {
      const response = await API.get('/api/rest/v2/tenant/count/all');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getActiveTenants() {
    try {
      const response = await API.get('/api/rest/v2/tenant/count/actief');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getNonActiveTenants() {
    try {
      const response = await API.get('/api/rest/v2/tenant/count/nietactief');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
