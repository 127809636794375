
<template>
  <Logs />
</template>

<script>
import Logs from "@/components/instellingen/Logs";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Logs,
  },
  metaInfo: {
 title: "FLEX VASTGOED CRM | Systeem logs",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>