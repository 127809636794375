
<template>
  <ViewEditArticle />
</template>

<script>
import ViewEditArticle from "@/components/knowledgebase/ViewEditArticle";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ViewEditArticle,
  },
  metaInfo: {
    title: "Bekijk en wijzig kennisitem | Kennisitems overzicht",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>