
<template>
    <SingleContract />
  </template>
  
  <script>
  import SingleContract from "@/components/contracten/SingleContract";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        SingleContract,
    },
    metaInfo: {
      title: "Welkom bij Flex vastgoed CRM | Contracten",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>