<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-tabs v-model="tab">
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.tab }}
        </v-tab>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              NAW gegevens
            </v-card-title>

            <v-card-text>
              <TenantProfile :id="id" @annuleer="handleAnnuleer" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Administratie
            </v-card-title>

            <v-card-text>
              <p>Administratie</p>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Contracten
            </v-card-title>

            <v-card-text>
              <p>Contracten</p>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title> </v-card-title>

            <v-card-text>
              <TenantNotes :id="id" @annuleer="handleAnnuleer" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Meldingen
            </v-card-title>

            <v-card-text>
              <p>Dit betreft reparatie meldingen</p>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import TenantProfile from "../tenant/TenantProfile.vue";
import TenantNotes from "../tenant/TenantNotes.vue";
export default {
  components: {
    TenantProfile,
    TenantNotes,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tab: null,
      activeTab: "profile",
      items: [
        { tab: "Profiel", content: "Tab 1 Content" },
        { tab: "Administratie", content: "Tab 2 Content" },
        { tab: "Contracten", content: "Tab 3 Content" },
        { tab: "Notities", content: "Tab 4 Content" },
        { tab: "Meldingen", content: "Tab 5 Content" },
      ],
    };
  },
  methods: {
    handleAnnuleer() {
      this.$emit("annuleer");
    },
  },
};
</script>

<style>
.nav-link.active {
  background-color: #17a2b8;
  color: #fff;
}
</style>
