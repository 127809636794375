<template>
  <v-container style="padding-top: 4rem">
    <v-row>
      <v-col cols="6">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <div style="margin-bottom:10px">
              <v-btn
                rounded
                style="background-color: #147D81;
  color: white;"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Type aanmaken
              </v-btn>
            </div>
          </template>

          <v-card>
            <v-card-title>
              {{
                contractIdToUpdate
                  ? "Contracte type bewerken"
                  : "Nieuwe contract type aanmaken"
              }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="contractType"
                label="contract type"
                outlined
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelEditcontractType()"
                >Annuleren</v-btn
              >
              <v-btn
                style="background-color: #147D81;
  color: white;"
                @click="saveContractType()"
                >Aanmaken</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:top> </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="red" @click="deletecontractType(item.id)">
              mdi-delete
            </v-icon>
            <v-icon small color="green" @click="editContractType(item.id)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiContractType from "../api/apiContractType";
export default {
  data: () => ({
    dialog: false,
    contractType: "",
    contractIdToUpdate: null,
    search: "",
    items: [],

    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  async mounted() {
    apiContractType.getContractType().then((data) => {
      this.items = data;
    });
  },
  methods: {
    async updateContractType(id, data) {
      try {
        const updatedData = {
          name: this.name,
          ...data,
        };

        const response = await apiContractType.updateContractType(
          id,
          updatedData
        );
        console.log(response);

        this.$swal.fire("Gelukt!", "Contract type is bijgewerkt!", "success");
        this.items = await apiContractType.getContractType();
        this.name = "";
        this.dialog = false;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },

    saveContractType() {
      if (this.contractType) {
        if (this.contractIdToUpdate) {
          this.updateContractType(this.contractIdToUpdate);
        } else {
          this.createOrUpdatecontractType();
        }
      } else {
        this.$swal.fire("Warning", "contractType name is required.", "warning");
      }
    },
    cancelEditcontractType() {
      this.dialog = false;
    },

    async deletecontractType(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze contract type wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiContractType.deleteContractType(id);
        await this.getContractType();
        this.$swal.fire(
          "Verwijderd",
          "De Contracttype is succesvol verwijderd",
          "success"
        );
      }
    },

    editContractType(id) {
      const contractType = this.items.find((item) => item.id === id);
      if (contractType) {
        this.contractType = contractType.name;
        this.contractIdToUpdate = id;
        this.dialog = true;
      }
    },
    createOrUpdatecontractType: async function() {
      if (this.contractIdToUpdate) {
        await apiContractType.updateContractType(this.contractIdToUpdate, {
          name: this.contractType,
        });
      } else {
        await apiContractType.createContractType({ name: this.contractType });
      }

      this.dialog = false;
      this.contractType = "";

      // Refresh facilities list
      await apiContractType.getContractType().then((data) => {
        this.items = data;
      });

      this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
    },
  },
};
</script>
