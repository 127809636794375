
<template>
  <EmailImport />
</template>

<script>
import EmailImport from "@/components/instellingen/EmailImport";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    EmailImport,
  },
  metaInfo: {
    title: "FLEX VASTGOED CRM | E-MAIL IMPORT",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>