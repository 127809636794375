<template>
  <v-container>
    <div>
      <v-tabs v-model="tab" background-color="#147D81" dark>
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.tab }}
        </v-tab>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              NAW gegevens
            </v-card-title>

            <v-card-text>
              <v-col cols="3">
                <v-select
                  :items="titels"
                  label="Titel"
                  v-model="tenant.title"
                ></v-select>
              </v-col>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Voornaam"
                    v-model="tenant.firstName"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Tussenvoegsel"
                    v-model="tenant.insertion"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Achternaam"
                    v-model="tenant.lastName"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Telefoonnummer"
                    v-model="tenant.phone"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Email"
                    v-model="tenant.email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Geboortedatum"
                    v-model="tenant.birthday"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Geboorteplaats"
                    v-model="tenant.birthplace"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-col cols="3">
                <v-select
                  :items="burgelijkestaten"
                  label="Burgerlijke staat"
                  v-model="tenant.maritalStatus"
                ></v-select>
              </v-col>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Adresgegevens
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Adres"
                    v-model="tenant.adres"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Huisnummer"
                    v-model="tenant.houseNumber"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Postcode"
                    v-model="tenant.postcode"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Stad"
                    v-model="tenant.city"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Geboortedatum"
                    v-model="tenant.birthday"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Geboorteplaats"
                    v-model="tenant.birthplace"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="tenant.country"
                    :items="countries"
                    label="Kies een land"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Administratiegegevens
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-select
                    :items="identificationTypes"
                    label="Type identificatie"
                    v-model="tenant.identificationType"
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Identificatienummer"
                    v-model="tenant.identificationNumber"
                  ></v-text-field>
                  <v-alert v-if="isInvalidIdentificationNumber" type="error">
                    Ongeldig identificatienummer
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Identificatie einddatum"
                    v-model="tenant.identificationEndDate"
                  ></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Bankrekeningnummer"
                    v-model="tenant.banknumber"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-btn
        style="background-color: #147D81;
  color: white"
        @click="updateTenant(tenant)"
        >Profiel updaten</v-btn
      >

      <v-btn
        style="color:white;background-color:#cb403d;margin-left:5px"
        @click="goBack()"
        >Afsluiten</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import apiTenant from "../api/apiTenant";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tenant: {},
      identificationTypes: ["Paspoort", "Rijbewijs", "Identiteitskaart"],
      tab: null,
      countries: [],
      selectedCountry: null,
      burgelijkestaten: [
        "Gehuwd",
        "Ongehuwd",
        "Geregistreerd partnerschap",
        "Gescheiden",
        "Weduwe/Weduwnaar",
      ],
      titels: ["Heer", "Mevrouw", "Zeg ik liever niet"],
      items: [
        { tab: "NAW gegevens", content: "Tab 1 Content" },
        { tab: "Adresgegevens", content: "Tab 2 Content" },
        { tab: "Administratiegegevens", content: "Tab 3 Content" },
      ],
    };
  },

  computed: {
    isInvalidIdentificationNumber() {
      if (
        this.identificationType === "Paspoort" &&
        this.identificationNumber.length !== 9
      ) {
        return true;
      }
      if (
        this.identificationType === "Rijbewijs" &&
        this.identificationNumber.length !== 10
      ) {
        return true;
      }
      return false;
    },
  },

  async mounted() {
    apiTenant.getTenantbyId(this.$route.params.id).then((data) => {
      this.tenant = data;
    });
    this.getCountries();
  },

  methods: {
    goBack(){
this.$router.push("/Huurders")
    },
    async updateTenant(tenant) {
      try {
        const id = this.$route.params.id;
        const response = await apiTenant.updateTenant(id, tenant);
        console.log(response);
        this.tenant = response;
        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
        this.tenant = await apiTenant.getTenantbyId(this.id);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },

    getCountries() {
      axios
        .get("https://restcountries.com/v3.1/all")
        .then((response) => {
          this.countries = response.data.map((country) => country.name.common);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
