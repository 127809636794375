<template>
  <v-container style="padding-top:4rem">
    <h2 style="margin-bottom:2rem">Bescherming van persoonlijke gegevens</h2>

    <div>
      <h5>Over de bescherming van persoonlijke gegevens</h5>
      <p>
        Veel landen hebben wetgeving die de bescherming van persoonlijke
        gegevens behandelt. In de EU wordt de bescherming van gegevens behandeld
        in de General Data Protection Regulations (GDPR). Uit deze wetgeving
        komen enkele vereisten voort voor organisaties, zoals de aanvraag om
        persoonlijke gegevens te verwijderen na een bepaalde periode. TOPdesk
        heeft verschillende functionaliteiten waarmee u kunt voldoen aan deze
        wetgeving.
      </p>
      <v-divider> </v-divider>

      <h5>Inactieve personen anonimiseren</h5>
      <p>
        Wanneer een relatie of project op "niet actief" wordt gezet dan worden
        de persoon in de database geanomiseerd. Let wel op dat je de personen
        die geanomiseerd zijn niet kan terughalen.
      </p>

      <v-switch
        v-model="archivePerson"
        label="Inactieve personen archiveren "
        hide-details
        :class="{ 'font-weight-bold': archivePerson }"
      ></v-switch>

      <v-switch
        v-model="archiveProject"
        label="Inactieve projecten archiveren "
        hide-details
        :class="{ 'font-weight-bold': archiveProject }"
      ></v-switch>

      <v-switch
        v-model="archiveMechanic"
        label="Inactieve monteurs archiveren  "
        hide-details
        :class="{ 'font-weight-bold': archiveMechanic }"
      ></v-switch>

      <v-switch
        v-model="archiveRelation"
        label="Inactieve relaties archiveren   "
        hide-details
        :class="{ 'font-weight-bold': archiveRelation }"
      ></v-switch>

      <v-row style="padding-top:3rem">
        <v-col cols="7">
          <v-checkbox
            v-model="aantalMaanden"
            label="Automatisch contactpersonen anonimiseren die zijn gearchiveerd voor (tenminste): "
          ></v-checkbox>
        </v-col>
        <v-col cols="4">
          <v-text-field
            outlined
            type="number"
            suffix="maanden"
            dense
            max-width="50"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      archivePerson: false,
      archiveProject: false,
      archiveMechanic: false,
      archiveRelation: false,
      aantalMaanden: null,
    };
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-bottom: 10px;
}

h5 {
  font-size: 15px;
}

p {
  font-size: 15px;
}
</style>
