
<template>
  <CompanyProfile />
</template>

<script>
import CompanyProfile from "@/components/company/CompanyProfile";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
   CompanyProfile,
  },
  metaInfo: {
 title: "FLEX VASTGOED CRM | Bedrijfsprofiel",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>