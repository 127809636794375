<template>
  <div style="margin-right:20px">
    <v-card max-width="1200" style="padding:25px">
    

        <v-col cols="8">
          <h4 class="font-weight-bold">Algemene informatie</h4>
          <v-simple-table class="border">
            <tbody>
              
              <tr class="project-overview">
                <td class="font-weight-bold" width="30%">
                  Voornaam
                </td>
                <td>
                  <form>
                    <input v-model="employee.firstName" readonly />
                  </form>
                </td>
              </tr>
              <tr class="project-overview">
                <td class="font-weight-bold" width="30%">
                  Achternaam
                </td>

                <td>
                  <form>
                    <input v-model="employee.lastName" readonly />
                  </form>
                </td>
              </tr>

              <tr class="project-overview">
                <td class="font-weight-bold" width="30%">
                 Email
                </td>

                <td>
                  <form>
                    <input v-model="employee.mail" readonly />
                  </form>
                </td>
              </tr>

              <tr class="project-overview">
                <td class="font-weight-bold" width="30%">
                  Telefoonnummer
                </td>

                <td>
                  <form>
                    <input v-model="employee.phoneNumber" readonly />
                  </form>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
    </v-card>
  
  </div>
</template>

<script>
// import apiTimeSheet from "../api/apiTimeSheet";
import apiUser from "../api/apiUser";
// import Avatar from "vue-avatar-component";
export default {
  // components: { Avatar },
  data() {
    return {
    
      active: true,
      activeElement: "Pending",
      search: "",
      employee: {},

     
    };
  },

  async mounted() {
    try {
      const data = await apiUser.getCurrentUser();
      this.employee = data;
    } catch (error) {
      console.error(error);
    }
  },
    // try {
    //   const data = await apiTimeSheet.gettotalThisWeek();
    //   this.totalThisWeek = data;
    // } catch (error) {
    //   console.error(error);
    // }
    // try {
    //   const data = await apiTimeSheet.gettotalLastWeek();
    //   this.totalLastWeek = data;
    // } catch (error) {
    //   console.error(error);
    // }

  //   try {
  //     const data = await apiTimeSheet.gettotalThisMonth();
  //     this.totalThisMonth = data;
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   try {
  //     const data = await apiTimeSheet.getTotalLastMonth();
  //     this.TotalLastMonth = data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
  methods: {
    async updateMyAccount(employee) {
      try {
        const response = await apiUser.updateMyAccount(employee);
        console.log(response);
        this.employee = response; // Update employee object with new values

        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het toevoegen is niet gelukt!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.h4 {
  font-weight: 400;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.h4,
h4 {
  font-size: 18px;
}
.panel_s {
  margin-top: 20px;
}
.panel-heading {
  font-size: 18px;
}
</style>
