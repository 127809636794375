<template>
  <v-container style="padding-top:4rem">
    <div>
      <v-row>
        <v-col cols="4">
          <v-text-field
            placeholder="Categorie invullen"
            v-model="form.name"
          ></v-text-field>

          <v-btn
            rounded
            style="background-color: #147D81;
  color: white"
            @click="addCategorie()"
          >
            Categorie aanmaken
          </v-btn>
        </v-col>

        <div v-if="items.length === 0">
            <img src="@/assets/nodata.jpg" height="550" width="650" />
          </div>

        <v-col cols="8" v-else>
          <v-card>
            <v-card-title>
              Kennisitems categorie
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <div class="table-container">
              <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                disable-pagination
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:[`item.Actions`]="{ item }">
                  <!-- <v-icon small color="green">
                    mdi-eye
                  </v-icon> -->
                  <v-icon small color="red" @click="deleteCategorie(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiKnowledgeCategorie from "../api/apiKnowledgeCategorie";
export default {
  data: () => ({
    form: {
      name: "",
      description: "",
    },
    items: [],
    search: "",
    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    apiKnowledgeCategorie.getKnowledgeCategories().then((data) => {
      this.items = data;
    });
  },

  methods: {
    resetForm() {
      this.form.name = "";
    },

    async addCategorie() {
      try {
        if (!this.form.name) {
          this.$swal.fire("Foutmelding", "Vul het veld in", "error");
          return;
        }

        const response = await apiKnowledgeCategorie.createKnowledgeCategorie(
          this.form
        );

        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.resetForm();
        const data = await apiKnowledgeCategorie.getKnowledgeCategories();
        this.items = data;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async deleteCategorie(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze Categorie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiKnowledgeCategorie.deleteKnowledgeCategorie(id);
        const data = await apiKnowledgeCategorie.getKnowledgeCategories();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De Categorie is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
