import {API} from "./api.base.js";

export default {
    async getMechanicsColumnNames() {
        try {
          const response = await API.get('/api/rest/v2/import/mechanics/columns');
          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
  async getRelationsColumnsNames() {
    try {
      const response = await API.get('/api/rest/v2/import/relations/columns');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
