<template>
  <Relations />
</template>

<script>
import Relations from "@/components/relation/Relations";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Relations,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Relaties ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>