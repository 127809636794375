
<template>
  <SystemInfo />
</template>

<script>
import SystemInfo from "@/components/instellingen/SystemInfo";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SystemInfo,
  },
  metaInfo: {
    title: "Systeem information | Dashboard",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>