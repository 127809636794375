<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div v-if="filteredRelations.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
        <v-card v-else>
          <v-card-title>
            Relaties
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Opzoeken"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col
              v-for="letter in letters"
              :key="letter"
              cols="auto"
              style="margin-left:20px"
            >
              <v-btn
                :color="selectedLetter === letter ? '#147D81' : ''"
                @click="selectLetter(letter)"
              >
                {{ letter }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-for="relation in filteredRelations"
              :key="relation.id"
              cols="12"
              md="6"
              lg="4"
            >
              <v-card style="margin-left:20px">
                <v-card-title>{{ relation.firstName }}</v-card-title>
                <v-card-text>
                  <div>Voornaam: {{ relation.firstName }}</div>
                  <div>Achternaam: {{ relation.lastName }}</div>
                  <div>Email: {{ relation.email }}</div>
                  <div>Telefoonnummer: {{ relation.phone }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-icon
                    small
                    color="green"
                    @click="openRelation(relation.id)"
                  >
                    mdi-eye
                  </v-icon>
                  <v-icon
                    small
                    color="red"
                    @click="deleteRelation(relation.id)"
                  >
                    mdi-delete
                  </v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRelation from "../api/apiRelation";

export default {
  data: () => ({
    relations: [],
    search: "",
    selectedLetter: "",
    letters: [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
  }),

  async mounted() {
    apiRelation.getRelation().then((data) => {
      this.relations = data;
    });
  },

  computed: {
    filteredRelations() {
      if (this.selectedLetter) {
        return this.relations.filter((trader) => {
          return (
            trader.firstName.charAt(0).toUpperCase() === this.selectedLetter
          );
        });
      }
      return this.relations;
    },
  },

  methods: {
    openRelation(id) {
      this.$router.push(`/relatie/${id}`);
    },
    selectLetter(letter) {
      this.selectedLetter = letter;
    },
    async deleteRelation(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze relatie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiRelation.deleteRelation(id);
        const data = await apiRelation.getRelation();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De relatie is succesvol verwijderd",
          "success"
        );
      }
    },
  },
};
</script>
