<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-col cols="12">
          <AddMechanicForm
            v-if="showAddMechanicForm"
            @cancel="showAddMechanicForm = false"
            @employee-added="handleEmployeeAdded"
          />

          <div style="margin-bottom:10px">
            <v-btn
              rounded
              style="background-color: #147D81;
  color: white"
              dark
              @click="showAddMechanicForm = true"
              v-if="!(showAddMechanicForm || showSingleMechanicForm)"
            >
              Onderhoudstechnicus aanmaken
            </v-btn>
          </div>

          <div v-if="items.length === 0">
            <img src="@/assets/nodata.jpg" height="550" width="650" />
          </div>

          <v-card v-else-if="!(showAddMechanicForm || showSingleMechanicForm)">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
                  Exporteren
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.text"
                  @click="exportData(item.type)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-title>
              Monteurs
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="elevation-1"
              id="my-mechanis"
              cellClass="printable"
            >
              <template v-slot:item.expertises="{ item }">
                <v-chip-group>
                  <v-chip
                    v-for="(expertise, index) in item.expertises"
                    :key="index"
                    small
                    outlined
                    :color="getChipColor(expertise)"
                    >{{ expertise.name }}</v-chip
                  >
                </v-chip-group>
              </template>
              <template v-slot:top> </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon
                  small
                  color="green"
                  @click="openMechanicProfile(item.id)"
                >
                  mdi-eye
                </v-icon>
                <v-icon small color="red" @click="deleteMechanic(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AddMechanicForm from "../mechanic/AddMechanicForm.vue";
import apiMechanics from "../api/apiMechanics";
export default {
  components: {
    AddMechanicForm,
  },
  data: () => ({
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    search: "",
    showAddMechanicForm: false,
    showSingleMechanicForm: false,

    items: [],
    headers: [
      {
        text: "Voornaam",
        value: "firstName",
        sortable: true,
      },

      {
        text: "Achternaam",
        value: "lastName",
        sortable: true,
      },

      {
        text: "Email",
        value: "email",
        sortable: true,
      },
      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
      },
      {
        text: "Expertises",
        value: "expertises",
        sortable: true,
      },

      {
        text: "Status",
        value: "status",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  async mounted() {
    apiMechanics.getMechanics().then((data) => {
      this.items = data;
    });
  },
  created() {
    this.$on("employee-added", () => {
      apiMechanics.getMechanics().then((data) => {
        this.items = data;
      });
    });
  },
  computed: {
    limitedExpertises() {
      return this.mechanic.expertises.slice(0, 3);
    },
  },
  methods: {
    handleEmployeeAdded() {
      apiMechanics.getMechanics().then((data) => {
        this.items = data;
      });
    },
    async deleteMechanic(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze Monteur wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiMechanics.deleteMechanic(id);
        const data = await apiMechanics.getMechanics();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De Monteur is succesvol verwijderd",
          "success"
        );
      }
    },
    openMechanicProfile(mechanicId) {
      this.$router.push(`/Monteursbeheren/${mechanicId}`);
    },
    getChipColor(expertise) {
      switch (expertise.name) {
        case "Schilder":
          return "red";
        case "Allround":
          return "blue";
        case "Electricien":
          return "green";
        case "Montage van meubels":
          return "orange";
        case "Stucadoor":
          return "purple";
        default:
          return "grey";
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Relaties.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.items.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Monteurssoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-mechanis").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style scoped></style>
