import {API} from "./api.base.js"
export default {

  async  createIncidentStatus(data) {
    try {
      const response = await API.post('/api/rest/v2/incidentstatus', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getIncidentsStatus() {
    try {
      const response = await API.get('/api/rest/v2/incidentstatus');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteIncidentStatus(id) {
    try {
      const response = await API.delete(`/api/rest/v2/incidentstatus/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateIncidentStatus(id) {
    try {
      const response = await API.put(`/api/rest/v2/incidentstatus/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

 
};
