import { render, staticRenderFns } from "./ActiviteitenLogs.vue?vue&type=template&id=8dca9040&"
import script from "./ActiviteitenLogs.vue?vue&type=script&lang=js&"
export * from "./ActiviteitenLogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports