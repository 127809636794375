<template>
  <div style="padding-top:4rem">
    <v-container>
      <v-row>
        <v-dialog v-model="dialog" persistent max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="background-color: #147D81; color: white; margin-left: 20px"
              dark
              v-bind="attrs"
              v-on="on"
            >
              {{ apiKeyGenerated ? "API Key bekijken" : "API Key aanmaken" }}
            </v-btn>
          </template>
          <v-card>
            <v-toolbar color="primary" dark>APIkey aanmaken</v-toolbar>
            <v-card-text>
              <v-btn
                color="primary"
                @click="generateApiKey()"
                v-if="!apiKeyGenerated"
                >Generate key</v-btn
              >
              <div style="margin-top:5px">
                <v-text-field
                  label="Gegeneer apiKey"
                  outlined
                  v-model="apiKey.apiKey"
                  :disabled="!apiKeyGenerated"
                >
                </v-text-field>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                @click="copyApiKey"
                :disabled="!apiKeyGenerated"
                >Copy key</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <iframe
        src="https://backend.flexvastgoedcrm.nl/api-docs/"
        style="width: 100%; height: 700px; border: none"  scrolling="no"
      ></iframe>
    </v-container>
  </div>
</template>

<script>
import apiKeys from "../api/apiKeys";
export default {
  data() {
    return {
      apiKey: [],
      apiKeyGenerated: false,
      dialog: false,
    };
  },

  async mounted() {
    apiKeys.getApiKey().then((data) => {
      this.apiKey = data[0];
      if (data) {
        this.apiKeyGenerated = true;
      }
    });
  },

  methods: {
    async generateApiKey() {
      try {
        const response = await apiKeys.generateApiKeys();
        console.log(response);
        if (response.length > 0 && response[0].apiKey) {
          this.apiKey = response[0].apiKey;
          this.apiKeyGenerated = true;
          this.$swal.fire("Yes!", "API Key is gegenereerd", "success");
        } else {
          this.$swal.fire(
            "Er is een foutmelding!",
            "Ongeldige API Key respons",
            "error"
          );
        }
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
    copyApiKey() {
      const apiKeyText = this.apiKey.apiKey;
      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(apiKeyText)
          .then(() => {
            this.$swal.fire("API Key gekopieerd!", "", "success");
          })
          .catch((error) => {
            console.error("Kopiëren naar klembord mislukt: ", error);
            this.$swal.fire(
              "Kopiëren naar klembord mislukt",
              "Probeer het handmatig te kopiëren.",
              "error"
            );
          });
      } else {
        const tempInput = document.createElement("input");
        document.body.appendChild(tempInput);
        tempInput.value = apiKeyText;
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        this.$swal.fire("API Key gekopieerd!", "", "success");
      }
    },
  },
};
</script>
