<template>
  <v-container style="padding-top: 4rem">
    <v-row>
      <v-col cols="6">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <div style="margin-bottom:10px">
              <v-btn
                rounded
                style="background-color: #147D81;
  color: white"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Nieuwe Faciliteit
              </v-btn>
            </div>
          </template>

          <v-card>
            <v-card-title>
              {{
                facilityIdToUpdate
                  ? "Faciliteit bewerken"
                  : "Nieuwe faciliteit aanmaken"
              }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="facilityName"
                label="Faciliteit naam"
                outlined
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelEditFacility()"
                >Annuleren</v-btn
              >
              <v-btn
                style="background-color: #147D81;
  color: white"
                @click="saveFacility()"
                >Aanmaken</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <div v-if="items.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="650" />
        </div>
      
    
          <v-data-table
          v-else
          :headers="headers"
          :items="items"
          :search="search"
          disable-pagination
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:top> </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon small color="red" @click="deleteFacility(item.id)">
              mdi-delete
            </v-icon>
            <v-icon small color="green" @click="editFacility(item.id)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>

      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiFacilities from "../api/apiFacilities";
export default {
  data: () => ({
    dialog: false,
    facilityName: "",
    facilityIdToUpdate: null,
    search: "",
    items: [],

    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  async mounted() {
    await this.getFacilities();
  },
  methods: {
    async updateFacility(id, data) {
      try {
        const updatedData = {
          name: this.facilityName,
          ...data,
        };
        const response = await apiFacilities.updateFacilities(id, updatedData);
        console.log(response);

        this.$swal.fire("Gelukt!", "Faciliteit is bijgewerkt!", "success");
        this.dialog = false;
        this.name = "";
        this.items = await apiFacilities.getFacilities();
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },

    saveFacility() {
      if (this.facilityName) {
        if (this.facilityIdToUpdate) {
          this.updateFacility(this.facilityIdToUpdate);
        } else {
          this.createOrUpdateFacility();
        }
      } else {
        this.$swal.fire("Warning", "Facility name is required.", "warning");
      }
    },

    cancelEditFacility() {
      this.dialog = false;
    },
    async getFacilities() {
      const facilities = await apiFacilities.getFacilities();
      this.items = facilities;
    },
    async deleteFacility(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze faciliteit wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiFacilities.deleteFacilities(id);
        await this.getFacilities();
        this.$swal.fire(
          "Verwijderd",
          "De faciliteit is succesvol verwijderd",
          "success"
        );
      }
    },

    editFacility(id) {
      const facility = this.items.find((item) => item.id === id);
      if (facility) {
        this.facilityName = facility.name;
        this.facilityIdToUpdate = id;
        this.dialog = true;
      }
    },
    createOrUpdateFacility: async function() {
      if (this.facilityIdToUpdate) {
        await apiFacilities.updateFacilities(this.facilityIdToUpdate, {
          name: this.facilityName,
        });
      } else {
        await apiFacilities.createFacilitie({ name: this.facilityName });
      }

      this.dialog = false;
      this.facilityName = "";

      // Refresh facilities list
      await apiFacilities.getFacilities().then((data) => {
        this.items = data;
      });

      this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
    },
  },
};
</script>
