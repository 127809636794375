
<template>
  <Password />
</template>

<script>
import Password from "@/components/profile/Password";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Password,
  },
  metaInfo: {
    title: "Flex CRM Wachtwoord wijzigen | Profile",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>