import {API} from "./api.base.js"
export default {

  async getKeys() {
    try {
      const response = await API.get('/api/rest/v2/keys');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateKey(id,lentTo) {
    try {
      const response = await API.put(`/api/rest/v2/keys/${id}`,lentTo);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
