<template>
  <v-container>
    <v-layout justify-end>
      <v-btn roundedclass="btn" v-if="isImported" @click="isImported = false" style="color: white" color="#147D81">
        Nieuwe import
      </v-btn>
    </v-layout>

    <v-stepper v-if="!isImported" v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        1. Doel
        <small>Doel instellen</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12" height="300px">
          <v-row>
            <v-col cols="12">
              <p>Selecteer het kaartsoort dat je wilt importeren:</p>
              <div>
                <v-select
                  :items="importTypes"
                  label="Selecteer import"
                  dense
                ></v-select>
              </div>
            </v-col>

            <v-col cols="12">
              Korte omschrijving (optioneel)
              <v-text-field label="omschrijving" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-btn class="btn" @click="e6 = 2">
          Volgende
        </v-btn>
        <v-btn @click="isImported = true" text>
          Annuleer
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        2. Bron
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card class="mb-12" height="300px">
          Selecteer het bestand waar je uit wilt importeren (Microsoft Excel of
          CSV) Gebruik de knop 'Xlsx-sjabloon downloaden' voor een correct
          opgemaakt bronbestand.

          <div class="drag-and-drop" @dragover.prevent @drop="dropFile($event)">
            <div class="drag-and-drop__label">
              Sleep een bestand hierheen of
            </div>
            <v-btn class="btn" @click="openFileInput">
              Selecteer een bestand
            </v-btn>
            <input
              ref="fileInput"
              type="file"
              class="drag-and-drop__input"
              @change="importExcel"
              accept=".xlsx, .xls"
            />
          </div>
          <v-data-table :headers="headers" :items="items"></v-data-table>
        </v-card>
        <v-btn class="btn" @click="e6 = 3">
          Volgende
        </v-btn>
        <v-btn @click="isImported = true" text>
          Annuleer
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        3. Veldtoewijzing
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card class="mb-12" height="300px">
          <v-col>
            <ul>
              <li v-for="column in mechanicsColumns" :key="column.name">
                {{ column.name }}<span v-if="column.isRequired">*</span>
              </li>
            </ul>
          </v-col>

          <v-col> </v-col>
        </v-card>
        <v-btn class="btn" @click="e6 = 4">
          Volgende
        </v-btn>
        <v-btn @click="isImported = true" text>
          Annuleer
        </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4">
        Importeren
      </v-stepper-step>
      <v-stepper-content step="4">
        <v-card class="mb-12" height="300px">
          Deze import kan:<br /><b>
            De import kan alleen bestaande records updaten. Om nieuwe records
            aan te maken, gaat u naar de stap 'Veldtoewijzing' en wijst u de/het
            volgende verplichte FlexCRM-veld(en) toe.</b
          >
          <v-checkbox
            label="Nieuwe kaarten van soort 'Contactpersoon' aanmaken`"
          ></v-checkbox>
          <v-checkbox
            label="Huidige kaarten van soort Contactpersoon updaten"
          ></v-checkbox>
        </v-card>
        <v-btn class="btn">
          Import
        </v-btn>
        <v-btn @click="isImported = true" text>
          Annuleer
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>

<script>
import apiImport from "../api/apiImport";
export default {
  data() {
    return {
      headers: [],
      items: [],
      isImported: true,
      e6: 1,
      importTypes: ["Monteurs", "Relaties", "Huurders"],
      mechanicsColumns: [],
      relationsColumns: [],
    };
  },
  async mounted() {
    apiImport.getMechanicsColumnNames().then((data) => {
      // Add "required" property to columns as needed
      data.forEach((column) => {
        if (column.propertyName === "requiredFieldName") {
          column.required = true;
        } else {
          column.required = false;
        }
      });
      this.mechanicsColumns = data;
    });
    apiImport.getRelationsColumnsNames().then((data) => {
      this.relationsColumns = data;
    });
  },

  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    importExcel(event) {
      const file = event.target.files[0];
      this.$readXlsxFile(file).then((rows) => {
        console.log(rows);
      });
    },
    dropFile(e) {
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      this.readFile(file);
    },
  },
};
</script>

<style scoped>
.drag-and-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed gray;
  padding: 20px;
  margin: 20px;
}

.drag-and-drop__label {
  margin-bottom: 20px;
}

.drag-and-drop__input {
  display: none;
}
</style>
