<template>
  <v-container style="padding-top:2rem">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="6">
        <v-card>
          <v-card-text>
            <v-radio-group v-model="authType" mandatory row v-if="!dataLoaded">
              <v-radio label="Basisauthenticatie" value="basic"></v-radio>
              <v-radio
                label="Microsoft Graph met OAuth 2.0"
                value="oauth"
              ></v-radio>
            </v-radio-group>

            <v-row v-if="authType === 'basic'">
              <v-col cols="12">
                <v-text-field
                  dense
                  placeholder="Host"
                  v-model="basicAuth.host"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Poort"
                  v-model="basicAuth.port"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
                <v-text-field
                  dense
                  placeholder="Gebruikersnaam"
                  v-model="basicAuth.username"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Wachtwoord"
                  v-model="basicAuth.password"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  dense
                  placeholder="Emailadres "
                  v-model="basicAuth.mail"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="authType === 'oauth'">
              <v-col cols="12">
                <v-text-field
                  dense
                  v-model="oauth.clientId"
                  placeholder="Voeg client ID"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="oauth.tenantId"
                  placeholder="Tenant ID"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="oauth.clientSecret"
                  placeholder="Client secret"
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>

                <v-text-field
                  dense
                  v-model="oauth.mail"
                  placeholder="Emailadres "
                  outlined
                  :disabled="!isEditing"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  :color="isEditing ? 'primary' : 'secondary'"
                  @click="handleButtonClick"
                  block
                >
                  {{ isEditing ? "Opslaan" : "Configuratie wijzigen" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiMail from "../../api/apiMail";
export default {
  data() {
    return {
      basicAuthLoaded: false,
      oauthLoaded: false,
      dataLoaded: false,
      basicAuth: {
        host: "",
        username: "",
        port: "",
        password: "",
        mail: "",
        authType: "basic",
      },

      oauth: {
        clientId: "",
        tenantId: "",
        clientSecret: "",
        mail: "",
        authType: "oauth",
      },
      isEditing: false,
      authType: "",
    };
  },

  async mounted() {
    const basicAuthData = await apiMail.getBasicAuth();
    if (this.areAllFieldsFilled(basicAuthData)) {
      this.basicAuth = basicAuthData;
      this.basicAuthLoaded = true;
      this.dataLoaded = true;
    }

    const oauthData = await apiMail.getAuth();
    if (this.areAllFieldsFilled(oauthData)) {
      this.oauth = oauthData;
      this.oauthLoaded = true;
      this.dataLoaded = true;
    }
  },

  methods: {
    areAllFieldsFilled(obj) {
      return Object.values(obj).every((value) => value !== "");
    },
    handleButtonClick() {
      if (this.isEditing) {
        this.addMailserver();
      } else {
        this.isEditing = true;
      }
    },
    async addMailserver() {
      let mailServerData;
      if (this.authType === "basic") {
        if (!this.areAllFieldsFilled(this.basicAuth)) {
          this.$swal.fire(
            "Foutmelding",
            "Alle velden moeten ingevuld zijn",
            "error"
          );
          return;
        }
        mailServerData = this.basicAuth;
      } else if (this.authType === "oauth") {
        if (!this.areAllFieldsFilled(this.oauth)) {
          this.$swal.fire(
            "Foutmelding",
            "Alle velden moeten ingevuld zijn",
            "error"
          );
          return;
        }
        mailServerData = this.oauth;
      } else {
        this.$swal.fire("Fout", "Selecteer een authenticatietype", "error");
        return;
      }

      try {
        const response = await apiMail.addMailServer(mailServerData);
        console.log(response);
        this.$swal.fire("Yes!", "Authenticatiegegevens opgeslagen", "success");
        this.isEditing = !this.isEditing;
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 10px;
}
</style>
