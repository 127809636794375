import {API} from "./api.base.js"

export default {
  async createIprestriction(data) {
    try {
      const response = await API.post('/api/rest/v2/iprestriction',data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getIprestrictions() {
    try {
      const response = await API.get('/api/rest/v2/iprestriction');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

};
