<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-col>
          <v-btn
            rounded
            style="background-color: #147D81;
  color: white"
            dark
            @click="showAddRelationForm = true"
            v-if="!showAddRelationForm"
            @relation-added="handleRelationAdded"
          >
            Relatie aanmaken
          </v-btn>

          <AddRelationForm
            v-if="showAddRelationForm"
            @cancel="showAddRelationForm = false"
            @relation-added="getRelationsData"
          />
        </v-col>
        <div style="float:right" v-if="!showAddRelationForm">
          <v-icon
            :color="showTable ? '#147D81' : undefined"
            @click="
              showTable = true;
              showCard = false;
            "
            >mdi-table</v-icon
          >
          <v-icon
            :color="showCard ? '#147D81' : undefined"
            @click="
              showTable = false;
              showCard = true;
            "
            >mdi-account-box-outline</v-icon
          >
        </div>
        <v-col cols="12" v-if="!showAddRelationForm">
          <div class="row mbot15 overview">
            <div class="col-md-12">
              <h4 class="no-margin">Relatie overzicht</h4>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ relationsInfo.totalRelations.aantal }}</h3>
              <span class="text-dark">Totaal relaties</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">
                {{ relationsInfo.totalActiveRelations.aantal }}
              </h3>
              <span class="text-success">Actieve relaties</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">
                {{ relationsInfo.totalNonActiveRelations.aantal }}
              </h3>
              <span class="text-danger">Inactieve relaties</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ relationsInfo.activeContacts }}</h3>
              <span class="text-info">Actieve contracten</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ relationsInfo.inactiveContacts }}</h3>
              <span class="text-danger">Onactieve contracten</span>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <RelationTable
            v-if="showTable && !showCard && !showAddRelationForm"
          />
          <RelationsCard
            v-if="!showTable && showCard && !showAddRelationForm"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RelationsCard from "../relation/RelationsCard.vue";
import RelationTable from "../relation/RelationTable.vue";
import AddRelationForm from "../relation/AddRelationForm.vue";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiRelation from "../api/apiRelation";
export default {
  components: {
    AddRelationForm,
    RelationsCard,
    RelationTable,
  },
  data: () => ({
    showTable: true,
    showCard: false,
    search: "",
    items: [],
    showAddRelationForm: false,
    relationsInfo: {
      totalRelations: [],
      totalActiveRelations: [],
      totalNonActiveRelations: [],
      activeContacts: 0,
      inactiveContacts: 0,
    },
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
  }),

  created() {
    this.$on("relation-added", () => {
      apiRelation.getRelation().then((data) => {
        this.items = data;
      });
    });
  },
  async mounted() {
    apiRelation.getTotalRelations().then((data) => {
      this.relationsInfo.totalRelations = data;
    });
    apiRelation.getActiveRelations().then((data) => {
      this.relationsInfo.totalActiveRelations = data;
    });

    apiRelation.getNonActiveRelations().then((data) => {
      this.relationsInfo.totalNonActiveRelations = data;
    });
    apiRelation.getRelation().then((data) => {
      this.items = data;
    });
  },

  methods: {
    handleRelationAdded() {
      apiRelation.getRelation().then((data) => {
        this.items = data;
      });
    },
    async deleteRelation(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze relatie wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiRelation.deleteRelation(id);
        const data = await apiRelation.getRelation();
        this.items = data;
        this.$swal.fire(
          "Verwijderd",
          "De relatie is succesvol verwijderd",
          "success"
        );
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "relatiesoverzicht.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.filteredItems.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("relatiesoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-relations").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style>
.overview {
  margin-bottom: 15px;
}
</style>
