import {API} from "./api.base.js"
export default {

  async  createContract(data) {
    try {
      const response = await API.post('/api/rest/v2/contract', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getContracts() {
    try {
      const response = await API.get('/api/rest/v2/contract');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteContract(id) {
    try {
      const response = await API.delete(`/api/rest/v2/contract/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateContract(id) {
    try {
      const response = await API.put(`/api/rest/v2/contract/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getContractById(id) {
    try {
      const response = await API.get(`/api/rest/v2/contract/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generatePDF(id) {
    try {
      const response = await API.post(`/api/rest/v2/contract/generate/pdf/${id}`, {
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
