
<template>
  <Apiview />
</template>

<script>
import Apiview from "@/components/instellingen/Apiview";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Apiview,
  },
  metaInfo: {
    title: "FLEX VASTGOED CRM | Api overview",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>