<template>
  <div>
    <v-container style="padding-top: 2rem">
      <ObjectForm v-if="showForm" @cancel="showForm = false" />
      <v-col>
        <v-btn
          rounded
          style="background-color: #147D81;
  color: white;margin-right:5px"
          @click="showForm = true"
          v-if="!showForm"
        >
          Object toevoegen
        </v-btn>

        <div style="float:right" v-if="!showForm">
          <v-icon
            :color="showTable ? 'primary' : undefined"
            @click="
              showTable = true;
              showMap = false;
            "
            >mdi-table</v-icon
          >
          <v-icon
            :color="showMap ? 'primary' : undefined"
            @click="
              showTable = false;
              showMap = true;
            "
            >mdi-map-marker</v-icon
          >
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <ObjectTable v-if="!showForm && showTable && !showMap" />
          <ObjectMap v-if="!showForm && !showTable && showMap" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ObjectTable from "../realestate/ObjectTable.vue";
import ObjectMap from "../realestate/ObjectMap.vue";
import ObjectForm from "../realestate/ObjectForm.vue";
export default {
  components: {
    ObjectTable,
    ObjectMap,
    ObjectForm,
  },
  data: () => ({
    showForm: false,
    showTable: true,
    showMap: false,
  }),

  methods: {
    goToAddObject() {
      this.$router.push("/ObjectToevoegen");
    },
  },
};
</script>

<style scoped>
.space {
  margin: 3px;
}

</style>

