<template>
    <SingleTenant />
  </template>
  
  <script>
  import SingleTenant from "@/components/tenant/SingleTenant";
  export default {
    data() {
      return {
        data: [],
      };
    },
    components: {
        SingleTenant,
    },
    metaInfo: {
      title: "Flex Vastgoed CRM | Huurders ",
      htmlAttrs: {
        lang: "EN",
        amp: true,
      },
    },
  };
  </script>
  