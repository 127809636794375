<template>
  <v-container>
    <v-btn v-if="isFolderSelected" style="color:white;" color="#147D81" @click="backToMainFolders">
      Terug naar Hoofdmappen
    </v-btn>

    <v-row>
      <v-col cols="3" v-if="!isFolderSelected">
        <h4>Documenten</h4>
      </v-col>
      <v-col cols="9" class="actions" v-if="!isFolderSelected">
        <div class="action" style="margin-right: 5px;">
          <v-btn icon>
            <v-icon color="orange">mdi-plus</v-icon>
          </v-btn>
          <span>Map toevoegen</span>
        </div>

        <div class="action">
          <v-btn
            rounded
            style="color:white;background-color:#147D81"
            @click="handleUpload"
          >
            <v-icon>mdi-file-upload</v-icon>Document uploaden
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!isFolderSelected">
      <v-col
        v-for="folder in folders"
        :key="folder.name"
        cols="12"
        md="6"
        lg="4"
      >
        <v-card class="folder-card" @click="openFolder(folder.name)">
          <v-icon x-large color="yellow">mdi-folder</v-icon>
          <span class="folder-name" style="font-size: 16px">{{
            folder.name
          }}</span>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <single-folder
          v-if="selectedFolder.name"
          :folder-name="selectedFolder.name"
          :folder-files="selectedFolder.files"
          @openFolder="openFolder"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apiFolder from "../api/apiFolders";
import singleFolder from "./SingleFolder.vue";
export default {
  components: {
    singleFolder,
  },
  data() {
    return {
      folders: [],
      selectedFolder: {
        name: "",
        files: [],
      },
      isFolderSelected: false,
    };
  },
  async mounted() {
    try {
      const data = await apiFolder.getDirectories();
      this.folders = data;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    selectFolder(subFolderName) {
      const newPath = this.currentPath
        ? `${this.currentPath}/${subFolderName}`
        : subFolderName;
      this.openFolder(newPath);
    },
    async openFolder(folderName) {
      try {
        console.log(folderName);
        const files = await apiFolder.getDirectoriesFiles(folderName);

        console.log(files);
        this.selectedFolder = {
          name: folderName,
          files,
        };
        this.isFolderSelected = true;
      } catch (error) {
        console.error(error);
      }
    },
    backToMainFolders() {
      this.isFolderSelected = false;
      this.selectedFolder = { name: "", files: [] };
    },
    handleAddFolder() {
      // Voeg hier je logica toe om een nieuwe map aan te maken
      console.log("Nieuwe map aanmaken");
    },

    handleUpload() {
      // Voeg hier je logica toe om een document te uploaden
      console.log("Document uploaden");
    },
  },
};
</script>

<style>
.folder-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
}

.folder-name {
  margin-top: 10px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.action {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.action v-btn {
  margin-right: 5px;
}
</style>
