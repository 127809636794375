import {API} from "./api.base.js"
export default {

  async  createCategorie(data) {
    try {
      const response = await API.post('/api/rest/v2/categories', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getCategories() {
    try {
      const response = await API.get('/api/rest/v2/categories');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteCategories(id) {
    try {
      const response = await API.delete(`/api/rest/v2/categories/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateCategories(id) {
    try {
      const response = await API.put(`/api/rest/v2/categories/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async  createSubategorie(data) {
    try {
      const response = await API.post('/api/rest/v2/subcategories', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getsubCategories() {
    try {
      const response = await API.get('/api/rest/v2/subcategories');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deletesubCategories(id) {
    try {
      const response = await API.delete(`/api/rest/v2/subcategories/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updatesubCategories(id) {
    try {
      const response = await API.put(`/api/rest/v2/subcategories/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
