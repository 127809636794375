<template>
  <AddRepair />
</template>

<script>
import AddRepair from "@/components/repair/AddRepair";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    AddRepair,
  },
  metaInfo: {
    title: "Flex Vastgoed CRM | Reparatie toevoegen ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
