<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <h2>Notitie</h2>
        <v-textarea
          v-model="note"
          :counter="1000"
          label="Voer een notitie in"
        ></v-textarea>
        <v-btn
          @click="addNote()"
          style="background-color: #147D81;
  color: white"
          >Aanmaken</v-btn
        >
        <v-btn
          style="color:white;background-color:#cb403d;margin-left:5px"
          @click="$emit('annuleer')"
          >Afsluiten</v-btn
        >
      </v-col>

      <v-col cols="12" md="6">
        <div
          style="border: 1px solid #4290f5; background-color: white; padding: 10px; max-height: 500px; overflow-y: auto; scrollbar-color: #ffffff;"
        >
          <h3>Geschiedenis</h3>
          <v-list>
            <v-card
              v-for="(note, index) in notes"
              :key="index"
              color="#4290f5"
              dark
            >
              <v-card-text
                >Aangemaakt op {{ note.createDate }} door {{ note.createdBy }}
                <v-icon small color="red" @click="deleteTenanttNote(note.id)">
                  mdi-trash-can
                </v-icon></v-card-text
              >
              <v-card-title> {{ note.note }} </v-card-title>

              <div
                v-if="index !== notes.length - 1"
                style="margin-bottom: 10px;"
              ></div>
            </v-card>
          </v-list>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiTenant from "../api/apiTenant";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      note: "",
      notes: [],
    };
  },

  async mounted() {
    try {
      const tenantNotes = await apiTenant.getNotesByTenantId(this.id);
      this.notes = tenantNotes;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async addNote() {
      try {
        const response = await apiTenant.createNote(this.id, {
          note: this.note,
        });
        console.log(response);
        this.note = "";
        this.$swal.fire("Gelukt!", "Notitie is toegevoeg!", "success");
        this.notes = await apiTenant.getNotesByTenantId(this.id);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },

    async deleteTenanttNote(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze huurder wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        const tenantId = this.$route.params.id;
        await apiTenant.deleteNote(id, tenantId);
        this.$swal.fire(
          "Verwijderd",
          "De consult is succesvol verwijderd",
          "success"
        );
        this.notes = await apiTenant.getNotesByTenantId(tenantId);
      }
    },
  },
};
</script>
