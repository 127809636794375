<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Naam aanmelder"
          v-model="fullName"
          disabled
        ></v-text-field>

        <v-text-field
          label="Mailadres aanmelder"
          v-model="tenantEmail"
          disabled
        ></v-text-field>

        <v-text-field
          label="Telefoonnummer aanmelder"
          v-model="tenantPhone"
          disabled
        ></v-text-field>

        <v-autocomplete
          :items="statussen.flat()"
          label="Selecteer status melding"
          v-model="incident.status"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>

      <v-col cols="6">
        <h5>Vraag van de aanmelder</h5>
        <v-textarea
          disabled
          v-html="incident.question"
          filled
          name="input-50-4"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Antwoord</h5>
        <vue-editor id="editor1" v-model="action"></vue-editor>
        <div class="text-center" style="margin-top:3px">
          <v-btn
            style="background-color: #147D81;
  color: white;margin-right:10px"
            @click="saveActionToIncident()"
          >
            Opslaan
          </v-btn>

          <v-btn style="color:white;background-color:#cb403d" @click="goBack()">
            Afsluiten
          </v-btn>
        </div>
        <v-row>
          <v-col cols="12">
            <h5>Geschiedenis</h5>
            <v-timeline>
              <v-timeline-item
                v-for="action in incident.actions"
                :key="action.id"
                color="teal"
                icon="mdi-history"
              >
                <v-card class="elevation-2">
                  <v-card-text>
                    <v-alert dense type="info">
                      {{ new Date(action.createDate).toLocaleDateString() }}
                      {{
                        new Date(action.createDate).toLocaleTimeString(
                          "nl-NL",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )
                      }}
                    </v-alert>
                  </v-card-text>
                  <v-card-text>
                    <span v-html="action.action"></span>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiIncidentstatus from "../api/apiIncidentstatus";
import apiIncident from "../api/apiIncident";

export default {
  data: () => ({
    action: "",
    statussen: [],
    incident: {},
  }),
  watch: {
    "incident.status": {
      immediate: false,
      handler: function(newStatus, oldStatus) {
        if (newStatus !== oldStatus) {
          this.saveStatus();
        }
      },
    },
  },

  async mounted() {
    apiIncident.getIncidentById(this.$route.params.id).then((data) => {
      this.incident = data;
    });
    apiIncidentstatus.getIncidentsStatus().then((data) => {
      this.statussen = data;
    });
  },

  computed: {
    actionIcon() {
      return "mdi-text";
    },
    fullName() {
      if (this.incident.tenant) {
        return `${this.incident.tenant.firstName} ${this.incident.tenant.lastName}`;
      }
      return "";
    },
    tenantEmail() {
      return this.incident.tenant ? this.incident.tenant.email : "";
    },
    tenantPhone() {
      return this.incident.tenant ? this.incident.tenant.phone : "";
    },
  },

  methods: {
    goBack() {
      this.$router.push(`../../Meldingen`);
    },

    async saveStatus() {
      try {
        const id = this.$route.params.id;
        const response = await apiIncident.updateIncident(id, this.incident);
        console.log(response);
        this.incidents = await apiIncident.getIncidentById(id);
        this.action = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    async saveActionToIncident() {
      try {
        const id = this.$route.params.id;
        const data = {
          action: this.action,
        };
        const response = await apiIncident.addActionToIncident(id, data);
        console.log(response);
        this.incidents = await apiIncident.getIncidentById(id);
        this.action = "";
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
