<template>
  <v-container style="padding-top: 2rem">
    <div v-if="!showaddContract">
      <v-row>
        <v-col>
          <v-btn
            rounded
            style="background-color: #147D81;color: white"
            @click="showaddContract = !showaddContract"
            >Nieuw Contract aanmaken</v-btn
          >
        </v-col>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Zoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-tabs v-model="tab" background-color="transparent">
        <v-tab key="all">Alle Contracten</v-tab>
        <v-tab key="active">Actieve Contracten</v-tab>
        <v-tab key="inactive">Niet Actieve Contracten</v-tab>
      </v-tabs>

      <v-data-table
        :items="filteredContracts"
        :search="search"
        :headers="headers"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <v-switch
            v-model="item.status"
            @change="updateStatus(item)"
          ></v-switch>
        </template>

        <template v-slot:[`item.Actions`]="{ item }">
          <v-icon small color="green" @click="openSingleContract(item.id)">
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-if="showaddContract">
      <AddContract @cancel="showaddContract = false" />
    </div>
  </v-container>
</template>

<script>
import AddContract from "../contracten/AddContract";
import apiContract from "../api/apiContract";

export default {
  components: {
    AddContract,
  },
  data: () => ({
    showaddContract: false,
    contracts: [],

    search: "",
    tab: "all",
    headers: [
      { text: "Contractnummer", value: "contractNumber" },
      { text: "Startdatum", value: "startDate" },
      { text: "Einddatum", value: "endDate" },
      { text: "Aanmaakdatum", value: "createDate" },
      { text: "Status", value: "status" },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    filteredContracts() {
      if (this.tab === "active") {
        return this.contracts.filter((contract) => contract.status);
      } else if (this.tab === "inactive") {
        return this.contracts.filter((contract) => !contract.status);
      }
      return this.contracts;
    },
  },
  async mounted() {
    apiContract.getContracts().then((data) => {
      this.contracts = data;
    });
  },
  methods: {
    openSingleContract(contractId) {
      this.$router.push(`/ContractInformatie/${contractId}`);
    },
    cancelForm() {
      this.showForm = false;
    },
    updateStatus() {
      this.$swal("Updated");
    },
  },
};
</script>
