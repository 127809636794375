<template>
  <v-container>
    <div class="d-flex justify-end" style="margin-bottom:5px">
      <v-btn color="primary" rounded>
        WOZ waarde opvragen
      </v-btn>
    </div>
    <div class="">
      <p><b>Adres:</b> {{ form.adres }}</p>
    </div>
    <div>
      <v-tabs v-model="tab" background-color="#147D81" dark>
        <v-tab v-for="(item, i) in items" :key="i">
          {{ item.tab }}
        </v-tab>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Wonen
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <label> Woonoppervlakte(m²)</label>
                  <input
                    class="form-control"
                    placeholder="Woon oppervlakte"
                    name="number_bedroom"
                    type="number"
                    v-model="form.square"
                  />
                </v-col>
                <v-col cols="4">
                  <label> Perceeloppervlakte(m²)</label>
                  <input
                    class="form-control"
                    placeholder="perceel oppervlakte"
                    type="number"
                    v-model="form.plotArea"
                  />
                </v-col>
                <v-col cols="4">
                  <label> Aantal slaapkamers</label>
                  <input
                    class="form-control"
                    placeholder="Aantal slaapkamers"
                    type="number"
                    id="number_bedroom"
                    v-model="form.bedrooms"
                  />
                </v-col>
                <v-col cols="4">
                  <label> Aantal badkamers</label>
                  <input
                    class="form-control"
                    placeholder="Aantal badkamers"
                    type="number"
                    v-model="form.bathrooms"
                  />
                </v-col>
                <v-col cols="4">
                  <label> Aantal woonlagen</label>
                  <input
                    class="form-control"
                    placeholder="Aantal woonlagen"
                    type="number"
                    v-model="form.stairs"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Financien
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <label> Vraagprijs</label>
                  <input
                    class="form-control"
                    outlined
                    label="€"
                    v-model="form.price"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <label> Vraagprijs per m²</label>
                  <input
                    outlined
                    class="form-control"
                    label="€"
                    v-model="form.pricePerSquare"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label> WOZ waarde </label>
                  <input
                    class="form-control"
                    outlined
                    label="€"
                    v-model="form.wozValue"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Bouw
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <label> Soort appartement</label>
                  <input
                    outlined
                    class="form-control"
                    v-model="form.appartmentType"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label> Soort bouw</label>
                  <input
                    outlined
                    class="form-control"
                    v-model="form.builtType"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Bouwjaar</label>
                  <v-select
                    label="Bouwjaar"
                    v-model="form.yearBuilt"
                    :items="years"
                    :menu-props="{ offsetY: true }"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label> Soort dak</label>
                  <input
                    class="form-control"
                    outlined
                    v-model="form.roofType"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Energie
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <label> Energielabel</label>

                  <v-autocomplete
                    outlined
                    :items="labels"
                    v-model="form.energyLabel"
                    auto-select-first
                    clearable
                    deletable-chips
                    dense
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label> Isolatie</label>
                  <input
                    class="form-control"
                    outlined
                    v-model="form.isolation"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label> Verwarming</label>
                  <input class="form-control" outlined v-model="form.heating" />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label> Warm water</label>
                  <input
                    class="form-control"
                    outlined
                    v-model="form.hotWater"
                  />
                </v-col>

                <!-- <v-col cols="12" sm="6" md="6">
                <label> Cv-ketel</label>
                <v-text-field outlined v-model="house.rooms"></v-text-field>
              </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Project en Status
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <p class="sidebar-title"><span>Status</span></p>

                  <v-autocomplete
                    :items="statussen"
                    label="Status"
                    v-model="form.status"
                    item-value="name"
                    item-text="name"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <p class="sidebar-title"><span>Project</span></p>

                  <v-autocomplete
                    :items="projects"
                    label="Project"
                    v-model="form.project"
                    item-value="id"
                    item-text="name"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <p class="sidebar-title"><span>Relatie</span></p>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    :items="relation"
                    label="Relatie"
                    v-model="form.relatie"
                    item-value="id"
                    item-text="fullName"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-combobox
                    v-model="form.facilitie"
                    :items="facilities"
                    label="Selecteer faciliteiten"
                    item-value="id"
                    item-text="name"
                    multiple
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card elevation="3">
            <v-card-title>
              Media en omschrijving
            </v-card-title>

            <v-card-text> </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <span style="margin-right:5px">
        <v-btn
          rounded
          style="background-color: #147D81;
  color: white"
          @click="updateEmployee()"
          >Opslaan</v-btn
        >
      </span>
      <v-btn
        text
        style="color:white;background-color:#cb403d"
        @click="$router.push('/Objecten')"
      >
        Afsluiten
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import apiFacilities from "../api/apiFacilities";
import apiHouse from "../api/apiHouse";
import apiProject from "../api/apiProject";
import apiRelation from "../api/apiRelation";
export default {
  data() {
    return {
      labels: ["A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G"],
      tab: null,
      form: [],
      relations: [],
      statussen: [],
      projects: [],
      facilities: [],
      years: [],
      items: [
        { tab: "Bouw", content: "Tab 1 Content" },
        { tab: "Financien", content: "Tab 2 Content" },
        { tab: "Bouw", content: "Tab 3 Content" },
        { tab: "Energie", content: "Tab 4 Content" },

        { tab: "Project en Status", content: "Tab 5 Content" },
        { tab: "Media en omschrijving", content: "Tab 6 Content" },
        { tab: "Contracten", content: "Tab 7 Content" },
        { tab: "Meldingen", content: "Tab 8 Content" },
      ],
    };
  },
  created() {
    const currentYear = new Date().getFullYear();
    for (let year = 1900; year <= currentYear; year++) {
      this.years.push(year);
    }
  },

  computed: {
    relation() {
      return this.relations.map((relation) => {
        return {
          ...relation,
          fullName: relation.firstName + " " + relation.lastName,
        };
      });
    },
  },
  async mounted() {
    apiHouse.getHouseById(this.$route.params.id).then((data) => {
      this.form = data;
    });

    apiFacilities.getFacilities().then((data) => {
      this.facilities = data;
    });
    apiProject.getProjects().then((data) => {
      this.projects = data;
    });
    await apiRelation.getRelation().then((data) => {
      this.relations = data;
    });

    await apiHouse.getStatus().then((data) => {
      this.statussen = data;
    });
  },

  methods: {
    async apiHouse(form) {
      try {
        const response = await apiHouse.updateHouse(this.id, form);
        console.log(response);
        this.form = response;
        this.$swal.fire("Gelukt!", "Uw profiel is bijgewerkt!", "success");
        this.form = await apiHouse.getHouseById(this.id);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Foutmelding!",
          "Het bijwerken is niet gelukt!",
          "error"
        );
      }
    },

    async updateObject() {
      const id = this.$route.params.id;
      try {
        const response = await apiHouse.updateHouse(id, this.form);
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
