<template>
  <div>
    <v-card>
      <v-container>
        <div>
          <label>Onderwerp</label>
          <v-text-field v-model="subject" solo></v-text-field>
          <label>Categorie selecteren</label>
          <v-select
            v-model="categorie"
            :items="categories"
            id="name"
            solo
            item-text="name"
          ></v-select>
          <label>Zoekwoorden</label>
          <v-combobox
            label="Tags"
            v-model="tags"
            multiple
            chips
            small-chips
            deletable-chips
            solo
          ></v-combobox>
        </div>
        <v-divider> </v-divider>
        <vue-editor id="editor1" v-model="content"> </vue-editor>

        <div class="text-center">
          <v-btn
            style="background-color: #147D81;
  color: white;margin-right:10px"
            @click="createKnowledgeItem()"
          >
            Aanmaken
          </v-btn>
          <v-btn
            style="color:white;background-color:#cb403d"
            @click="cancelForm"
          >
            Afsluiten
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import apiKnowledgeCategorie from "../api/apiKnowledgeCategorie";
import { VueEditor } from "vue2-editor";
import apiKnowledgebase from "../api/apiKnowledgebase";
export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      editing: false,
      htmlForEditor: "",
      tags: [],
      subject: "",
      content: "",
      categorie: "",
      categories: [],
    };
  },

  async mounted() {
    apiKnowledgebase.getKnowledgeItems().then((data) => {
      this.items = data;
    });

    apiKnowledgeCategorie.getKnowledgeCategories().then((data) => {
      this.categories = data;
    });
  },

  methods: {
    resetForm() {
      this.tags = [];
      this.subject = "";
      this.content = "";
    },
    cancelForm() {
      this.$emit("cancel");
    },
    async createKnowledgeItem() {
      try {
        const data = {
          subject: this.subject,
          keywords: this.tags,
          content: this.content,
        };
        const response = await apiKnowledgebase.createKnowledgeItem(data);
        console.log(response);
        this.$swal.fire("Yes!", "Het aanmaken is gelukt!", "success");
        this.items = await apiKnowledgebase.getKnowledgeItems();
        this.resetForm();
        this.$root.$emit("knowledgeItemAdded");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>

<style scoped>
.text-center {
  padding-top: 1%;
  text-align: center;
}

#editor,
#editor1 {
  height: 350px;
  width: 150px;
}

.projectButton {
  margin: 10px;
}
</style>
