import {API} from "./api.base.js"
export default {

  async  createKnowledgeCategorie(data) {
    try {
      const response = await API.post('/api/rest/v2/knowledgecategorie', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  async getKnowledgeCategories() {
    try {
      const response = await API.get('/api/rest/v2/knowledgecategorie');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteKnowledgeCategorie(id) {
    try {
      const response = await API.delete(`/api/rest/v2/knowledgecategorie/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateKnowledgeCategorie(id) {
    try {
      const response = await API.put(`/api/rest/v2/knowledgecategorie/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
 
};
