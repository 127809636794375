<template>
  <div>
    <v-container>
      <div v-if="items.length === 0">
          <img src="@/assets/nodata.jpg" height="550" width="750" />
        </div>
      <v-row v-else>
        <v-col cols="12">
          <v-card>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
                  Exporteren
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.text"
                  @click="exportData(item.type)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-title>
              Projecten
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              id="my-projects"
              cellClass="printable"
              class="custom-table-header"
            >
              <template v-slot:top> </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon small color="green" @click="openProject(item.id)">
                  mdi-eye
                </v-icon>
                <v-icon small color="red" @click="deleteProject(item.id)">
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiRelation from "../api/apiRelation";

import apiProject from "../api/apiProject";
export default {
  data: () => ({
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],

    search: "",

    dialogDelete: false,

    items: [],
    headers: [
      {
        text: "Naam",
        value: "name",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Omschrijving",
        value: "description",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Status",
        value: "status",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Aangemaakt door:",
        value: "createdBy",
        sortable: true,
        class: "white-header",
      },

      {
        text: " adres:",
        value: "houses[0].adres",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Relatie",
        value: "relations[0].firstName",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  async mounted() {
    apiProject.getProjects().then((data) => {
      this.items = data;
    });
  },

  methods: {
    async openProject(id) {
      this.$router.push(`/project/informatie/${id}`);
    },
    async deleteProject(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je dit project wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiProject.deleteProject(id);
        this.$swal.fire(
          "Verwijderd",
          "Het project is succesvol verwijderd",
          "success"
        );
        apiProject.getProjects().then((data) => {
          this.items = data;
        });
      }
    },

    async getRelations() {
      const relations = await apiRelation.getRelation();
      this.relations = relations;
    },

    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Projecten.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.items.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Projecten.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-projects").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>
<style>
.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
