<template>
  <div>
    <v-container style="padding-top: 4rem">
      <v-row>
        <v-btn
          rounded
          style="background-color: #147D81;
  color: white"
          dark
          @click="showAddTenantForm = true"
          v-if="!(showAddTenantForm || showSingleTenantForm)"
        >
          Huurder aanmaken
        </v-btn>

        <v-col cols="12">
          <add-tenant-form
            v-if="showAddTenantForm"
            @tenant-added="onTenantAdded"
            @cancel="showAddTenantForm = false"
          />

          <SingleTenant
            @annuleer="showSingleTenantForm = false"
            v-if="showSingleTenantForm"
            :id="tenantId"
          />
        </v-col>
        <v-col cols="12" v-if="!(showAddTenantForm || showSingleTenantForm)">
          <div class="row mbot15 overview">
            <div class="col-md-12">
              <h4 class="no-margin">Huurders overzicht</h4>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ totalTenants.aantal }}</h3>
              <span class="text-dark">Totaal huurders</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ totalActiveTenants.aantal }}</h3>
              <span class="text-success">Actieve huurders</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ totalNonActiveTenants.aantal }}</h3>
              <span class="text-danger">Inactieve huurders</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ activeContacts }}</h3>
              <span class="text-info">Actieve contracten</span>
            </div>
            <div class="col-md-2 col-xs-6 border-right">
              <h3 class="bold">{{ inactiveContacts }}</h3>
              <span class="text-danger">Onactieve contracten</span>
            </div>
          </div>

          <v-card>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
                  Exporteren
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.text"
                  @click="exportData(item.type)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-card-title>
              <div style="display: flex; align-items: center;">
                <v-checkbox color="success" v-model="checkbox"></v-checkbox>
                <label style="margin-right: 10px;font-size:16px"
                  >Inactief uitsluiten Klanten</label
                >
              </div>

              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="filteredItems"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="custom-table-header"
              id="my-tenants"
              cellClass="printable"
            >
              <template v-for="item in items" v-slot:[`item.status`]="{ item }">
                <div :key="item.id">
                  <v-switch
                    v-model="item.toggle"
                    hide-details
                    :disabled="item.status !== 'Actief'"
                    @change="updateTenant(item)"
                  ></v-switch>
                </div>
              </template>

              <template v-slot:[`item.Actions`]="{ item }">
                <span style="margin-right:5px">
                  <v-icon
                    small
                    color="green"
                    @click="openTenantProfile(item.id)"
                  >
                    mdi-eye
                  </v-icon>
                </span>
                <v-icon small color="red" @click="deleteTenant(item.id)">
                  mdi-trash-can
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import apiTenant from "../api/apiTenant";
import AddTenantForm from "../tenant/AddTenantForm.vue";
import SingleTenant from "../tenant/SingleTenant.vue";
export default {
  components: {
    AddTenantForm,
    SingleTenant,
  },
  data: () => ({
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    checkbox: false,
    tenants: [],
    search: "",
    showAddTenantForm: false,
    showSingleTenantForm: false,
    items: [],
    totalTenants: [],
    totalActiveTenants: [],
    totalNonActiveTenants: [],
    activeContacts: 0,
    inactiveContacts: 0,
    headers: [
      {
        text: "Titel",
        value: "title",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Voornaam",
        value: "firstName",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Achternaam",
        value: "lastName",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Telefoonnummer",
        value: "phone",
        sortable: true,
        class: "white-header",
      },

      {
        text: "E-mail",
        value: "email",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Status:",
        value: "status",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  created() {
    this.$on("tenant-added", () => {
      apiTenant.getTenants().then((data) => {
        this.tenants = data;
      });
    });
  },

  async mounted() {
    apiTenant.getTotalTenants().then((data) => {
      this.totalTenants = data;
    });
    apiTenant.getActiveTenants().then((data) => {
      this.totalActiveTenants = data;
    });

    apiTenant.getNonActiveTenants().then((data) => {
      this.totalNonActiveTenants = data;
    });
    try {
      const data = await apiTenant.getTenants();
      this.items = data.map((item) => ({
        ...item,
        toggle: item.status === "Actief",
        showActions: false,
      }));
    } catch (error) {
      console.error(error);
    }
  },

  computed: {
    filteredItems() {
      if (this.checkbox) {
        return this.items.filter(
          (item) => item.toggle && item.status === "Actief"
        );
      } else {
        return this.items;
      }
    },
  },

  methods: {

    openTenantProfile(tenantId) {
      this.$router.push(`/Huurderprofiel/${tenantId}`);
    },

    onTenantAdded(tenant) {
      this.tenants.push(tenant);
      this.showAddTenantForm = false;
    },
    async updateTenant(item) {
      try {
        const newStatus = item.toggle ? "Actief" : "niet actief";
        await apiTenant.updateTenant(item.id, { status: newStatus });
        item.status = newStatus;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteTenant(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze huurder wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        try {
          await apiTenant.deleteTenant(id);

          this.$vuetify.snackbar({
            color: "success",
            timeout: 3000,
            text: "De Huurder is succesvol verwijderd",
          });
          await apiTenant.getTenants().then((data) => {
            this.items = data;
          });
        } catch (error) {
          console.error(error);
          this.$vuetify.snackbar({
            color: "error",
            timeout: 3000,
            text:
              "Er is een fout opgetreden bij het verwijderen van de Huurder",
          });
        }
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "huurdersoverzicht.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.filteredItems.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("huurdersoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-tenants").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style scoped>
.overview {
  margin-bottom: 10px;
}
.checkbox-sm .v-input--selection-controls__ripple {
  height: 16px;
  width: 16px;
}

.checkbox-sm .v-input--selection-controls__input {
  height: 16px;
  width: 16px;
}
.swal2-title {
  font-size: 1.5rem !important;
}
</style>
<style>
.custom-table-header .v-data-table-header {
  background-color: #147d81;
  color: white !important;
}
.white-header {
  color: white !important;
}
</style>
