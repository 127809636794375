import {API} from "./api.base.js"
export default {

  async getKnowledgeItems() {
    try {
      const response = await API.get('/api/rest/v2/knowledge');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async searchKnowledgeItem(keyword) {
    try {
      const response = await API.post('/api/rest/v2/knowledge/search', {keyword} );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  
  
  async createKnowledgeItem(data) {
    try {
      const response = await API.post('/api/rest/v2/knowledge', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getArticleById(id) {
    try {
      const response = await API.get(`/api/rest/v2/knowledge/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  
  async updateArticle(id, items) {
    try {
      const response = await API.put(`/api/rest/v2/knowledge/${id}`,items);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteItem(id) {
    try {
      const response = await API.delete(`/api/rest/v2/knowledge/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
 
};
