<template>
  <div style="padding-top: 4rem">

      <v-row>
        <v-col cols="4">
          <v-card height="400" width="256" class="mx-auto">
            <v-navigation-drawer permanent>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                Flex Vastgoed CRM
                  </v-list-item-title>
                  <v-list-item-subtitle>
                  Mijn profiel
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-list dense nav>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="item.title"
                  link
                  :class="{ '#147D81--text': item.active }"
                  @click="handleNavigationClick(item)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-col cols="8">
          <component :is="right" />
        </v-col>
      </v-row>

  </div>
</template>

<script>
import MyProjects from "../profile/MyProjects";
import ProfileSetting from "../profile/ProfileSetting";
import Profile from "../profile/Profile";
import Password from "../profile/Password";
import ProfileShortcuts from "../profile/ProfileShortcuts";
export default {
  components: {
    ProfileSetting,
    Profile,
    Password,
    ProfileShortcuts,
    MyProjects,
  },
  data() {
    return {
      items: [
        { title: "Mijn profiel", icon: "mdi-account", active: true },
        { title: "Handige links", icon: "mdi-link", active: false },
        { title: "Projecten", icon: "mdi-lock", active: false },
        { title: "Wachtwoord", icon: "mdi-wrench", active: false },
   
      ],
    right: "Profile",
    };
  },
  methods: {
    handleNavigationClick(item) {
      let index = this.items.indexOf(item);
      console.log(index);
      if (index > -1) {
        this.items.forEach((item) => {
          item.active = false;
        });
        this.items[index].active = true;
        if (item.title === "Mijn profiel") {
          this.right = "Profile";
        } else if (item.title === "Instellingen") {
          this.right = "ProfileSetting";
        } else if (item.title === "Handige links") {
          this.right = "ProfileShortcuts";
        } else if (item.title === "Wachtwoord") {
          this.right = "Password";
        } else if (item.title === "Projecten") {
          this.right = "MyProjects";
        }
      }
    },
  },
};
</script>
