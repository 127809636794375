<template>
  <div>
    <v-card v-if="showForm">
      <v-card-title class="text-h5"
        >Nieuwe snelkoppeling toevoegen</v-card-title
      >
      <v-card-text>
        <v-form ref="form" @submit.prevent="createShortcut()">
          <v-text-field
            v-model="newShortcut.name"
            label="Naam"
            required
          ></v-text-field>
          <v-text-field
            v-model="newShortcut.url"
            label="URL"
            required
          ></v-text-field>
          <v-alert v-if="formError" type="error">{{ formError }}</v-alert>
          <v-btn type="submit" style="background-color: #147D81;
  color: white">Toevoegen</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <div style="margin-bottom:3px">
          <v-btn @click="showForm = true" style="background-color: #147D81;
  color: white">
            Snelkoppeling toevoegen
          </v-btn>
        </div>
<v-data-table :headers="headers" :items="shortcuts" hide-default-footer>
  <template v-slot:item.actions="{ item }">
    <v-btn style="background-color: #147D81;
  color: white" small text 
      @click="item.editing = true"
      v-if="!item.editing"
    >
      Bewerken
    </v-btn>
    <v-btn color="success" small text 
      @click="updateShortcut(item)"
      v-else
    >
      Opslaan
    </v-btn>
    <v-btn color="red" small text 
      @click="confirmDeleteShortcut(item.id)"
      v-if="!item.editing"
    >
      Verwijder
    </v-btn>

    <v-btn color="red" small text 
      @click="item.editing = false"
      v-else
    >
      Annuleren
    </v-btn>
  </template>
  <template v-slot:item.name="{ item }">
      <input type="text" v-model="item.name" v-if="!item.editing">
  <v-text-field v-model="item.name" :readonly="!item.editing" v-else></v-text-field>

  </template>
  <template v-slot:item.url="{ item }">
  <a :href="item.url" target="_blank" v-text="item.url" v-if="!item.editing"></a>
  <v-text-field v-model="item.url" :readonly="!item.editing" v-else></v-text-field>
  </template>
</v-data-table>






     
      </v-card-text>
    </v-card>

  
  </div>
</template>

<script>
import apiShortcut from "../api/apiShortcut";
export default {
  data() {
    return {
        showForm: false,
editing:false,
      formError: "",
      headers: [
        { text: "Naam", value: "name" },
        { text: "URL", value: "url" },
        { text: "Acties", value: "actions", sortable: false },
      ],
      shortcuts: [],
      newShortcut: {
        name: "",
        url: "",
      },
    };
  },
async mounted() {
  try {
    const data = await apiShortcut.getShortcut();
    this.shortcuts = data.map(item => ({
      ...item,
      editing: false,
    }));
  } catch (error) {
    console.error(error);
  }
},


  methods: {
    clearForm() {
      this.newShortcut.name = "";
      this.newShortcut.url = "";
    },
    async createShortcut() {
      if (!this.newShortcut.name || !this.newShortcut.url) {
        this.formError = "Vul a.u.b. beide velden in.";
        return;
      }
      try {
        const data = {
          name: this.newShortcut.name,
          url: this.newShortcut.url,
        };
        const response = await apiShortcut.createShortcut(data);
        console.log(response);
        this.$swal.fire("Gelukt!", "Snelkoppeling is aangemaakt!", "success");
        await apiShortcut.getShortcut();
        this.showForm = false;
        this.formError = "";
        this.clearForm();
      } catch (error) {
        console.error(error);
      }
    },

    async confirmDeleteShortcut(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je dit URL wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiShortcut.deleteShortcut(id);
        this.$swal.fire(
          "Verwijderd",
          "Het project is succesvol verwijderd",
          "success"
        );
      this.shortcuts = await apiShortcut.getShortcut();

      }
    },

editShortcut(shortcut) {
  this.$set(shortcut, "editing", true);
},
async updateShortcut(item) {
  const id = item.id;
  const newData = {
    name: item.name,
    url: item.url
  };

  try {
    const response = await apiShortcut.updateShortcut(id, newData);
    console.log(response);
    item.editing = false;
    this.$swal.fire(
      "Gelukt!",
      "De snelkoppeling is bijgewerkt!",
      "success"
    );
  } catch (error) {
    console.error(error);
    this.$swal.fire(
      "Foutmelding!",
      "Het bijwerken is niet gelukt!",
      "error"
    );
  }
}


},

};
</script>

<style scoped>
.input[type="text"], input[type="password"], textarea {
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc; /* You can adjust the color and thickness of the outline */
}
.table-input {
  padding: 4px;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.table-link {
  padding: 4px;
  margin: 0;
  display: block;
}

</style>