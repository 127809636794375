
<template>
  <ProfileSidebar />
</template>

<script>
import ProfileSidebar from "@/components/profile/ProfileSidebar";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ProfileSidebar,
  },
  metaInfo: {
    title: "Flex vastgoed CRM | Profiel",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>