<template>
  <v-container>
    <v-btn @click="toggleIntern()" small color="#147D81" style="color:white">
      {{ intern ? "Externe melding" : "Interne melding" }}
    </v-btn>
    <v-row>
      <v-col cols="6">
        <div class="d-flex align-center">
          <v-autocomplete
            class="flex-grow-1"
            :items="tenants"
            label="Naam aanmelder"
            v-model="incident.tenantId"
            :item-text="getFullName"
            item-value="id"
            @change="onNameSelected"
            v-if="!intern"
          ></v-autocomplete>

          <v-btn
            small
            color="green darken-2"
            style="color:white"
            @click="openTenant()"
          >
            Open kaart
          </v-btn>
        </div>

        <v-text-field
        disabled
          label="Mailadres aanmelder"
          v-model="incident.mail"
          v-if="!intern"
        ></v-text-field>

        <v-text-field

        disabled
          label="Telefoonnummer aanmelder"
          v-model="incident.phone"
          v-if="!intern"
        ></v-text-field>
        <v-text-field
          label="Onderwerp"
          v-model="incident.subject"
        ></v-text-field>

        <v-autocomplete
          :items="statussen"
          label="Status melding selecteren"
          v-model="incident.status"
          item-text="name"
          item-value="id"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h5>Melding</h5>
        <vue-editor id="editor1" v-model="incident.question"></vue-editor>
        <div class="text-center" style="margin-top:3px">
          <v-btn
            style="background-color: #147D81;
    color: white;margin-right:10px"
            @click="createIncident()"
          >
            Aanmaken
          </v-btn>
          <v-btn style="color:white;background-color:#cb403d" @click="goBack()">
            Afsluiten
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiIncidentstatus from "../api/apiIncidentstatus";
import apiIncident from "../api/apiIncident";
import apiTenant from "../api/apiTenant";
export default {
  data: () => ({
    tenants: [],
    selectedTenantId: null,
    intern: false,
    statussen: [],
    incident: {
      status: "",
      question: "",
      phone: "",
      mail: "",
      name: "",
      subject: "",
      tenantId: "",
    },
  }),

  async mounted() {
    await apiIncidentstatus.getIncidentsStatus().then((data) => {
      this.statussen = data;
    });
    await apiTenant.getTenantForIncident().then((data) => {
      this.tenants = data;
    });
  },
  methods: {
    openTenant() {
      const id = this.selectedTenantId;
      if (id) {
        this.$router.push(`/HuurderProfiel/${id}`);
      } else {
        this.$swal.fire({
          title: "Geen aanmelder geselecteerd",
          text: "Selecteer alstublieft een aanmelder voordat u doorgaat.",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#147D81",
        });
      }
    },

    getFullName(item) {
      return `${item.firstName} ${item.lastName}`;
    },
    onNameSelected(selectedId) {
      const selectedTenant = this.tenants.find(
        (tenant) => tenant.id === selectedId
      );
      if (selectedTenant) {
        this.incident.mail = selectedTenant.email
          ? selectedTenant.email
          : "Onbekend";
        this.incident.phone = selectedTenant.phone
          ? selectedTenant.phone
          : "Onbekend";
        this.selectedTenantId = selectedId;
      }
    },

    toggleIntern() {
      this.intern = !this.intern;
    },
    goBack() {
      this.$router.push(`../../Meldingen`);
    },
    async createIncident() {
      try {
        const response = await apiIncident.createIncident(this.incident);
        console.log(response);
        this.$swal.fire("Yes!", "De melding is aangemaakt!", "success");
        this.$router.push(`/MeldingBekijken/${response.id}`);
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },
  },
};
</script>
