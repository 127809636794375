<template>
  <div>
    <v-container>
      <div class="icon-container">
            <v-icon
              :color="showTable ? '#147D81' : undefined"
              @click="
                showTable = true;
                showCard = false;
              "
              >mdi-table</v-icon
            >

            <v-icon
              :color="showCard ? '#147D81' : undefined"
              @click="
                showTable = false;
                showCard = true;
              "
              >mdi-account-box-outline</v-icon
            >
          </div>
      <v-col cols="12">
        <v-btn
          rounded
          style="background-color: #147D81;
  color: white;"
          @click="showForm = true"
          v-if="!showForm"
          >Kennis item aanmaken
        </v-btn>
        <div>
         
        </div>
        <AddArticle v-if="showForm" @cancel="cancelForm" />
        
        <KnowledgeBaseTable v-if="showTable && !showCard && !showForm" />
        <KnowledgeBaseSearch v-if="!showTable && showCard && !showForm" />
      </v-col>
    </v-container>
  </div>
</template>

<script>
import AddArticle from "@/components/knowledgebase/AddArticle.vue";
import KnowledgeBaseTable from "@/components/knowledgebase/KnowledgeBaseTable.vue";
import KnowledgeBaseSearch from "@/components/knowledgebase/KnowledgeBaseSearch.vue";
export default {
  components: {
    AddArticle,
    KnowledgeBaseTable,
    KnowledgeBaseSearch,
  },
  data: () => ({
    showForm: false,
    showTable: true,
    showCard: false,
  }),
  methods: {
    cancelForm() {
      this.showForm = false;
    },
  },
};
</script>

<style>
.icon-container {
  display: flex;
  justify-content: flex-end;
}
</style>
