<template>
  <div>
    <v-container
      style="max-height: 1000px; overflow-y: auto; padding-top: 4rem"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark v-on="on" style="margin-left:10px;margin-top:10px">
                  Exporteren
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in exportOptions"
                  :key="item.text"
                  @click="exportData(item.type)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-card-title>
              Objecten
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Opzoeken"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              fixed-header
              :headers="headers"
              :items="objects"
              :search="search"
              disable-pagination
              :hide-default-footer="true"
              class="custom-table-header"
            >
              <template v-slot:[`item.Actions`]="{ item }">
                <v-icon small color="red" @click="deleteObject(item.id)">
                  mdi-delete
                </v-icon>

                <v-icon small color="green" @click="openObjectProfile(item.id)">
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import apiHouse from "../api/apiHouse";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  data: () => ({
    exportOptions: [
      {
        text: "Export naar Excel",
        type: "excel",
        icon: "mdi-file-excel-outline",
      },
      { text: "Export naar PDF", type: "pdf", icon: "mdi-file" },
      { text: "Afdrukken", type: "print", icon: "mdi-printer" },
    ],
    dialog: false,
    search: "",
    objects: [],
    headers: [
      {
        text: "Adres",
        value: "adres",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Sleutelnummer",
        value: "key.keynumber",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Status",
        value: "status",
        sortable: true,
        class: "white-header",
      },

      {
        text: "aangemaakt door",
        value: "createdBy",
        sortable: true,
        class: "white-header",
      },

      {
        text: "Aangemaakt op",
        value: "createDate",
        sortable: true,
        class: "white-header",
      },
      {
        text: "Acties",
        value: "Actions",
        sortable: false,
        class: "white-header",
      },
    ],
  }),

  async mounted() {
    apiHouse.getHouse().then((data) => {
      this.objects = data;
    });
  },

  methods: {
    openObjectProfile(id) {
      this.$router.push(`/Objectinfo/${id}`);
    },

    goToAddObject() {
      this.$router.push("/ObjectToevoegen");
    },
    async deleteObject(id) {
      const confirmed = await this.$swal.fire({
        title: "Weet je zeker dat je deze object wilt verwijderen?",
        text: "Je kunt deze actie niet ongedaan maken",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja, verwijderen",
        cancelButtonText: "Annuleren",
      });

      if (confirmed.value) {
        await apiHouse.deleteHouse(id);
        const data = await apiHouse.getHouse();
        this.objects = data;
        this.$swal.fire(
          "Verwijderd",
          "De object is succesvol verwijderd",
          "success"
        );
      }
    },
    exportData(type) {
      if (type === "excel") {
        const worksheet = XLSX.utils.json_to_sheet(this.items);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Relaties.xlsx");
      } else if (type === "pdf") {
        const doc = new jsPDF();
        const tableData = [];
        this.objects.forEach((item) => {
          const rowData = [];
          this.headers.forEach((header) => {
            rowData.push(item[header.value]);
          });
          tableData.push(rowData);
        });
        const colHeaders = this.headers.map((header) => header.text);
        doc.autoTable({
          head: [colHeaders],
          body: tableData,
        });
        doc.save("Monteurssoverzicht.pdf");
      } else if (type === "print") {
        const printContents = document.getElementById("my-mechanis").outerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
      }
    },
  },
};
</script>

<style >
.space {
  margin: 3px;
}


.white-header {
  color: white !important;
  background-color: #147d81 !important;
}
</style>
