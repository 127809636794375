
<template>
  <Mailserver />
</template>

<script>
import Mailserver from "@/components/instellingen/mailserver/Mailserver";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Mailserver,
  },
  metaInfo: {
    title: "Mailserver | Dashboard",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>