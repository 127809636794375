
<template>
  <Project />
</template>

<script>
import Project from "@/components/project/Project";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Project,
  },
  metaInfo: {
    title: "Flex CRM | toevoegen",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>