<template>
  <v-container style="padding-top: 2rem">
    <v-row>
      <v-col cols="8">
        <v-row style="margin-top:1.5rem">
          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Verwarming defect')"
            v-if="!showAddRepairForm"
          >
            <div>
              <div class="link-box">
                <img src="@/assets/repair/heater.png" alt="img" />
                <p class="link-box-subtitle">Verwarming defect</p>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Waterlevering probleem')"
            v-if="!showAddRepairForm"
          >
            <div class="link-box">
              <img
                src="@/assets/repair/water-tap.png"
                alt="img"
                title="Flex vastgoed CRM huurders"
              />

              <p class="link-box-subtitle">Waterlevering probleem</p>
            </div>
          </div>

          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Glasschade')"
            v-if="!showAddRepairForm"
          >
            <div>
              <div class="link-box">
                <img src="@/assets/repair/broken.png" alt="img" />

                <p class="link-box-subtitle">
                  Glasschade
                </p>
              </div>
            </div>
          </div>
        </v-row>

        <v-row>
          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Stroom storing')"
            v-if="!showAddRepairForm"
          >
            <div>
              <div class="link-box">
                <img src="@/assets/repair/power-off.png" alt="img" />
                <p class="link-box-subtitle">Stroom storing</p>
              </div>
            </div>
          </div>
          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Afvoer verstopt')"
            v-if="!showAddRepairForm"
          >
            <div class="link-box">
              <img
                src="@/assets/repair/drainage.png"
                alt="img"
                title="Flex vastgoed CRM "
              />

              <p class="link-box-subtitle">Afvoer verstopt</p>
            </div>
          </div>

          <div
            class="col-12 col-lg-4 mb-4"
            @click="setSubject('Overige melding')"
            v-if="!showAddRepairForm"
          >
            <div>
              <div class="link-box">
                <img src="@/assets/repair/tools.png" alt="img" />

                <p class="link-box-subtitle">
                  Overige melding
                </p>
              </div>
            </div>
          </div>
        </v-row>
      </v-col>

      <v-col v-if="!showAddRepairForm">
        <h5>Recente meldingen</h5>

        <v-card
          v-for="repairs in recentRapairs"
          :key="repairs.id"
          color="#385F73"
          dark
          style="margin-bottom: 10px;"
        >
          <v-card-text style="text-align: center;">
            <h5>{{ repairs.title }}</h5>
          </v-card-text>
          <v-card-subtitle v-if="repairs.property">
            {{ repairs.property.adres }}</v-card-subtitle
          >
          <v-card-actions>
            <v-btn text @click="redirectToSingleReparatie(repairs.id)">
              Open Melding
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <AddRepairForm
      v-if="showAddRepairForm"
      :subject="subjectCopy"
      @cancel="showAddRepairForm = false"
     
    />
  </v-container>
</template>

<script>
import AddRepairForm from "../repair/AddRepairForm.vue";
import apiRepair from "../api/apiRepair";
export default {
  components: {
    AddRepairForm,
  },
  data() {
    return {
      recentRapairs: [],
      subject: "",
      subjectCopy: "",
      showAddRepairForm: false,
    };
  },

  async mounted() {
    apiRepair.getRecentRepairs().then((data) => {
      this.recentRapairs = data;
    });
  },

  methods: {
    redirectToSingleReparatie(id) {
      this.$router.push(`/SingleReparatie/${id}`);
    },
    setSubject(subject) {
      this.subjectCopy = subject;
      this.showAddRepairForm = true;
    },
  },
};
</script>

<style scoped>
.router-link {
  color: black;
  text-decoration: none;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  background-color: #00676d;
  /* min-height:100%; */
}
.link-box p {
  margin: 0px 0px 10px 0px;
}
.link-box img {
  height: 40px;
  margin-bottom: 10px;
}
.link-box-title {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.link-box-subtitle {
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
}
.link-box {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 5px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 20px;
  font-size: 16px;
  text-align: center;
  background-color: #00676d;
  cursor: pointer;
}

.link-box:hover,
.link-box.hovered {
  background-color: #00a6b1;
}
</style>
